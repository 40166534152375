import { getEmployees } from "@assets/employeeImageHelper";
import { ReactComponent as IconChartSetTheory } from "@assets/icons/fi-rr-chart-set-theory.svg";
import { ReactComponent as IconComment } from "@assets/icons/fi-rr-comment.svg";
import { ReactComponent as IconCommunityHands } from "@assets/icons/fi-rr-community-hands.svg";
import { ReactComponent as IconVideoCameraAlt } from "@assets/icons/fi-rr-video-camera-alt.svg";
import onlineCoachingSrc from "@assets/images/online-coaching-header.jpg";
import { Stack, Title } from "@mantine/core";
import { IconList } from "@shared/components/IconList";
import { ImageCard } from "@shared/components/ImageCard";
import { ImageList } from "@shared/components/ImageList";
import { resolveIntl } from "@shared/helpers";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export const OnlineCoachingCard: FunctionComponent = () => {
  const { country, locale } = resolveIntl();
  const { t } = useTranslation();

  return (
    <ImageCard
      imageSrc={onlineCoachingSrc}
      bottomSection={
        <ImageList
          itemWidth={158}
          title={t("meetOurExperts")}
          items={getEmployees(country, locale).map((employee) => ({
            title: employee.name,
            description: employee.description,
            imageSrc: employee.imageSrc,
          }))}
        />
      }
    >
      <Stack>
        <Title c="embla.4" size={30} fw={600}>
          {t("onlineCoaching")}
        </Title>
        <IconList
          items={[
            {
              icon: IconCommunityHands,
              label: t("premiumProgram"),
            },
            {
              icon: IconChartSetTheory,
              label: t("individualisedLifestyleChange"),
            },
            {
              icon: IconVideoCameraAlt,
              label: t("oneToOneUnlimitedVideo"),
            },
            {
              icon: IconComment,
              label: t("chatRightInYourPocket"),
            },
          ]}
        />
      </Stack>
    </ImageCard>
  );
};
