import { ScrollArea, ScrollAreaProps } from '@mantine/core';
import { FunctionComponent } from 'react';

import classes from './EmblaContainer.module.css';

export const EmblaContainer: FunctionComponent<React.PropsWithChildren<ScrollAreaProps>> = ({ children, ...props }) => {
  return (
    <ScrollArea
      pr={10}
      mr={-10}
      style={{ flex: 1 }}
      type={'auto'}
      classNames={{
        viewport: classes.viewport,
        scrollbar: classes.scrollbar,
        thumb: classes.thumb,
      }}
      {...props}
    >
      {children}
    </ScrollArea>
  );
};
