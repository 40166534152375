import { Button, ButtonGroup, Flex, Group, Stack, Text } from '@mantine/core';
import { useSessionStorage } from 'usehooks-ts';

import { DebuggerPageNavigator } from './DebuggerPageNavigator';
import {
  FunnelDebugApi,
  FunnelEngineActions,
  FunnelEngineConfig,
  FunnelEngineContext,
  FunnelEngineData,
  FunnelEngineErrors,
  FunnelPageData,
  IFunnelApi,
} from '..';

export const FunnelDebugger = <T extends FunnelEngineConfig>({
  funnelApi,
  debugApi,
  currentSection,
  currentPage,
  config,
  data,
  context,
  name,
}: {
  funnelApi: IFunnelApi<FunnelPageData<T>, FunnelEngineContext<T>, FunnelEngineActions<T>, FunnelEngineErrors<T>>;
  debugApi: FunnelDebugApi<T>;
  config: T;
  currentSection: keyof T;
  currentPage: keyof T[keyof T];
  context: FunnelEngineContext<T>;
  data: FunnelEngineData<T>;
  name: string;
}) => {
  const [hidden, setHidden] = useSessionStorage(`funnel-debugger-hidden`, false);

  return (
    <Flex
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        borderTopLeftRadius: 10,
        color: 'white',
      }}
      p="md"
      bg={'embla.3'}
    >
      <Stack>
        <Group justify="space-between">
          <Text fw="bold">Funnel Debugger</Text>
          <Text>{name}</Text>
          <Button size="xs" onClick={() => setHidden((h) => !h)}>
            {hidden ? 'Show' : 'Hide'}
          </Button>
        </Group>
        {!hidden && (
          <Stack style={{ transition: 'height 1s', height: hidden ? 0 : undefined }}>
            <Group gap="xl" align="flex-start">
              <Stack>
                <Stack gap={0}>
                  <Text fw="bold">Context:</Text>
                  <pre
                    style={{
                      color: 'black',
                      padding: 2,
                      backgroundColor: 'white',
                      fontSize: 14,
                      maxHeight: 300,
                      maxWidth: 400,
                      overflowY: 'scroll',
                    }}
                  >
                    {JSON.stringify(context, null, 2)}
                  </pre>
                </Stack>
                <Stack gap={0}>
                  <Text fw="bold">Data:</Text>
                  <pre
                    style={{
                      color: 'black',
                      padding: 2,
                      backgroundColor: 'white',
                      fontSize: 14,
                      maxHeight: 200,
                      maxWidth: 400,
                      overflowY: 'scroll',
                    }}
                  >
                    {JSON.stringify(data, null, 2)}
                  </pre>
                </Stack>
              </Stack>
              <Stack gap={'sm'} justify="center">
                <Text fw="bold">Navigation</Text>
                {Object.keys(config).map((sectionName) => {
                  return (
                    <Stack gap="xs" key={`debugger-section-${sectionName}`}>
                      <Text fw="bold">{sectionName}</Text>
                      <Stack gap="xs" pl="md">
                        {Object.keys(config[sectionName]).map((pageName) => {
                          return (
                            <DebuggerPageNavigator
                              debugApi={debugApi}
                              route={{ section: sectionName, page: pageName }}
                              currentRoute={{
                                section: currentSection,
                                page: currentPage,
                              }}
                              key={`debugger-page-${sectionName}-${pageName}`}
                            />
                          );
                        })}
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            </Group>
            <Group justify="center">
              <ButtonGroup>
                <Button size="sm" color="error.2" onClick={debugApi.resetFunnel}>
                  Reset
                </Button>
                <Button size="sm" color="cream.4" onClick={() => funnelApi.back()}>
                  Back
                </Button>
                <Button
                  size="sm"
                  color="success.4"
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    funnelApi.next({} as any);
                  }}
                >
                  Next
                </Button>
              </ButtonGroup>
            </Group>
          </Stack>
        )}
      </Stack>
    </Flex>
  );
};
