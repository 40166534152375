import { Stack, Text } from "@mantine/core";
import { BackButton } from "@shared/components/buttons/BackButton";
import { NextButton } from "@shared/components/buttons/NextButton";
import { Disclaimer } from "@shared/components/Disclaimer";
import { StepTitle } from "@shared/components/StepTitle";
import { FunnelPageComponent } from "@shared/funnel-engine";
import { CountryIso3166, Product } from "@shared/gql/sdk";
import {
  formatPrice,
  getPriceInfoByType,
  getProductByType,
  resolveIntl,
} from "@shared/helpers";
import { IProduct } from "@shared/types";
import { useTranslation } from "react-i18next";

import { GLP1MedicineCard } from "./productCards/GLP1MedicineCard";
import { OnlineCoachingCard } from "./productCards/OnlineCoachingCard";
import { ProductCard } from "./productCards/ProductCard";
import { VirtualWeightLossUniverseCard } from "./productCards/VirtualWeightLossUniverseCard";
import { t } from "../../i18n";
import IFunnelContext, { ProductSelection } from "../FunnelContext";

const country = resolveIntl().country;
export const PRODUCTS = {
  medicalWeightLoss: {
    heading: "",
    title: t("medicalWeightLossProgram.title"),
    price:
      country === CountryIso3166.Dk
        ? t("medicalWeightLossProgram.priceDK")
        : t("medicalWeightLossProgram.priceUK"),
    pricePer:
      country === CountryIso3166.Dk
        ? t("medicalWeightLossProgram.pricePerDK")
        : t("medicalWeightLossProgram.pricePerUK"),
    priceDetails: t("medicalWeightLossProgram.priceDetails") || undefined,
    priceDetailExplainer:
      t("medicalWeightLossProgram.priceDetailExplainer") || undefined,
    features: {
      [CountryIso3166.Gb]: [
        t("lifestyleCoachingProgram"),
        t("personalTreatmentTeam"),
        t("medicationDelivered"),
      ],
      [CountryIso3166.Dk]: [
        t("lifestyleCoachingProgram"),
        t("personalTreatmentTeam"),
        t("fullAccessToUniverse"),
      ],
    },
    message: t("freeFirstConsultation"),
    cards: [
      <OnlineCoachingCard key="productCard:OnlinceCoachingCard" />,
      <GLP1MedicineCard key="productCard:GLP1MeidcineCard" />,
      <VirtualWeightLossUniverseCard key="productCard:VirtualWeightLossCard" />,
    ],
  } as IProduct,
  coachingOnly: {
    heading: t("coachingOnly.heading"),
    title: t("coachingOnly.title"),
    price:
      country === CountryIso3166.Dk
        ? t("coachingOnly.priceDK")
        : t("coachingOnly.priceUK"),
    pricePer: t("coachingOnly.pricePer"),
    features: {
      [CountryIso3166.Gb]: [
        t("lifestyleCoachingProgram"),
        t("personalTreatmentTeam"),
        t("fullAccessToUniverse"),
      ],
      [CountryIso3166.Dk]: [
        t("lifestyleCoachingProgram"),
        t("personalTreatmentTeam"),
        t("fullAccessToUniverse"),
      ],
    },
    message:
      country === CountryIso3166.Dk
        ? t("coachingOnly.messageDK")
        : t("coachingOnly.messageUK"),
    cards: [<OnlineCoachingCard />, <VirtualWeightLossUniverseCard />],
  } as IProduct,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const Offering: FunnelPageComponent<{}, IFunnelContext, undefined> = ({
  funnelApi,
  context,
}) => {
  const { t } = useTranslation();
  const { eligibleFor } = context;
  const productType =
    eligibleFor === ProductSelection.CoachingOnly
      ? Product.Coaching
      : Product.CoachingAndMedication;

  const priceInfo = getPriceInfoByType(productType, context.priceInfos || []);
  const product = getProductByType(productType);

  const finalProduct: IProduct = {
    ...product,
    ...(priceInfo
      ? {
          price: formatPrice(
            priceInfo.amount,
            context.locale,
            priceInfo.currency,
          ),
          discount: priceInfo.campaignAmount
            ? {
                title: priceInfo.campaignText,
                price: formatPrice(
                  priceInfo.campaignAmount,
                  context.locale,
                  priceInfo.currency,
                ),
                pricePer:
                  priceInfo.campaignDiscount?.durationInMonths === 1
                    ? product.pricePer.replace(t("perMonth"), t("firstMonth"))
                    : undefined,
              }
            : undefined,
        }
      : {}),
  };

  const renderDisclaimer = () => {
    const notElibgibleForMedsDisclaimer = productType === Product.Coaching;

    const cancerDisclaimer =
      eligibleFor === ProductSelection.CoachingAndMaybeMedication;

    if (notElibgibleForMedsDisclaimer) {
      return (
        <Disclaimer
          type="info"
          pillText={t("yourWeightLossPlan")}
          message={t("basedOnYourMedicalCondition")}
        />
      );
    }
    if (cancerDisclaimer) {
      return (
        <Disclaimer
          type="warning"
          pillText={t("yourWeightLossPlan")}
          message={t("basedOnYourMedicalHistory")}
        />
      );
    }
    return null;
  };

  const dislaimer = renderDisclaimer();

  return (
    <Stack>
      <Stack>
        <Stack align="center">
          {dislaimer}

          <Text mt={dislaimer ? undefined : "lg"} c="embla.3" fw={"bolder"}>
            {t("yourWeightLossPlan")}
          </Text>
          <StepTitle my={0} title={t("whatsIncluded")} />
          <Stack w="100%">
            {product.cards}
            <ProductCard product={finalProduct} />
          </Stack>
        </Stack>

        <Stack>
          <Stack>
            <NextButton onClick={() => funnelApi.next({})} />
            <BackButton onClick={() => funnelApi.back()} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
