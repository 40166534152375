import { useCallback, useEffect, useRef, useState } from "react";
import {
  FunnelEngineActions,
  FunnelEngineConfig,
  FunnelEngineContext,
  FunnelEngineData,
  FunnelEngineErrors,
  FunnelPageConfiguration,
  FunnelPageContext,
  FunnelPageData,
  IFunnelEngineHookOptions,
  IFunnelLoaderConfig,
} from "../types";

export const useFunnelLoading = <T extends FunnelEngineConfig>(
  options: IFunnelEngineHookOptions<T>,
  currentPageConfig: FunnelPageConfiguration<
    FunnelEngineData<T>,
    FunnelEngineContext<T>,
    FunnelEngineActions<T>,
    FunnelEngineErrors<T>
  >,
  initialLoad = false
) => {
  const { defaultLoader, noMinimumLoadingTime } = options;
  const [isLoading, setIsLoading] = useState(initialLoad);
  const currentLoaderRef = useRef<IFunnelLoaderConfig>({
    component: () => null,
  });

  const currentLoadTimeMs = useRef(0);

  const toggleLoader = useCallback(
    (
      loader: (typeof currentPageConfig)["loader"],
      context: FunnelEngineContext<T>,
      data: FunnelPageData<any>
    ) => {
      let resolvedLoader: Partial<IFunnelLoaderConfig>;

      if (typeof loader === "function") {
        resolvedLoader = loader({ context, data });
      } else {
        resolvedLoader = loader;
      }

      currentLoaderRef.current = {
        component: () => null,
        ...(defaultLoader || {}),
        ...(resolvedLoader || {}),
      };

      setIsLoading((v) => !v);
    },
    [defaultLoader]
  );

  const withLoading = useCallback(
    async <TLoadData>(
      loader: () => Promise<TLoadData>,
      context: FunnelEngineContext<T>,
      data: FunnelPageData<(typeof currentPageConfig)["component"]>,
      onLoaded?: (data: TLoadData) => void,
      customLoader?: IFunnelLoaderConfig
    ) => {
      if (customLoader) {
        currentLoaderRef.current = {
          ...(defaultLoader || {}),
          ...customLoader,
        };
      } else {
        const pageLoader = currentPageConfig.loader;
        let resolvedLoader: Partial<IFunnelLoaderConfig>;

        if (typeof pageLoader === "function") {
          resolvedLoader = pageLoader({ context, data });
        } else {
          resolvedLoader = pageLoader;
        }

        currentLoaderRef.current = {
          component: () => null,
          ...(defaultLoader || {}),
          ...(resolvedLoader || {}),
        };
      }

      currentLoadTimeMs.current =
        (currentLoaderRef.current.minimumDurationSeconds || 0) * 1000;

      const loadStart = new Date().getTime();

      setIsLoading(true);
      const loadData = await loader();

      const loadEnd = new Date().getTime();
      const loadDurationMs = loadEnd - loadStart;

      if (!noMinimumLoadingTime) {
        await new Promise((resolve) =>
          setTimeout(
            resolve,
            Math.max(0, currentLoadTimeMs.current - loadDurationMs)
          )
        );
      }

      onLoaded?.(loadData);
      setIsLoading(false);
    },
    [defaultLoader, noMinimumLoadingTime, currentPageConfig.loader]
  );

  return {
    isLoading,
    currentLoader: currentLoaderRef.current,
    withLoading,
    toggleLoader,
    currentLoadTimeMs,
  };
};
