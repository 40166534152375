export const env = (function () {
  const root = `${import.meta.env["VITE_SERVER_ROOT_URL"]}`;
  const gtmId = `${import.meta.env["VITE_GTM_ID"]}`;
  const recaptchaKey = import.meta.env["VITE_RECAPTCHA_KEY"]
    ? `${import.meta.env["VITE_RECAPTCHA_KEY"]}`
    : undefined;
  return {
    serverRoot: root,
    gqlUrl: `${root}/graphql`,
    gtmId: gtmId,
    recaptchaKey: recaptchaKey,
    growthbook: {
      clientKey: `${import.meta.env["VITE_GROWTHBOOK_CLIENT_KEY"]}`,
      apiHost: `${import.meta.env["VITE_GROWTHBOOK_API_HOST"]}`,
    },
  };
})();
