import { FunctionComponent, PropsWithChildren, createContext, useContext } from 'react';
import { v4 } from 'uuid';

import api from './api';
import { TrackingEventName } from './gql/sdk';

const getCookieValue = (name: string) => document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

type TrackingContextType = {
  trackEvent: (eventName: TrackingEventName, data?: Data) => Promise<void>;
  anonymousId: string;
  registerTrackingSuperProperties: (data: Data) => void;
};
type Data = Record<string, string | number | boolean | string[] | number[] | boolean[]>;
function getOS() {
  if (typeof window === 'undefined') {
    return 'undetermined';
  }

  const { userAgent } = window.navigator;
  const macosPlatforms = /(Macintosh)|(MacIntel)|(MacPPC)|(Mac68K)/i;
  const windowsPlatforms = /(Win32)|(Win64)|(Windows)|(WinCE)/i;
  const iosPlatforms = /(iPhone)|(iPad)|(iPod)/i;

  if (macosPlatforms.test(userAgent)) {
    return 'macos';
  }
  if (iosPlatforms.test(userAgent)) {
    return 'ios';
  }
  if (windowsPlatforms.test(userAgent)) {
    return 'windows';
  }
  if (/Android/i.test(userAgent)) {
    return 'android';
  }
  if (/Linux/i.test(userAgent)) {
    return 'linux';
  }

  return 'undetermined';
}

class TrackingService {
  public anonymousId: string;
  private superProperties: Data;

  private hasSession() {
    return !!sessionStorage.getItem('token');
  }

  constructor() {
    const randomId = getCookieValue('_embla_identifier') || v4();
    // Do not change the name of anonymous_id or the place where it is stored
    // As it is used in GTM tracking to stich user sessions from the website
    this.anonymousId = localStorage.getItem('anonymous_id') || randomId;
    if (!localStorage.getItem('anonymous_id')) {
      localStorage.setItem('anonymous_id', this.anonymousId);
    }
  }

  public pushGTMEvent = (eventName: string, data: Record<string, string> = {}) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ((window as any).dataLayer as any[])?.push({
      event: eventName,
      ...data,
    });
  };

  public trackEvent = async (eventName: TrackingEventName | string, data: Data = {}) => {
    this.pushGTMEvent('trackingEvent', { funnelEvent: eventName, ...data });

    await api.TrackEvent({
      eventName,
      data: JSON.stringify({
        anonymous_id:
          !this.hasSession() || eventName === TrackingEventName.SignupRegistered ? this.anonymousId : undefined,
        ...(this.superProperties || {}),
        ...(data || {}),
        app_version: APP_VERSION,
        os: getOS(),
      }),
    });
  };

  public registerTrackingSuperProperties = (data: Data) => {
    this.superProperties = data;
  };
}

const trackingService = new TrackingService();

const TrackingContext = createContext<TrackingContextType>(undefined);
const TrackingContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <TrackingContext.Provider
      value={{
        trackEvent: trackingService.trackEvent,
        registerTrackingSuperProperties: trackingService.registerTrackingSuperProperties,
        anonymousId: trackingService.anonymousId,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

const useTracking = (): TrackingContextType => {
  const context = useContext(TrackingContext);
  if (context === undefined) {
    throw new Error('TrackingContext was used outside of its Provider');
  }
  return context;
};
export { TrackingContextProvider, useTracking, trackingService };
