import { Box, Container, Flex, ScrollArea } from '@mantine/core';
import { FunctionComponent, useEffect, useRef, useState } from 'react';

type Insets = { top: number; bottom: number; left: number; right: number };
export const EmblaTopContainer: FunctionComponent<
  React.PropsWithChildren<{
    noPadding?: boolean;
    fullWidth?: boolean;
  }>
> = ({ children, noPadding, fullWidth }) => {
  const [insets, setInsets] = useState<Insets>();
  const loading = useRef(false);

  useEffect(() => {
    if (!loading.current) {
      loading.current = true;
      const params = new URLSearchParams(window.location.search);
      if (params.has('insets')) {
        const insets = params.get('insets');
        const decoded = decodeURIComponent(insets);
        const parsed = JSON.parse(decoded.replace(/\\\\"/g, '"'));
        setInsets(parsed);
      }
    }
  }, []);
  return (
    <Box
      style={{
        display: 'block',
        position: 'absolute',
        height: 'auto',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        marginBottom: insets?.bottom,
        marginTop: insets?.top,
        marginLeft: insets?.left,
        marginRight: insets?.right,
      }}
    >
      <ScrollArea h={'100%'}>
        <Container
          size={fullWidth ? '100%' : 'xs'}
          p={noPadding ? 0 : 'md'}
          h={'100%'}
          w={fullWidth ? '100%' : undefined}
        >
          <Flex direction={'column'} h={'100%'} w={fullWidth ? '100%' : undefined}>
            {children}
          </Flex>
        </Container>
      </ScrollArea>
    </Box>
  );
};
