import {
  ClientMotivation,
  CountryIso3166,
  EligibilityType,
  FinancialPrice,
  FinancialProduct,
  LocaleIso6391,
  MarketingLeadTag,
  Product,
  UtmHeaders,
} from '@shared/gql/sdk';
import { parsePhoneNumber } from 'libphonenumber-js/mobile';
import { PRODUCTS } from 'src/v3/pages/Offering';

import { t } from '../src/i18n';
import { resolveIntl } from '../src/resolveIntl';

export const calculateBmi = (weight: number, height: number) => {
  const bmi = weight && height ? weight / ((height * height) / 10000) : undefined;
  return bmi ? Math.trunc(bmi * 10) / 10 : undefined;
};

export const getStonesAndPounds = (weight: number) => {
  const stones = Math.floor(weight / 6.35029318);
  const pounds = Math.round((weight % 6.35029318) / 0.45359237);
  return { stones, pounds };
};

export type UnitSystem = 'metric' | 'imperial';

export const getRootPath = () => {
  const { country } = resolveIntl();
  const countryCode = country && country !== CountryIso3166.Gb ? country.toLowerCase() : 'uk';
  return `/${countryCode.toLowerCase()}/signup`;
};

export const getUrlParams = () => {
  return new URLSearchParams(window.location.search);
};

export const debugEnabled = () => {
  const enabledInStorage = localStorage.getItem('debug') === 'true';
  const enabledInUrl = getUrlParams().get('debug') === 'true';

  if (!enabledInStorage && enabledInUrl) {
    localStorage.setItem('debug', 'true');
  }

  return enabledInUrl || enabledInStorage;
};

const mapUtmHeaders = (rawUtmHeaders: Record<string, string>): UtmHeaders => {
  const utmHeaders: UtmHeaders = {
    utm_source: rawUtmHeaders.utm_source,
    utm_medium: rawUtmHeaders.utm_medium,
    utm_campaign: rawUtmHeaders.utm_campaign,
    utm_keyword: rawUtmHeaders.utm_keyword,
    utm_content: rawUtmHeaders.utm_content,
    utm_adgroupid: rawUtmHeaders.utm_adgroupid,
    fbclid: rawUtmHeaders.fbclid,
    gclid: rawUtmHeaders.gclid,
  };
  return utmHeaders;
};

export const getUtmHeaders = (): UtmHeaders => {
  const fromStorage = sessionStorage.getItem('utmHeaders');
  if (fromStorage) {
    try {
      return mapUtmHeaders(JSON.parse(fromStorage));
    } catch (e) {
      // ignore
    }
  }

  const urlParams = getUrlParams();
  const utmHeaders: Record<string, string> = {};
  urlParams.forEach((val, key) => {
    if (key.startsWith('utm_') || key === 'fbclid' || key === 'gclid') {
      utmHeaders[key] = val;
    }
  });

  if (Object.keys(utmHeaders)?.length > 0) {
    sessionStorage.setItem('utmHeaders', JSON.stringify(utmHeaders));
    return mapUtmHeaders(utmHeaders);
  }
};

type NotificationConfig = {
  notifyMe?: boolean;
  completed?: boolean;
  tag?: MarketingLeadTag;
};

export const setNotificationConfig = (config: NotificationConfig) => {
  const currentConfig = getNotificationConfig();
  sessionStorage.setItem('notificationConfig', JSON.stringify({ ...currentConfig, ...config }));
};

export const getNotificationConfig = (): NotificationConfig => {
  let config = sessionStorage.getItem('notificationConfig');
  if (!config) {
    const isNotifyMeFlow = window.location.pathname.includes('notify-me');
    const tagFromUrl = getUrlParams().get('notify-me-tag');
    const tag = MarketingLeadTag[tagFromUrl as keyof typeof MarketingLeadTag];
    config = JSON.stringify({
      notifyMe: isNotifyMeFlow,
      completed: false,
      tag: tag || MarketingLeadTag.WaitingForScreeningCapacity,
    });
    sessionStorage.setItem('notificationConfig', config);
  }

  return JSON.parse(config);
};

export const mapClientScreeningToServer = (screening: Record<string, boolean>) => {
  return Object.keys(screening).map((key) => ({
    id: key,
    label: t(`screeningQuestion.${key}`),
    value: !screening[key],
  }));
};

export const mapServerScreeningToClientScreening = (screening: { id: string; value: boolean }[]) => {
  return screening.reduce(
    (acc, curr) => {
      acc[curr.id] = !curr.value;
      return acc;
    },
    {} as Record<string, boolean>,
  );
};

export const mapServerMotivationsToClient = (motivations: ClientMotivation[]) => {
  if (motivations.length === 0) {
    return null;
  }
  const clientMotivations: Record<ClientMotivation, boolean> = {
    [ClientMotivation.FitnessAndConfidence]: false,
    [ClientMotivation.HealthierLifestyle]: false,
    [ClientMotivation.ImproveMentalHealth]: false,
    [ClientMotivation.LowerRiskOfDiseases]: false,
    [ClientMotivation.MoreEnergy]: false,
    [ClientMotivation.SustainableWeightLoss]: false,
    [ClientMotivation.ImprovePhysicalHealth]: false,
    [ClientMotivation.Other]: false,
  };
  motivations.forEach((motivation) => {
    clientMotivations[motivation] = true;
  });
  return clientMotivations;
};

export const sleep = async (timeInMilllies: number) => {
  return new Promise((resolve) => setTimeout(resolve, timeInMilllies));
};

export const validatePhone = (phone: string) => {
  try {
    const parsedPhoneNumber = parsePhoneNumber(phone);

    return parsedPhoneNumber.isValid() ? null : t('invalidPhoneNumber');
  } catch (err) {
    return t('invalidPhoneNumber');
  }
};

export const validateEmail = (email: string) => {
  // this regex was copied from our email validation in the backend
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email) ? null : t('invalidEmail');
};
export { resolveIntl };

export const getPriceInfoByType = (type: Product, priceInfos: FinancialPrice[]) => {
  switch (type) {
    case Product.Coaching:
      return priceInfos.find((pi) => pi.product.eligibilityType === EligibilityType.Coaching);
    case Product.CoachingAndMedication:
      return priceInfos.find((pi) => pi.product.eligibilityType === EligibilityType.CoachingAndMedication);
  }
};

export const getProductByType = (type: Product) => {
  switch (type) {
    case Product.Coaching:
      return PRODUCTS.coachingOnly;
    case Product.CoachingAndMedication:
      return PRODUCTS.medicalWeightLoss;
    default:
      return PRODUCTS.coachingOnly;
  }
};

export const formatPrice = (amount: number, locale: LocaleIso6391, currency: string) => {
  const formatCurrency = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  });

  return formatCurrency.format(amount / 100);
};

export const formatAmount = (amount: number, currency: FinancialPrice['currency']) => {
  const decimalAmount = (amount / 100).toFixed(2);

  switch (currency) {
    case 'gbp':
      return `£${decimalAmount}`;
    case 'dkk':
      return `kr. ${decimalAmount},-`;
  }
};

export const formatInterval = (price: FinancialPrice, format: 'long' | 'short' = 'long') => {
  if (!price) return '';

  switch (price.paymentIntervalCode) {
    case 'month':
      return format === 'long' ? t('monthly') : t('monthly.short');
  }
};

/**
 * Retrieves the name for a given product, with custom mappers.
 *
 * @param product - The Financial product.
 * @returns The label for the product type.
 */
export const getProductNameByProduct = (product: Pick<FinancialProduct, 'name' | 'eligibilityType'>) => {
  switch (product.eligibilityType) {
    case EligibilityType.Coaching:
      return t('productName.emblaCoach');
    case EligibilityType.CoachingAndMedication:
      return t('coachingAndMedication');
    default:
      return product.name;
  }
};
