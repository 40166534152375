import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'node_modules/graphql-request/build/cjs/types';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  JSON: { input: any; output: any };
};

export type Absence = {
  __typename?: 'Absence';
  description?: Maybe<Scalars['String']['output']>;
  enableSubstitution?: Maybe<Scalars['Boolean']['output']>;
  end?: Maybe<Scalars['Date']['output']>;
  excludedSubstituteServices?: Maybe<Array<Maybe<CareTeamService>>>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  muteNotifications?: Maybe<Scalars['Boolean']['output']>;
  start?: Maybe<Scalars['Date']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type AbsenceAffectedClient = {
  __typename?: 'AbsenceAffectedClient';
  client?: Maybe<User>;
  clientId?: Maybe<Scalars['ID']['output']>;
  gaps?: Maybe<Array<Maybe<AbsenceSubstitutionPeriod>>>;
  substitutions?: Maybe<Array<Maybe<AbsenceSubstitution>>>;
};

export type AbsenceRegistrationPreview = {
  __typename?: 'AbsenceRegistrationPreview';
  affectedAppointments?: Maybe<Array<Maybe<Appointment>>>;
  affectedPrimaryClients?: Maybe<Array<Maybe<AbsenceAffectedClient>>>;
};

export type AbsenceRegistrationSubstitution = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  employeeId?: InputMaybe<Scalars['ID']['input']>;
};

export type AbsenceSubstitution = {
  __typename?: 'AbsenceSubstitution';
  substitutionPeriods?: Maybe<Array<Maybe<AbsenceSubstitutionPeriod>>>;
  user?: Maybe<User>;
};

export type AbsenceSubstitutionPeriod = {
  __typename?: 'AbsenceSubstitutionPeriod';
  end?: Maybe<Scalars['Date']['output']>;
  start?: Maybe<Scalars['Date']['output']>;
};

export type AbuseReport = {
  __typename?: 'AbuseReport';
  clientId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hideMessage?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  messageThreadId: Scalars['ID']['output'];
  reason?: Maybe<AbuseReportReason>;
  userImageId?: Maybe<Scalars['String']['output']>;
};

export type AbuseReportInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  hideMessage?: InputMaybe<Scalars['Boolean']['input']>;
  messageId: Scalars['ID']['input'];
  reason?: InputMaybe<AbuseReportReason>;
  screenshotBase64?: InputMaybe<Scalars['String']['input']>;
};

export enum AbuseReportReason {
  BullyingOrHarassment = 'BULLYING_OR_HARASSMENT',
  FalseInformation = 'FALSE_INFORMATION',
  HateSpeechOrSymbols = 'HATE_SPEECH_OR_SYMBOLS',
  IntellectualPropertyViolation = 'INTELLECTUAL_PROPERTY_VIOLATION',
  NudityOrSexualContent = 'NUDITY_OR_SEXUAL_CONTENT',
  Other = 'OTHER',
  SaleOrPromotion = 'SALE_OR_PROMOTION',
  ScamsOrFraud = 'SCAMS_OR_FRAUD',
  SuicideSelfInjuryOrEatingDisorders = 'SUICIDE_SELF_INJURY_OR_EATING_DISORDERS',
  ViolenceOrDangerousOrganisations = 'VIOLENCE_OR_DANGEROUS_ORGANISATIONS',
}

export type AbuseReview = {
  __typename?: 'AbuseReview';
  id?: Maybe<Scalars['ID']['output']>;
  messageId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<AbuseReviewStatus>;
  taskId?: Maybe<Scalars['ID']['output']>;
};

export type AbuseReviewInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  status: AbuseReviewStatus;
};

export enum AbuseReviewStatus {
  Acknowledged = 'ACKNOWLEDGED',
  Dismissed = 'DISMISSED',
  InProgress = 'IN_PROGRESS',
}

export type AchievementsStatus = {
  __typename?: 'AchievementsStatus';
  id?: Maybe<Scalars['ID']['output']>;
  lvl?: Maybe<Scalars['Int']['output']>;
  lvlGreeting?: Maybe<Scalars['String']['output']>;
  lvlProgression?: Maybe<Scalars['Int']['output']>;
  lvlTitle?: Maybe<Scalars['String']['output']>;
  totalMeasurements?: Maybe<Scalars['Int']['output']>;
  totalScore?: Maybe<Scalars['Int']['output']>;
  totalWeightLossPct?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type Address = {
  __typename?: 'Address';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryISO3166?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryISO3166?: InputMaybe<Scalars['String']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type AppContext = {
  __typename?: 'AppContext';
  build?: Maybe<Scalars['String']['output']>;
  deviceManufacturer?: Maybe<Scalars['String']['output']>;
  deviceModelId?: Maybe<Scalars['String']['output']>;
  deviceName?: Maybe<Scalars['String']['output']>;
  nativeVersion?: Maybe<Scalars['String']['output']>;
  osName?: Maybe<Scalars['String']['output']>;
  osVersion?: Maybe<Scalars['String']['output']>;
  platformOs?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type Appointment = {
  __typename?: 'Appointment';
  appointmentDate?: Maybe<Scalars['Date']['output']>;
  appointmentReason?: Maybe<AppointmentReason>;
  calendar?: Maybe<Calendar>;
  calendarId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['Date']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  endTime?: Maybe<Scalars['Date']['output']>;
  expired?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  invitation?: Maybe<ClientInvitation>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  usersId?: Maybe<Scalars['String']['output']>;
};

export enum AppointmentReason {
  ClinicalAid = 'CLINICAL_AID',
  Coaching = 'COACHING',
  FollowUp = 'FOLLOW_UP',
  Other = 'OTHER',
  Psychology = 'PSYCHOLOGY',
  Research = 'RESEARCH',
  Screening = 'SCREENING',
  SideEffect = 'SIDE_EFFECT',
  Unknown = 'UNKNOWN',
}

export enum AppointmentStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Noshow = 'NOSHOW',
  PendingPayment = 'PENDING_PAYMENT',
}

export type Auth0Error = {
  __typename?: 'Auth0Error';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum AuthorizationErrorCode {
  TokenExpired = 'TokenExpired',
  TokenInvalid = 'TokenInvalid',
  TokenRevoked = 'TokenRevoked',
}

export type BmiResult = {
  __typename?: 'BmiResult';
  isBetween27And30?: Maybe<Scalars['Boolean']['output']>;
  isLessThan27?: Maybe<Scalars['Boolean']['output']>;
  isMoreThan30?: Maybe<Scalars['Boolean']['output']>;
};

export type BookableAppointment = {
  __typename?: 'BookableAppointment';
  calendar?: Maybe<Calendar>;
  calendarId?: Maybe<Scalars['ID']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
};

export type BookableAppointmentV2 = {
  __typename?: 'BookableAppointmentV2';
  calendar: Calendar;
  calendarId: Scalars['ID']['output'];
  endTime: Scalars['Date']['output'];
  startTime: Scalars['Date']['output'];
  userName?: Maybe<Scalars['String']['output']>;
};

export type Calendar = {
  __typename?: 'Calendar';
  active?: Maybe<Scalars['Boolean']['output']>;
  appointments?: Maybe<Array<Maybe<Appointment>>>;
  availableTimespans?: Maybe<Array<Maybe<CalendarAvailableTimespan>>>;
  calendarAvailability?: Maybe<Array<Maybe<CalendarAvailability>>>;
  calendarExceptions?: Maybe<Array<Maybe<CalendarException>>>;
  exceptionTimespans?: Maybe<Array<Maybe<CalendarExceptionTimespan>>>;
  id?: Maybe<Scalars['ID']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type CalendarAppointmentsArgs = {
  dateFrom?: InputMaybe<Scalars['Date']['input']>;
  dateTo?: InputMaybe<Scalars['Date']['input']>;
};

export type CalendarAvailableTimespansArgs = {
  dateFrom?: InputMaybe<Scalars['Date']['input']>;
  dateTo?: InputMaybe<Scalars['Date']['input']>;
};

export type CalendarExceptionTimespansArgs = {
  dateFrom?: InputMaybe<Scalars['Date']['input']>;
  dateTo?: InputMaybe<Scalars['Date']['input']>;
};

export type CalendarAvailability = {
  __typename?: 'CalendarAvailability';
  active?: Maybe<Scalars['Boolean']['output']>;
  dateFrom?: Maybe<Scalars['Date']['output']>;
  dateTo?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isoweekdays?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  timeslots?: Maybe<Array<Maybe<CalendarAvailabilityTimeslots>>>;
};

export type CalendarAvailabilityInputV2 = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  isoweekdays?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  timeslots?: InputMaybe<Array<InputMaybe<CalendarAvailabilityTimeslotsInputV2>>>;
};

export type CalendarAvailabilityTimeslots = {
  __typename?: 'CalendarAvailabilityTimeslots';
  endTimeHHMM?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  startTimeHHMM?: Maybe<Scalars['String']['output']>;
};

export type CalendarAvailabilityTimeslotsInputV2 = {
  endTimeHHMM: Scalars['String']['input'];
  startTimeHHMM: Scalars['String']['input'];
};

export type CalendarAvailableTimespan = {
  __typename?: 'CalendarAvailableTimespan';
  availabilityIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  calendarId?: Maybe<Scalars['ID']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type CalendarException = {
  __typename?: 'CalendarException';
  active?: Maybe<Scalars['Boolean']['output']>;
  calendarId?: Maybe<Scalars['String']['output']>;
  dateFrom?: Maybe<Scalars['Date']['output']>;
  dateTo?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CalendarExceptionInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
};

export type CalendarExceptionTimespan = {
  __typename?: 'CalendarExceptionTimespan';
  calendarId?: Maybe<Scalars['ID']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  exceptionIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type CalendarInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CalendarType>;
};

export enum CalendarType {
  Coach = 'COACH',
  Nursing = 'NURSING',
  Psychology = 'PSYCHOLOGY',
  Screening = 'SCREENING',
}

export type CareTeamCondition = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  employeeUserId?: InputMaybe<Scalars['ID']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  responsibility?: InputMaybe<CareTeamResponsibility>;
};

export type CareTeamInput = {
  excludes?: InputMaybe<Array<InputMaybe<CareTeamCondition>>>;
  includes?: InputMaybe<Array<InputMaybe<CareTeamCondition>>>;
};

export type CareTeamMember = {
  __typename?: 'CareTeamMember';
  causedBy?: Maybe<Scalars['String']['output']>;
  client?: Maybe<User>;
  clientUserId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  employee?: Maybe<User>;
  employeeUserId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  periodEnd?: Maybe<Scalars['Date']['output']>;
  periodStart?: Maybe<Scalars['Date']['output']>;
  primary?: Maybe<Scalars['Boolean']['output']>;
  responsibility?: Maybe<CareTeamResponsibility>;
};

export enum CareTeamResponsibility {
  Coach = 'COACH',
  CoCoach = 'CO_COACH',
  Doctor = 'DOCTOR',
  Nurse = 'NURSE',
}

export enum CareTeamService {
  CalendarBooking = 'CALENDAR_BOOKING',
}

export enum CatchAllStatus {
  Handled = 'HANDLED',
  Unhandled = 'UNHANDLED',
}

export type ChristmasCalendar = {
  __typename?: 'ChristmasCalendar';
  days?: Maybe<Array<Maybe<ChristmasCalendarDay>>>;
};

export type ChristmasCalendarDay = {
  __typename?: 'ChristmasCalendarDay';
  canParticipate?: Maybe<Scalars['Boolean']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  day?: Maybe<Scalars['Int']['output']>;
  hasParticipated?: Maybe<Scalars['Boolean']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  isOpen?: Maybe<Scalars['Boolean']['output']>;
  learningId?: Maybe<Scalars['String']['output']>;
};

export enum ChurnReason {
  Conditions = 'conditions',
  FinancesPersonalReasonsChanges = 'finances_personal_reasons_changes',
  FinancesValue = 'finances_value',
  GoalAchieved = 'goal_achieved',
  Motivation = 'motivation',
  NoEffectOfMedication = 'no_effect_of_medication',
  OtherSolutionCause = 'other_solution_cause',
  PersonalReasons = 'personal_reasons',
  SideEffects = 'side_effects',
  UnknownCause = 'unknown_cause',
}

export type Client = {
  __typename?: 'Client';
  CPRNumber?: Maybe<Scalars['String']['output']>;
  alias?: Maybe<Scalars['String']['output']>;
  availableStages: Array<Maybe<ClientStage>>;
  bio?: Maybe<Scalars['String']['output']>;
  canSelectSubscription?: Maybe<Scalars['Boolean']['output']>;
  careTeamMembers: Array<Maybe<CareTeamMember>>;
  completedFeedItemTypes?: Maybe<Array<Maybe<FeedItemType>>>;
  consentedCommunityTermsVersion?: Maybe<Scalars['Int']['output']>;
  consentedLogbookVersion?: Maybe<Scalars['Int']['output']>;
  consentedPrivacyVersion?: Maybe<Scalars['Int']['output']>;
  consentedTermsVersion?: Maybe<Scalars['Int']['output']>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  emailMarketing?: Maybe<Scalars['Boolean']['output']>;
  feed?: Maybe<ClientFeedResponse>;
  feelingAboutWeightLoss?: Maybe<FeelingAboutWeightLoss>;
  gamificationConfiguration: GamificationConfiguration;
  heardAboutEmbla?: Maybe<HeardAboutEmbla>;
  heightCm?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  invitations?: Maybe<Array<Maybe<ClientInvitation>>>;
  isMedicationClient: Scalars['Boolean']['output'];
  lastWeekMeasurementCount?: Maybe<Scalars['Int']['output']>;
  leadershipBoardPositions?: Maybe<Array<Maybe<LeadershipBoardPosition>>>;
  learningAssignments?: Maybe<Array<Maybe<LearningAssignment>>>;
  learningBookmarks?: Maybe<Array<Maybe<LearningBookmark>>>;
  learningCompletions?: Maybe<Array<Maybe<LearningCompletion>>>;
  measurementNudgeSettings?: Maybe<Array<Maybe<MeasurementNudgeSetting>>>;
  memberFocus?: Maybe<Array<Maybe<MemberFocus>>>;
  motivations: Array<Maybe<ClientMotivation>>;
  mutedFeedItemTypes?: Maybe<Array<Maybe<FeedItemType>>>;
  pastWeightLossSolutions?: Maybe<Array<Maybe<WeightLossSolution>>>;
  profileLink?: Maybe<Scalars['String']['output']>;
  reminders?: Maybe<Array<Maybe<Reminder>>>;
  remindersDisabled?: Maybe<Scalars['Boolean']['output']>;
  screeningForm?: Maybe<Scalars['Boolean']['output']>;
  screeningResults?: Maybe<Scalars['String']['output']>;
  sexAssignedAtBirth?: Maybe<SexAssignedAtBirth>;
  signedupWhileOnMedication?: Maybe<Scalars['Boolean']['output']>;
  signupCurrentMedication?: Maybe<Array<Maybe<CurrentMedication>>>;
  signupMedicationWanted?: Maybe<MedicationWantedAnswers>;
  signupPregnantOrBreastfeeding?: Maybe<Scalars['Boolean']['output']>;
  signupStatus?: Maybe<SignupStatus>;
  stage?: Maybe<ClientStage>;
  statusActionsAndFeatures?: Maybe<UserStatusActionsAndFeatures>;
  subscriptions?: Maybe<Array<Maybe<ClientSubscription>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  trustPilotReviews?: Maybe<Array<Maybe<TrustPilotReview>>>;
  type?: Maybe<ClientType>;
  user: User;
};

export type ClientLeadershipBoardPositionsArgs = {
  date?: InputMaybe<Scalars['Date']['input']>;
};

export type ClientActivity = {
  __typename?: 'ClientActivity';
  lastMeasurementAt?: Maybe<Scalars['String']['output']>;
  lastMeasurementSubType?: Maybe<Scalars['String']['output']>;
  lastMeasurementType?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type ClientCommunities = {
  __typename?: 'ClientCommunities';
  joinedCommunities?: Maybe<Array<Maybe<CommunityInfoWithMessageThread>>>;
  suggestedCommunities?: Maybe<Array<Maybe<CommunityInfoWithMessageThread>>>;
};

export type ClientFeedResponse = {
  __typename?: 'ClientFeedResponse';
  clientId: Scalars['ID']['output'];
  communitySuggestions?: Maybe<Array<Maybe<CommunityInfo>>>;
  forecast?: Maybe<Scalars['String']['output']>;
  greeting?: Maybe<ClientGreeting>;
  greetings?: Maybe<Scalars['String']['output']>;
  invitationItems?: Maybe<Array<Maybe<FeedItem>>>;
  myCommunities?: Maybe<Array<Maybe<CommunityInfo>>>;
  plan?: Maybe<Array<Maybe<FeedItem>>>;
  todaysSuggestions?: Maybe<Array<Maybe<ClientFeedSuggestion>>>;
};

export type ClientFeedSuggestion = {
  __typename?: 'ClientFeedSuggestion';
  description?: Maybe<Scalars['String']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  learningArticle?: Maybe<LearningArticle>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ClientGameFriendFeedItem = {
  __typename?: 'ClientGameFriendFeedItem';
  externalProfile?: Maybe<ExternalProfile>;
  id?: Maybe<Scalars['ID']['output']>;
  measurement?: Maybe<ExternalMeasurement>;
  reactions?: Maybe<Array<Maybe<Reaction>>>;
  xpPointsEarned?: Maybe<Scalars['Int']['output']>;
};

export type ClientGameOverview = {
  __typename?: 'ClientGameOverview';
  acceptedRelationships?: Maybe<Array<Maybe<Relationship>>>;
  charts?: Maybe<ClientGameOverviewCharts>;
  clientInfo?: Maybe<GameClientInfo>;
  currentWeekStreakScore?: Maybe<Scalars['Int']['output']>;
  daysSinceJoined?: Maybe<Scalars['Int']['output']>;
  favoritExercise?: Maybe<ExerciseType>;
  healthIndex?: Maybe<Scalars['Int']['output']>;
  incomingPendingRelationships?: Maybe<Array<Maybe<Relationship>>>;
  totalActivities?: Maybe<Scalars['Int']['output']>;
  totalXP?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  weekStreak?: Maybe<Scalars['Int']['output']>;
  weeklyXP?: Maybe<Scalars['Int']['output']>;
};

export type ClientGameOverviewCharts = {
  __typename?: 'ClientGameOverviewCharts';
  userId?: Maybe<Scalars['ID']['output']>;
  xpComparison?: Maybe<GameChart>;
};

export type ClientGreeting = {
  __typename?: 'ClientGreeting';
  header?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ClientInput = {
  completedFeedItemTypes?: InputMaybe<Array<InputMaybe<FeedItemType>>>;
  heightCm?: InputMaybe<Scalars['Int']['input']>;
  motivations?: InputMaybe<Array<InputMaybe<ClientMotivation>>>;
  signedupWhileOnMedication?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientInvitation = {
  __typename?: 'ClientInvitation';
  appointmentId?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Employee>;
  durationMins?: Maybe<Scalars['Int']['output']>;
  exposedCalendarTypes?: Maybe<Array<Maybe<CalendarType>>>;
  exposedCalendars?: Maybe<Array<Maybe<Calendar>>>;
  id?: Maybe<Scalars['ID']['output']>;
  invitationExpireAt?: Maybe<Scalars['Date']['output']>;
  invitationStartAt?: Maybe<Scalars['Date']['output']>;
  price?: Maybe<FinancialPrice>;
  reason?: Maybe<AppointmentReason>;
  status?: Maybe<InvitationStatus>;
  type?: Maybe<InvitationType>;
};

export type ClientInvitationCreate = {
  clientId: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  durationMins?: InputMaybe<Scalars['Int']['input']>;
  exposedCalendarIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exposedCalendarTypes?: InputMaybe<Array<InputMaybe<CalendarType>>>;
  invitationExpireAt: Scalars['Date']['input'];
  invitationStartAt: Scalars['Date']['input'];
  priceId?: InputMaybe<Scalars['String']['input']>;
  reason: AppointmentReason;
};

export type ClientInvitationUpdate = {
  comment?: InputMaybe<Scalars['String']['input']>;
  durationMins?: InputMaybe<Scalars['Int']['input']>;
  exposedCalendarIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exposedCalendarTypes?: InputMaybe<Array<InputMaybe<CalendarType>>>;
  invitationExpireAt: Scalars['Date']['input'];
  invitationStartAt: Scalars['Date']['input'];
  priceId?: InputMaybe<Scalars['String']['input']>;
  reason: AppointmentReason;
};
export enum ClientMotivation {
  FitnessAndConfidence = 'FITNESS_AND_CONFIDENCE',
  HealthierLifestyle = 'HEALTHIER_LIFESTYLE',
  ImproveMentalHealth = 'IMPROVE_MENTAL_HEALTH',
  ImprovePhysicalHealth = 'IMPROVE_PHYSICAL_HEALTH',
  LowerRiskOfDiseases = 'LOWER_RISK_OF_DISEASES',
  MoreEnergy = 'MORE_ENERGY',
  Other = 'OTHER',
  SustainableWeightLoss = 'SUSTAINABLE_WEIGHT_LOSS',
}

export type ClientSearchQueryArgs = {
  careTeam?: InputMaybe<CareTeamInput>;
  coachId?: InputMaybe<Scalars['ID']['input']>;
  excludedTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastWeekMeasurementCount?: InputMaybe<NumberRange>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
  stripeProductIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<ClientType>;
};

export enum ClientStage {
  Stage_1 = 'STAGE_1',
  Stage_2 = 'STAGE_2',
  Stage_3 = 'STAGE_3',
}

export type ClientSubscription = {
  __typename?: 'ClientSubscription';
  productType?: Maybe<Product>;
  status?: Maybe<SubscriptionStatus>;
};

export enum ClientType {
  Alumni = 'ALUMNI',
  Freemium = 'FREEMIUM',
  Lead = 'LEAD',
  Member = 'MEMBER',
}

export type ClientWeeklyReport = {
  __typename?: 'ClientWeeklyReport';
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['ID']['output']>;
  report?: Maybe<ClientWeeklyReportData>;
};

export type ClientWeeklyReportData = {
  __typename?: 'ClientWeeklyReportData';
  days?: Maybe<Array<Maybe<ClientWeeklyReportDataDay>>>;
};

export type ClientWeeklyReportDataDay = {
  __typename?: 'ClientWeeklyReportDataDay';
  bodyMeasurement?: Maybe<Array<Maybe<Measurement>>>;
  breakfast?: Maybe<Scalars['String']['output']>;
  breakfastPostSnack?: Maybe<Scalars['String']['output']>;
  completed?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['Date']['output']>;
  dinner?: Maybe<Scalars['String']['output']>;
  dinnerPostSnack?: Maybe<Scalars['String']['output']>;
  exercise?: Maybe<Array<Maybe<Measurement>>>;
  foodNote?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<Maybe<MeasurementImage>>>;
  liquid?: Maybe<Array<Maybe<Measurement>>>;
  lunch?: Maybe<Scalars['String']['output']>;
  lunchPostSnack?: Maybe<Scalars['String']['output']>;
  medication?: Maybe<Array<Maybe<Measurement>>>;
  satietyLevel?: Maybe<Scalars['String']['output']>;
  sleep?: Maybe<Array<Maybe<Measurement>>>;
  status?: Maybe<Array<Maybe<Measurement>>>;
  steps?: Maybe<Measurement>;
  symptomes?: Maybe<Array<Maybe<Measurement>>>;
  weight?: Maybe<Array<Maybe<Measurement>>>;
};

export type ClientWithLatestAppointment = {
  __typename?: 'ClientWithLatestAppointment';
  latestAppointment?: Maybe<Appointment>;
  user?: Maybe<User>;
};

export type ClinicalScreening = {
  __typename?: 'ClinicalScreening';
  approvedForProducts: Array<Maybe<Product>>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  preformedByUser: User;
  preformedByUserId: Scalars['String']['output'];
  productDiscountCoupon?: Maybe<Scalars['String']['output']>;
  result: ClinicalScreeningResult;
  user: User;
  userFeedback?: Maybe<ClinicalScreeningUserFeedback>;
  userId: Scalars['String']['output'];
};

export enum ClinicalScreeningResult {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  TimedOut = 'TIMED_OUT',
}

export enum ClinicalScreeningUserFeedback {
  BmiBelow_30NotInterestedInCoaching = 'BMI_BELOW_30_NOT_INTERESTED_IN_COACHING',
  GeneralQuestionsNotScreened = 'GENERAL_QUESTIONS_NOT_SCREENED',
  Interested = 'INTERESTED',
  NeedTimeToConsider = 'NEED_TIME_TO_CONSIDER',
  NoShow = 'NO_SHOW',
  Other = 'OTHER',
  TooExpensive = 'TOO_EXPENSIVE',
}

export enum CoachingAppointmentDuration {
  Long = 'Long',
  Short = 'Short',
}

export type Community = {
  __typename?: 'Community';
  active?: Maybe<Scalars['Boolean']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageName?: Maybe<Scalars['String']['output']>;
  invitationOnly?: Maybe<Scalars['Boolean']['output']>;
  isAutoJoinEnabled?: Maybe<Scalars['Boolean']['output']>;
  memberOnly?: Maybe<Scalars['Boolean']['output']>;
  primaryMessageThread?: Maybe<MessageThread>;
  /** @deprecated Use invitationOnly instead */
  public?: Maybe<Scalars['Boolean']['output']>;
  tags: Array<CommunityTag>;
  title: Scalars['String']['output'];
};

export type CommunityInfo = {
  __typename?: 'CommunityInfo';
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  messageThreadId?: Maybe<Scalars['String']['output']>;
  numberOfUnreadMessages?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CommunityInfoWithMessageThread = {
  __typename?: 'CommunityInfoWithMessageThread';
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  invitationOnly?: Maybe<Scalars['Boolean']['output']>;
  memberOnly?: Maybe<Scalars['Boolean']['output']>;
  messageThread?: Maybe<MessageThread>;
  messageThreadId?: Maybe<Scalars['String']['output']>;
  numberOfUnreadMessages?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CommunityInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  imageName?: InputMaybe<Scalars['String']['input']>;
  invitationOnly?: InputMaybe<Scalars['Boolean']['input']>;
  isAutoJoinEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  memberOnly?: InputMaybe<Scalars['Boolean']['input']>;
  tags: Array<CommunityTag>;
  title: Scalars['String']['input'];
};

export type CommunityPaginationArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CommunitySearchQueryArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum CommunityTag {
  AgeAbove_45 = 'AGE_ABOVE_45',
  AgeBelow_25 = 'AGE_BELOW_25',
  AgeBelow_30 = 'AGE_BELOW_30',
  EmblaEmbrace = 'EMBLA_EMBRACE',
  EmblaMen = 'EMBLA_MEN',
  EmblaWomen = 'EMBLA_WOMEN',
  Medication = 'MEDICATION',
}

export type ComorbidityAnswersInput = {
  cardiovascular?: InputMaybe<Scalars['Boolean']['input']>;
  highcolesterol?: InputMaybe<Scalars['Boolean']['input']>;
  hypertension?: InputMaybe<Scalars['Boolean']['input']>;
  prediabetes?: InputMaybe<Scalars['Boolean']['input']>;
  sleepapnea?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ConfigurationObjects = {
  __typename?: 'ConfigurationObjects';
  tags?: Maybe<Array<Maybe<LocalizedTagConfiguration>>>;
};

export type ConfigurationObjectsTagsArgs = {
  localeISO6391?: InputMaybe<LocaleIso6391>;
};

export type ConfigurationSyncData = {
  __typename?: 'ConfigurationSyncData';
  feed: Array<Maybe<FeedItemConfiguration>>;
  feedback: Array<Maybe<FeedbackConfiguration>>;
  greeting: Array<Maybe<Greeting>>;
  tag: Array<Maybe<TagConfiguration>>;
};

export type ConfigurationSyncLogEntry = {
  __typename?: 'ConfigurationSyncLogEntry';
  id?: Maybe<Scalars['ID']['output']>;
  syncedAt: Scalars['Date']['output'];
  syncedBy?: Maybe<User>;
  type?: Maybe<ConfigurationType>;
};

export enum ConfigurationType {
  Feed = 'feed',
  Feedback = 'feedback',
  Greeting = 'greeting',
  Tag = 'tag',
}

export type Country = {
  __typename?: 'Country';
  businessName?: Maybe<Scalars['String']['output']>;
  communityTermsVersion: Scalars['Int']['output'];
  countryISO3166: CountryIso3166;
  emblaShareUrl?: Maybe<Scalars['String']['output']>;
  features?: Maybe<CountryFeatures>;
  logbookPath: Scalars['String']['output'];
  logbookVersion: Scalars['Int']['output'];
  privacyUrl: Scalars['String']['output'];
  privacyVersion: Scalars['Int']['output'];
  termsUrl: Scalars['String']['output'];
  termsVersion: Scalars['Int']['output'];
  userIdentificationType: UserIdentificationType;
};

export type CountryFeatures = {
  __typename?: 'CountryFeatures';
  myCareTeam?: Maybe<MyCareTeamFeatures>;
};

export enum CountryIso3166 {
  Dk = 'DK',
  Gb = 'GB',
}

export type Coupon = {
  __typename?: 'Coupon';
  code: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  discount?: Maybe<CouponDiscount>;
  id: Scalars['ID']['output'];
  maxRedeemCount?: Maybe<Scalars['Int']['output']>;
  owner?: Maybe<User>;
  redemptions?: Maybe<Array<Maybe<CouponRedemption>>>;
};

export type CouponDiscount = {
  __typename?: 'CouponDiscount';
  amount?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  durationInMonths?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type CouponRedemption = {
  __typename?: 'CouponRedemption';
  coupon?: Maybe<Coupon>;
  couponId: Scalars['ID']['output'];
  redeemedBy?: Maybe<User>;
};

export enum CouponType {
  EmblaShare = 'EMBLA_SHARE',
}

export type CreateMeasurementEventInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  durationMins?: InputMaybe<Scalars['Int']['input']>;
  foodEvent?: InputMaybe<FoodEventInput>;
  subType?: InputMaybe<Scalars['String']['input']>;
  timestamp: Scalars['Date']['input'];
  type: MeasurementType;
  value: Scalars['String']['input'];
};

export type CreateReminderPayload = {
  clientId: Scalars['ID']['input'];
  hour: Scalars['Int']['input'];
  isoDays: Array<InputMaybe<Scalars['Int']['input']>>;
  minute: Scalars['Int']['input'];
};

export type CreateSalesLeadInput = {
  clientId: Scalars['ID']['input'];
  followup?: InputMaybe<Scalars['Date']['input']>;
  responsibleEmployeeId: Scalars['ID']['input'];
};

export enum CurrentMedication {
  Antipsychotic = 'ANTIPSYCHOTIC',
  Insulin = 'INSULIN',
  None = 'NONE',
  Orlistat = 'ORLISTAT',
  Sulfonylureas = 'SULFONYLUREAS',
}

export type CurrentMedicationAnswersInput = {
  ANTIPSYCHOTIC?: InputMaybe<Scalars['Boolean']['input']>;
  INSULIN?: InputMaybe<Scalars['Boolean']['input']>;
  NONE?: InputMaybe<Scalars['Boolean']['input']>;
  ORLISTAT?: InputMaybe<Scalars['Boolean']['input']>;
  SULFONYLUREAS?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomerPortalSession = {
  __typename?: 'CustomerPortalSession';
  url?: Maybe<Scalars['String']['output']>;
};

export type DashboardInfo = {
  __typename?: 'DashboardInfo';
  coachActiveClients?: Maybe<Array<Maybe<ClientActivity>>>;
  coachInactiveClients?: Maybe<Array<Maybe<ClientActivity>>>;
  nAppointmentsTotal?: Maybe<Scalars['Int']['output']>;
  nClientsTotal?: Maybe<Scalars['Int']['output']>;
  nLostKgsTotal?: Maybe<Scalars['Int']['output']>;
  nMeasurements24HTotal?: Maybe<Scalars['Int']['output']>;
  nMeasurementsTotal?: Maybe<Scalars['Int']['output']>;
  nUsersTotal?: Maybe<Scalars['Int']['output']>;
  todaysSchedule?: Maybe<Array<Maybe<TodaysSchedule>>>;
};

export type DateRange = {
  end: Scalars['Date']['input'];
  start: Scalars['Date']['input'];
};

export type Discount = {
  __typename?: 'Discount';
  total?: Maybe<Scalars['Float']['output']>;
};

export type DiscountCode = Coupon | PromoCode;

export type EligibilityAnswersInput = {
  activePancreatitisOrGallstones?: InputMaybe<Scalars['Boolean']['input']>;
  alcohol_drugs?: InputMaybe<Scalars['Boolean']['input']>;
  cancer?: InputMaybe<Scalars['Boolean']['input']>;
  diabetesType1?: InputMaybe<Scalars['Boolean']['input']>;
  diabetesType2?: InputMaybe<Scalars['Boolean']['input']>;
  eatingdisorder?: InputMaybe<Scalars['Boolean']['input']>;
  heartFailure?: InputMaybe<Scalars['Boolean']['input']>;
  historyOfInflammatoryBowelDiseaseOrGastroparesis?: InputMaybe<Scalars['Boolean']['input']>;
  multipleEndocrineNeoplasiaType2?: InputMaybe<Scalars['Boolean']['input']>;
  pregnancy?: InputMaybe<Scalars['Boolean']['input']>;
  severeLiverOrKidneyDisease?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum EligibilityResult {
  CoachingAndMaybeMedicine = 'CoachingAndMaybeMedicine',
  CoachingOnly = 'CoachingOnly',
  MedicineAndCoaching = 'MedicineAndCoaching',
  NotEligible = 'NotEligible',
}

export enum EligibilityType {
  Coaching = 'COACHING',
  CoachingAndMedication = 'COACHING_AND_MEDICATION',
}

export enum EmblaBotType {
  GrammarCheck = 'GrammarCheck',
  NurseGuideReply = 'NurseGuideReply',
  WeeklyFeedback = 'WeeklyFeedback',
}

export type EmblaShareDetails = {
  __typename?: 'EmblaShareDetails';
  referralDiscount?: Maybe<CouponDiscount>;
  url?: Maybe<Scalars['String']['output']>;
};

export type EmblaShareSavings = {
  __typename?: 'EmblaShareSavings';
  amount?: Maybe<CouponDiscount>;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type EmblaShareUserSeniority = {
  __typename?: 'EmblaShareUserSeniority';
  level?: Maybe<Scalars['Int']['output']>;
  maxLevel?: Maybe<Scalars['Int']['output']>;
  savings?: Maybe<EmblaShareSavings>;
};

export type Employee = {
  __typename?: 'Employee';
  deactivated: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  user: User;
};

export type EmployeeDescription = {
  __typename?: 'EmployeeDescription';
  description: Scalars['String']['output'];
  employeeUserId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  locale: LocaleIso6391;
};

export type EmployeeDescriptionInput = {
  description: Scalars['String']['input'];
  employeeUserId: Scalars['ID']['input'];
  locale: LocaleIso6391;
};

export type EmployeeInfo = {
  __typename?: 'EmployeeInfo';
  about?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profilePictureId?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
};

export type ErrorMessage = {
  __typename?: 'ErrorMessage';
  message?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type EventLogEntry = {
  __typename?: 'EventLogEntry';
  authenticatedUser?: Maybe<User>;
  data: Scalars['JSON']['output'];
  eventTime: Scalars['Date']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  targetId?: Maybe<Scalars['ID']['output']>;
  type: EventLogType;
  userId: Scalars['ID']['output'];
};

export type EventLogEntryInput = {
  data: Scalars['JSON']['input'];
  eventTime?: InputMaybe<Scalars['Date']['input']>;
  targetId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EventLogType>;
  userId: Scalars['ID']['input'];
};

export enum EventLogType {
  Churned = 'churned',
  /** @deprecated use medication_stopped_by_subscription_change */
  JournalPrescriptionEntryPending = 'journal_prescription_entry_pending',
  JournalPrescriptionEntryReady = 'journal_prescription_entry_ready',
  MedicationCompleted = 'medication_completed',
  MedicationDeescalationStarted = 'medication_deescalation_started',
  MedicationStarted = 'medication_started',
  MedicationStoppedBySubscriptionChange = 'medication_stopped_by_subscription_change',
}

export enum ExerciseType {
  Crossfit = 'CROSSFIT',
  CyklingKm = 'CYKLING_KM',
  Dancing = 'DANCING',
  Gardening = 'GARDENING',
  Golf = 'GOLF',
  Hiking = 'HIKING',
  Other = 'OTHER',
  /** @deprecated use OTHER */
  OtherCardio = 'OTHER_CARDIO',
  /** @deprecated use OTHER */
  OtherStrength = 'OTHER_STRENGTH',
  Padel = 'PADEL',
  Pilates = 'PILATES',
  PowerWalkingKm = 'POWER_WALKING_KM',
  Rowing = 'ROWING',
  RunningKm = 'RUNNING_KM',
  SwimmingKm = 'SWIMMING_KM',
  WalkingKm = 'WALKING_KM',
  Weightlifting = 'WEIGHTLIFTING',
  Yoga = 'YOGA',
}

export type ExternalMeasurement = {
  __typename?: 'ExternalMeasurement';
  durationMins?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  subType?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['Date']['output'];
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  usersId?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type ExternalProfile = {
  __typename?: 'ExternalProfile';
  alias?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  profilePictureImageUrl?: Maybe<Scalars['String']['output']>;
};

export type FeedItem = {
  __typename?: 'FeedItem';
  actionPrimary?: Maybe<FeedItemAction>;
  actionSecondary?: Maybe<FeedItemAction>;
  description?: Maybe<Scalars['String']['output']>;
  details?: Maybe<FeedItemDetails>;
  error?: Maybe<FeedItemError>;
  header?: Maybe<Scalars['String']['output']>;
  hideActions?: Maybe<Scalars['Boolean']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
  isActionableAfterCompletion?: Maybe<Scalars['Boolean']['output']>;
  isCompleted?: Maybe<Scalars['Boolean']['output']>;
  isInfoOnly?: Maybe<Scalars['Boolean']['output']>;
  isLocked?: Maybe<Scalars['Boolean']['output']>;
  metaData?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Date']['output']>;
  type: FeedItemType;
};

export type FeedItemAction = {
  __typename?: 'FeedItemAction';
  completeAction?: Maybe<Scalars['Boolean']['output']>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  metaData?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FeedItemCompleteInput = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  itemType: FeedItemType;
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type FeedItemConfiguration = {
  __typename?: 'FeedItemConfiguration';
  actionPrimary?: Maybe<Scalars['String']['output']>;
  actionSecondary?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  detailsAction?: Maybe<Scalars['String']['output']>;
  detailsDescription?: Maybe<Scalars['String']['output']>;
  detailsHeader?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  itemType?: Maybe<FeedItemType>;
  locale?: Maybe<LocaleIso6391>;
};

export type FeedItemDetails = {
  __typename?: 'FeedItemDetails';
  action?: Maybe<FeedItemAction>;
  description?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
};

export type FeedItemError = {
  __typename?: 'FeedItemError';
  message?: Maybe<Scalars['String']['output']>;
};

export type FeedItemEventInput = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  eventType: FeedItemEventType;
  feedItemType: FeedItemType;
};

export enum FeedItemEventType {
  DetailsAction = 'DETAILS_ACTION',
  PrimaryAction = 'PRIMARY_ACTION',
}

export type FeedItemLocalization = {
  __typename?: 'FeedItemLocalization';
  actionPrimary?: Maybe<Scalars['String']['output']>;
  actionSecondary?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  detailsAction?: Maybe<Scalars['String']['output']>;
  detailsDescription?: Maybe<Scalars['String']['output']>;
  detailsHeader?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
  itemType?: Maybe<FeedItemType>;
  locale?: Maybe<LocaleIso6391>;
};

export type FeedItemLocalizationInput = {
  actionPrimary?: InputMaybe<Scalars['String']['input']>;
  actionSecondary?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  detailsAction?: InputMaybe<Scalars['String']['input']>;
  detailsDescription?: InputMaybe<Scalars['String']['input']>;
  detailsHeader?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  itemType?: InputMaybe<FeedItemType>;
  locale?: InputMaybe<LocaleIso6391>;
};

export enum FeedItemType {
  AboutEmbla = 'ABOUT_EMBLA',
  ActivateSubscription = 'ACTIVATE_SUBSCRIPTION',
  Appointment = 'APPOINTMENT',
  AppointmentCoach = 'APPOINTMENT_COACH',
  AppointmentScreening = 'APPOINTMENT_SCREENING',
  BookAppointment = 'BOOK_APPOINTMENT',
  BookFirstCoachingAppointment = 'BOOK_FIRST_COACHING_APPOINTMENT',
  BookInvitation = 'BOOK_INVITATION',
  BookScreening = 'BOOK_SCREENING',
  ClaimReward = 'CLAIM_REWARD',
  CommunityJoin = 'COMMUNITY_JOIN',
  CompleteProfileAddress = 'COMPLETE_PROFILE_ADDRESS',
  CompleteProfileAlias = 'COMPLETE_PROFILE_ALIAS',
  CompleteProfileDateOfBirth = 'COMPLETE_PROFILE_DATE_OF_BIRTH',
  CompleteProfileEmail = 'COMPLETE_PROFILE_EMAIL',
  CompleteProfilePhone = 'COMPLETE_PROFILE_PHONE',
  CompleteProfilePicture = 'COMPLETE_PROFILE_PICTURE',
  CompleteProfileSex = 'COMPLETE_PROFILE_SEX',
  CoomunityNewActivity = 'COOMUNITY_NEW_ACTIVITY',
  EmblaShare = 'EMBLA_SHARE',
  FocusArea = 'FOCUS_AREA',
  GetCoach = 'GET_COACH',
  GetMedicalAssessment = 'GET_MEDICAL_ASSESSMENT',
  GetPrescription = 'GET_PRESCRIPTION',
  HowToUseTheApp = 'HOW_TO_USE_THE_APP',
  KnowYourTreatmentTeam = 'KNOW_YOUR_TREATMENT_TEAM',
  LearningArticles = 'LEARNING_ARTICLES',
  LogExercise = 'LOG_EXERCISE',
  LogFood = 'LOG_FOOD',
  LogLiquid = 'LOG_LIQUID',
  LogMedication = 'LOG_MEDICATION',
  LogSleep = 'LOG_SLEEP',
  LogStatus = 'LOG_STATUS',
  LogWeight = 'LOG_WEIGHT',
  Message = 'MESSAGE',
  MonthlyOverview = 'MONTHLY_OVERVIEW',
  OnboardingArticles = 'ONBOARDING_ARTICLES',
  OnboardingCompleted = 'ONBOARDING_COMPLETED',
  ProvidePaymentInformation = 'PROVIDE_PAYMENT_INFORMATION',
  ReminderIntro = 'REMINDER_INTRO',
  SelectProgram = 'SELECT_PROGRAM',
  SocialIntro = 'SOCIAL_INTRO',
  SubscriptionCancelled = 'SUBSCRIPTION_CANCELLED',
  SubscriptionPastDue = 'SUBSCRIPTION_PAST_DUE',
  SubscriptionPaused = 'SUBSCRIPTION_PAUSED',
  SubscriptionWillCancel = 'SUBSCRIPTION_WILL_CANCEL',
  TrustpilotReview = 'TRUSTPILOT_REVIEW',
  UpdateProfile = 'UPDATE_PROFILE',
  UpdateSubscription = 'UPDATE_SUBSCRIPTION',
  ViewRecipe = 'VIEW_RECIPE',
  ViewTreatmentTeam = 'VIEW_TREATMENT_TEAM',
}

export type FeedbackConfiguration = {
  __typename?: 'FeedbackConfiguration';
  active: Scalars['Boolean']['output'];
  anonymous: Scalars['Boolean']['output'];
  country: CountryIso3166;
  existingFeedbackTargetIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  followUpAnswer?: Maybe<Scalars['Boolean']['output']>;
  followUpRatingMax?: Maybe<Scalars['Int']['output']>;
  followUpSelection?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  followUpWithComment: Scalars['Boolean']['output'];
  followUpWithContactable: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  localizations: Array<Maybe<FeedbackConfigurationLocalization>>;
  multipleChoice?: Maybe<Scalars['Boolean']['output']>;
  numberOfFeedbacks?: Maybe<Scalars['Int']['output']>;
  ratingMax?: Maybe<Scalars['Int']['output']>;
  ratingMin?: Maybe<Scalars['Int']['output']>;
  targetType?: Maybe<FeedbackTarget>;
  type?: Maybe<FeedbackType>;
};

export type FeedbackConfigurationInput = {
  active: Scalars['Boolean']['input'];
  anonymous: Scalars['Boolean']['input'];
  country: CountryIso3166;
  followUpAnswer?: InputMaybe<Scalars['Boolean']['input']>;
  followUpRatingMax?: InputMaybe<Scalars['Int']['input']>;
  followUpSelection?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  followUpWithComment: Scalars['Boolean']['input'];
  followUpWithContactable: Scalars['Boolean']['input'];
  key: Scalars['String']['input'];
  multipleChoice?: InputMaybe<Scalars['Boolean']['input']>;
  ratingMax?: InputMaybe<Scalars['Int']['input']>;
  ratingMin?: InputMaybe<Scalars['Int']['input']>;
  targetType: FeedbackTarget;
  type: FeedbackType;
};

export type FeedbackConfigurationLocalization = {
  __typename?: 'FeedbackConfigurationLocalization';
  choices?: Maybe<Scalars['String']['output']>;
  commentPrompt?: Maybe<Scalars['String']['output']>;
  falseAnswer?: Maybe<Scalars['String']['output']>;
  locale: LocaleIso6391;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  trueAnswer?: Maybe<Scalars['String']['output']>;
};

export type FeedbackConfigurationLocalizationInput = {
  choices?: InputMaybe<Scalars['String']['input']>;
  commentPrompt?: InputMaybe<Scalars['String']['input']>;
  falseAnswer?: InputMaybe<Scalars['String']['input']>;
  locale: LocaleIso6391;
  subtitle: Scalars['String']['input'];
  title: Scalars['String']['input'];
  trueAnswer?: InputMaybe<Scalars['String']['input']>;
};

export enum FeedbackTarget {
  App = 'APP',
  Appointment = 'APPOINTMENT',
  Article = 'ARTICLE',
  ExpertTip = 'EXPERT_TIP',
  LearningTopic = 'LEARNING_TOPIC',
  Recipe = 'RECIPE',
}

export enum FeedbackType {
  Answer = 'ANSWER',
  Comment = 'COMMENT',
  Rating = 'RATING',
  Selection = 'SELECTION',
}

export enum FeelingAboutWeightLoss {
  Confident = 'CONFIDENT',
  Frustrated = 'FRUSTRATED',
  Hopeful = 'HOPEFUL',
  NotSure = 'NOT_SURE',
  Optimistic = 'OPTIMISTIC',
  Worried = 'WORRIED',
}

export type FinancialPrice = {
  __typename?: 'FinancialPrice';
  active?: Maybe<Scalars['Boolean']['output']>;
  amount?: Maybe<Scalars['Int']['output']>;
  bmi?: Maybe<Scalars['String']['output']>;
  campaignAmount?: Maybe<Scalars['Int']['output']>;
  campaignDiscount?: Maybe<CouponDiscount>;
  campaignDiscountText?: Maybe<Scalars['String']['output']>;
  campaignText?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paymentIntervalCode?: Maybe<Scalars['String']['output']>;
  paymentIntervalCount?: Maybe<Scalars['String']['output']>;
  product?: Maybe<FinancialProduct>;
  terms?: Maybe<Scalars['String']['output']>;
};

export type FinancialProduct = {
  __typename?: 'FinancialProduct';
  available?: Maybe<Scalars['Boolean']['output']>;
  defaultPriceId?: Maybe<Scalars['String']['output']>;
  eligibilityType?: Maybe<EligibilityType>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  requirements?: Maybe<ProductRequirements>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Product>;
};

export type FinancialSubscriptionPhase = {
  __typename?: 'FinancialSubscriptionPhase';
  end?: Maybe<Scalars['Date']['output']>;
  items?: Maybe<Array<Maybe<FinancialPrice>>>;
  start?: Maybe<Scalars['Date']['output']>;
};

export type FinancialSubscriptions = {
  __typename?: 'FinancialSubscriptions';
  amount?: Maybe<Scalars['Int']['output']>;
  cancelAt?: Maybe<Scalars['Date']['output']>;
  coupon?: Maybe<CouponDiscount>;
  currency?: Maybe<Scalars['String']['output']>;
  customerBalance?: Maybe<Scalars['Int']['output']>;
  emblaShareRestricted?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  nextInvoice?: Maybe<Invoice>;
  paymentIntervalCode?: Maybe<Scalars['String']['output']>;
  paymentIntervalCount?: Maybe<Scalars['Int']['output']>;
  paymentStatus?: Maybe<UserStartSubscriptionResponse>;
  periodEndAt?: Maybe<Scalars['Date']['output']>;
  periodeStartAt?: Maybe<Scalars['Date']['output']>;
  phases?: Maybe<Array<Maybe<FinancialSubscriptionPhase>>>;
  priceId?: Maybe<Scalars['String']['output']>;
  priceType?: Maybe<PriceType>;
  productId?: Maybe<Scalars['String']['output']>;
  productKey?: Maybe<Product>;
  productName?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type FoodEvent = {
  __typename?: 'FoodEvent';
  breakfast?: Maybe<Scalars['String']['output']>;
  breakfastPostSnack?: Maybe<Scalars['String']['output']>;
  completed?: Maybe<Scalars['Boolean']['output']>;
  dinner?: Maybe<Scalars['String']['output']>;
  dinnerPostSnack?: Maybe<Scalars['String']['output']>;
  lunch?: Maybe<Scalars['String']['output']>;
  lunchPostSnack?: Maybe<Scalars['String']['output']>;
  satietyLevel?: Maybe<Scalars['Int']['output']>;
};

export type FoodEventInput = {
  breakfast?: InputMaybe<Scalars['String']['input']>;
  breakfastPostSnack?: InputMaybe<Scalars['String']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  dinner?: InputMaybe<Scalars['String']['input']>;
  dinnerPostSnack?: InputMaybe<Scalars['String']['input']>;
  lunch?: InputMaybe<Scalars['String']['input']>;
  lunchPostSnack?: InputMaybe<Scalars['String']['input']>;
  satietyLevel?: InputMaybe<Scalars['Int']['input']>;
};

export type GameChart = {
  __typename?: 'GameChart';
  datasets?: Maybe<Array<Maybe<GameChartDataset>>>;
  labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  legend?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  xAxisLabel?: Maybe<Scalars['String']['output']>;
  yAxisLabel?: Maybe<Scalars['String']['output']>;
};

export type GameChartDataset = {
  __typename?: 'GameChartDataset';
  data?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  label?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type GameClientInfo = {
  __typename?: 'GameClientInfo';
  alias?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  profileAvatarConfiguration?: Maybe<Scalars['JSON']['output']>;
  profileLink?: Maybe<Scalars['String']['output']>;
  profilePictureImageId?: Maybe<Scalars['String']['output']>;
};

export type GamificationConfiguration = {
  __typename?: 'GamificationConfiguration';
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  visibilitySettings: GamificationMeasurementVisibilityConfigurations;
};

export type GamificationConfigurationInput = {
  enabled: Scalars['Boolean']['input'];
  visibilitySettings: GamificationMeasurementVisibilityConfigurationsInput;
};

export type GamificationMeasurementVisibilityConfiguration = {
  __typename?: 'GamificationMeasurementVisibilityConfiguration';
  enabled: Scalars['Boolean']['output'];
  shareDuration: Scalars['Boolean']['output'];
  shareValue: Scalars['Boolean']['output'];
};

export type GamificationMeasurementVisibilityConfigurationInput = {
  enabled: Scalars['Boolean']['input'];
  shareDuration: Scalars['Boolean']['input'];
  shareValue: Scalars['Boolean']['input'];
};

export type GamificationMeasurementVisibilityConfigurations = {
  __typename?: 'GamificationMeasurementVisibilityConfigurations';
  bodyMeasurements: GamificationMeasurementVisibilityConfiguration;
  exercise: GamificationMeasurementVisibilityConfiguration;
  foodAndLiquid: GamificationMeasurementVisibilityConfiguration;
  sleep: GamificationMeasurementVisibilityConfiguration;
};

export type GamificationMeasurementVisibilityConfigurationsInput = {
  bodyMeasurements: GamificationMeasurementVisibilityConfigurationInput;
  exercise: GamificationMeasurementVisibilityConfigurationInput;
  foodAndLiquid: GamificationMeasurementVisibilityConfigurationInput;
  sleep: GamificationMeasurementVisibilityConfigurationInput;
};

export type Graph = {
  __typename?: 'Graph';
  barColors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  changeTotal?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  latestValue?: Maybe<Scalars['String']['output']>;
  legend?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Greeting = {
  __typename?: 'Greeting';
  category?: Maybe<GreetingCategory>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  localizations?: Maybe<Array<Maybe<GreetingLocalization>>>;
  requiresData?: Maybe<Scalars['Boolean']['output']>;
};

export enum GreetingCategory {
  Activity = 'ACTIVITY',
  Community = 'COMMUNITY',
  FoodAndDiet = 'FOOD_AND_DIET',
  Lifestyle = 'LIFESTYLE',
  SleepAndRest = 'SLEEP_AND_REST',
}

export type GreetingInput = {
  category: GreetingCategory;
  id?: InputMaybe<Scalars['ID']['input']>;
  localizations?: InputMaybe<Array<GreetingLocalizationInput>>;
  requiresData?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GreetingLocalization = {
  __typename?: 'GreetingLocalization';
  locale?: Maybe<LocaleIso6391>;
  value?: Maybe<Scalars['String']['output']>;
};

export type GreetingLocalizationInput = {
  locale: LocaleIso6391;
  value: Scalars['String']['input'];
};

export enum HeardAboutEmbla {
  Emails = 'EMAILS',
  Facebook = 'FACEBOOK',
  FriendReferal = 'FRIEND_REFERAL',
  Google = 'GOOGLE',
  GoMorgenDanmark = 'GO_MORGEN_DANMARK',
  Instagram = 'INSTAGRAM',
  NewsArticle = 'NEWS_ARTICLE',
  NotSure = 'NOT_SURE',
  TvAdvert = 'TV_ADVERT',
  Youtube = 'YOUTUBE',
}

export type HeightWeightInput = {
  height?: InputMaybe<Scalars['Float']['input']>;
  unitSystem?: InputMaybe<UnitSystem>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export enum InvitationStatus {
  Active = 'ACTIVE',
  Consumed = 'CONSUMED',
  Deleted = 'DELETED',
}

export enum InvitationType {
  NurseAppointment = 'NURSE_APPOINTMENT',
  NurseFollowUp = 'NURSE_FOLLOW_UP',
  Other = 'OTHER',
}

export type Invoice = {
  __typename?: 'Invoice';
  currency?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Discount>;
  nextPaymentAttempt?: Maybe<Scalars['String']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type LeadScoring = {
  __typename?: 'LeadScoring';
  confirmedLeadScoringQuestions: Array<Maybe<LeadScoringQuestion>>;
  score: Scalars['Float']['output'];
  version: LeadScoringVersion;
};

export enum LeadScoringQuestion {
  AttendsScreeningWithSuitableExpectations = 'ATTENDS_SCREENING_WITH_SUITABLE_EXPECTATIONS',
  HasReadUpOnUs = 'HAS_READ_UP_ON_US',
  InterestedInHealthCoachingForHealthOverAesthetic = 'INTERESTED_IN_HEALTH_COACHING_FOR_HEALTH_OVER_AESTHETIC',
  InterestedInSustainableWeightLoss = 'INTERESTED_IN_SUSTAINABLE_WEIGHT_LOSS',
  InterestedInUsingApp = 'INTERESTED_IN_USING_APP',
  KeenOnMedicationWithCoaching = 'KEEN_ON_MEDICATION_WITH_COACHING',
  NotDisuadedByMembershipLength = 'NOT_DISUADED_BY_MEMBERSHIP_LENGTH',
  PriceIsAffordable = 'PRICE_IS_AFFORDABLE',
  ResponsiveToEducation = 'RESPONSIVE_TO_EDUCATION',
  UnderstandsValueOfLifestyleSupport = 'UNDERSTANDS_VALUE_OF_LIFESTYLE_SUPPORT',
  WillingAndAbleToInvest = 'WILLING_AND_ABLE_TO_INVEST',
}

export enum LeadScoringVersion {
  V0 = 'V0',
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
}

export type LeadershipBoard = {
  __typename?: 'LeadershipBoard';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  leagueName?: Maybe<Scalars['String']['output']>;
  positions?: Maybe<Array<Maybe<LeadershipBoardPosition>>>;
};

export type LeadershipBoardPosition = {
  __typename?: 'LeadershipBoardPosition';
  externalProfile?: Maybe<ExternalProfile>;
  position?: Maybe<Scalars['Int']['output']>;
  positionChange?: Maybe<Scalars['Int']['output']>;
  xp?: Maybe<Scalars['Int']['output']>;
  xpChange?: Maybe<Scalars['Int']['output']>;
};

export enum LeadershipboardType {
  Friends = 'FRIENDS',
  Global = 'GLOBAL',
}

export type LearningArticle = {
  __typename?: 'LearningArticle';
  accessibility?: Maybe<Scalars['String']['output']>;
  canRead?: Maybe<Scalars['Boolean']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isRead?: Maybe<Scalars['Boolean']['output']>;
  metaData?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nLikes?: Maybe<Scalars['Int']['output']>;
  programWeek?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['String']['output']>;
  readingTime?: Maybe<Scalars['String']['output']>;
  userHasFavorit?: Maybe<Scalars['Boolean']['output']>;
  userHasLiked?: Maybe<Scalars['Boolean']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type LearningAssignment = {
  __typename?: 'LearningAssignment';
  assignedAt: Scalars['Date']['output'];
  assignedBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  sanityId: Scalars['ID']['output'];
  sanityType: Scalars['String']['output'];
};

export type LearningBookmark = {
  __typename?: 'LearningBookmark';
  bookmarkedAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  sanityId: Scalars['ID']['output'];
  sanityType: Scalars['String']['output'];
};

export type LearningCompletion = {
  __typename?: 'LearningCompletion';
  completedAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  sanityId: Scalars['ID']['output'];
  sanityType: Scalars['String']['output'];
};

export enum LocaleIso6391 {
  Da = 'da',
  En = 'en',
}

export type LocalizedTagConfiguration = {
  __typename?: 'LocalizedTagConfiguration';
  color?: Maybe<TagColor>;
  localization: TagConfigurationLocalization;
  manuallyAssigned?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  system?: Maybe<Scalars['Boolean']['output']>;
  tag: Scalars['String']['output'];
};

export enum MailSubscriptionType {
  Marketing = 'MARKETING',
}

export type MarketingLead = {
  __typename?: 'MarketingLead';
  client: Client;
  clientId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  status: MarketingLeadStatus;
  tags: Array<MarketingLeadTag>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  utmHeaders?: Maybe<Scalars['JSON']['output']>;
};

export enum MarketingLeadOrderBy {
  CreatedAt = 'createdAt',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

export type MarketingLeadPaginationArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MarketingLeadOrderBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type MarketingLeadQueryArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<MarketingLeadStatus>;
  tags?: InputMaybe<Array<InputMaybe<MarketingLeadTag>>>;
};

export enum MarketingLeadStatus {
  LostLead = 'LOST_LEAD',
  NewLead = 'NEW_LEAD',
  NotEligible = 'NOT_ELIGIBLE',
  PayingCustomer = 'PAYING_CUSTOMER',
  SalesLead = 'SALES_LEAD',
}

export enum MarketingLeadTag {
  MqlFollowedUpWithCall = 'MQL_FOLLOWED_UP_WITH_CALL',
  SubscribedViaEcom = 'SUBSCRIBED_VIA_ECOM',
  Uk_2023MedicationShortageWatchlist = 'UK_2023_MEDICATION_SHORTAGE_WATCHLIST',
  WaitingForHigherMedicationDose = 'WAITING_FOR_HIGHER_MEDICATION_DOSE',
  WaitingForScreeningCapacity = 'WAITING_FOR_SCREENING_CAPACITY',
}

export type Measurement = {
  __typename?: 'Measurement';
  comment?: Maybe<Scalars['String']['output']>;
  durationMins?: Maybe<Scalars['String']['output']>;
  foodEvent?: Maybe<FoodEvent>;
  id?: Maybe<Scalars['ID']['output']>;
  images?: Maybe<Array<Maybe<MeasurementImage>>>;
  subType?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['Date']['output'];
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  user?: Maybe<User>;
  usersId?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type MeasurementImage = {
  __typename?: 'MeasurementImage';
  LLMCategory?: Maybe<Scalars['String']['output']>;
  LLMDescription?: Maybe<Scalars['String']['output']>;
  LLMHealthScore?: Maybe<Scalars['Int']['output']>;
  LLMNutritioncore?: Maybe<Scalars['Int']['output']>;
  LLMSatietyScore?: Maybe<Scalars['Int']['output']>;
  LLMVolumenGrams?: Maybe<Scalars['Int']['output']>;
  measurement?: Maybe<Measurement>;
  measurementEventId?: Maybe<Scalars['ID']['output']>;
  measurementId?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type MeasurementInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  durationMins?: InputMaybe<Scalars['String']['input']>;
  foodEvent?: InputMaybe<FoodEventInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  subType?: InputMaybe<Scalars['String']['input']>;
  timestamp: Scalars['Date']['input'];
  type: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
  value: Scalars['String']['input'];
};

export type MeasurementLiquidSuggestion = {
  __typename?: 'MeasurementLiquidSuggestion';
  notes?: Maybe<Scalars['String']['output']>;
  subType?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type MeasurementNudgeSetting = {
  __typename?: 'MeasurementNudgeSetting';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  measurementType?: Maybe<MeasurementType>;
};

export enum MeasurementType {
  BodyMeasurement = 'BODY_MEASUREMENT',
  Exercise = 'EXERCISE',
  Food = 'FOOD',
  HeightCm = 'HEIGHT_CM',
  LiquidMl = 'LIQUID_ML',
  Medication = 'MEDICATION',
  SleepHours = 'SLEEP_HOURS',
  Status = 'STATUS',
  Steps = 'STEPS',
  Symptomes = 'SYMPTOMES',
  WeightKg = 'WEIGHT_KG',
}

export enum MedicationArticle {
  Mounjaro = 'MOUNJARO',
  Mysimba = 'MYSIMBA',
  Ozempic = 'OZEMPIC',
  Rybelsus = 'RYBELSUS',
  Saxenda = 'SAXENDA',
  Victoza = 'VICTOZA',
  Wegovy = 'WEGOVY',
}

export enum MedicationWantedAnswers {
  No = 'NO',
  NotSure = 'NOT_SURE',
  Yes = 'YES',
}

export type MemberFocus = {
  __typename?: 'MemberFocus';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type Message = {
  __typename?: 'Message';
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  reactions?: Maybe<Array<Maybe<MessageReactionByEmoji>>>;
  readByPatient?: Maybe<Scalars['Boolean']['output']>;
  readByStaff?: Maybe<Scalars['Boolean']['output']>;
  threadId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  userImageId?: Maybe<Scalars['String']['output']>;
  userImageUrl?: Maybe<Scalars['String']['output']>;
};

export enum MessageAuthorDisplayType {
  Alias = 'ALIAS',
  Name = 'NAME',
  Unknown = 'UNKNOWN',
}

export type MessageReactionByEmoji = {
  __typename?: 'MessageReactionByEmoji';
  count?: Maybe<Scalars['Int']['output']>;
  emoji?: Maybe<Scalars['String']['output']>;
  isAlsoUserReaction?: Maybe<Scalars['Boolean']['output']>;
};

export type MessageThread = {
  __typename?: 'MessageThread';
  authorDisplayType?: Maybe<MessageAuthorDisplayType>;
  catchAllStatus?: Maybe<CatchAllStatus>;
  clientUser?: Maybe<User>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isCoachTeam?: Maybe<Scalars['Boolean']['output']>;
  isRead?: Maybe<Scalars['Boolean']['output']>;
  lastMessage?: Maybe<Message>;
  lastMessageCreatedAt?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<Maybe<Message>>>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfParticipants?: Maybe<Scalars['Int']['output']>;
  participants?: Maybe<Array<Maybe<User>>>;
  pushNotificationsMuted?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unreadMessageCount?: Maybe<Scalars['Int']['output']>;
};

export type MessageThreadParticipant = {
  __typename?: 'MessageThreadParticipant';
  authorDisplayType: MessageAuthorDisplayType;
  pushNotificationsMuted?: Maybe<Scalars['Boolean']['output']>;
  unreadMessageCount: Scalars['Int']['output'];
  user?: Maybe<Array<Maybe<User>>>;
  userId: Scalars['ID']['output'];
};

export enum MessageThreadType {
  CareTeam = 'CARE_TEAM',
  Community = 'COMMUNITY',
  Misc = 'MISC',
  Task = 'TASK',
}

export type MinutesOfActivity = {
  __typename?: 'MinutesOfActivity';
  durationInMinutes?: Maybe<Scalars['Int']['output']>;
  exerciseType?: Maybe<ExerciseType>;
};

export type MonthlyOverviewData = {
  __typename?: 'MonthlyOverviewData';
  articlesRead?: Maybe<Scalars['Int']['output']>;
  averageStepsPerDay?: Maybe<Scalars['Int']['output']>;
  averageStepsPerDayChangeSinceLastMonth?: Maybe<Scalars['Int']['output']>;
  consultationsAttended?: Maybe<Scalars['Int']['output']>;
  currentWeightKg?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['Date']['output']>;
  minutesOfActivity?: Maybe<Array<Maybe<MinutesOfActivity>>>;
  minutesOfActivityTotal?: Maybe<Scalars['Int']['output']>;
  numberOfMessagesSentInCommunities?: Maybe<Scalars['Int']['output']>;
  numberOfRewardsClaimed?: Maybe<Scalars['Int']['output']>;
  weightKgLost?: Maybe<Scalars['Float']['output']>;
};

export type MotivationAnswersInput = {
  FITNESS_AND_CONFIDENCE?: InputMaybe<Scalars['Boolean']['input']>;
  HEALTHIER_LIFESTYLE?: InputMaybe<Scalars['Boolean']['input']>;
  IMPROVE_MENTAL_HEALTH?: InputMaybe<Scalars['Boolean']['input']>;
  IMPROVE_PHYSICAL_HEALTH?: InputMaybe<Scalars['Boolean']['input']>;
  LOWER_RISK_OF_DISEASES?: InputMaybe<Scalars['Boolean']['input']>;
  MORE_ENERGY?: InputMaybe<Scalars['Boolean']['input']>;
  OTHER?: InputMaybe<Scalars['Boolean']['input']>;
  SUSTAINABLE_WEIGHT_LOSS?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  AcceptRelationship?: Maybe<Array<Maybe<Relationship>>>;
  ActivateOrDeactivateCommunity?: Maybe<Scalars['Boolean']['output']>;
  ActivateReminder?: Maybe<Reminder>;
  AddMeasurements?: Maybe<Scalars['Boolean']['output']>;
  AddTagsToMarketingLead: MarketingLead;
  AddUserVerification?: Maybe<UserVerification>;
  AddressCreateOrUpdate?: Maybe<Address>;
  AppointmentCancel?: Maybe<Scalars['Boolean']['output']>;
  AppointmentComplete?: Maybe<Appointment>;
  AppointmentCreate?: Maybe<Appointment>;
  AppointmentMove?: Maybe<Appointment>;
  AppointmentNoShow?: Maybe<Appointment>;
  AssignLearning?: Maybe<Array<Maybe<LearningAssignment>>>;
  AssignTag?: Maybe<Scalars['Boolean']['output']>;
  BookmarkLearning?: Maybe<Array<Maybe<LearningBookmark>>>;
  CancelScheduledSubscription?: Maybe<Scalars['Boolean']['output']>;
  ChristmasCalendarParticipate?: Maybe<Scalars['Boolean']['output']>;
  ClientMuteFeedItemType?: Maybe<Scalars['Boolean']['output']>;
  ClientReactionToFeedItem?: Maybe<Array<Maybe<ClientGameFriendFeedItem>>>;
  ClientSetMeasurementNudgeSetting?: Maybe<Scalars['Boolean']['output']>;
  ClientUnmuteFeedItemType?: Maybe<Scalars['Boolean']['output']>;
  CompleteFeedItem?: Maybe<Scalars['Boolean']['output']>;
  CompleteLearning?: Maybe<Array<Maybe<LearningCompletion>>>;
  CreateAbuseReport?: Maybe<AbuseReport>;
  CreateCalendar?: Maybe<Calendar>;
  CreateClientInvitation?: Maybe<ClientInvitation>;
  CreateCommunity?: Maybe<Community>;
  CreateEmblaShareCoupon: Coupon;
  CreateEventLogEntry?: Maybe<EventLogEntry>;
  CreateFeedbackConfiguration?: Maybe<Scalars['Boolean']['output']>;
  CreateLeadScoring: SalesLead;
  CreateMarketingLead: MarketingLead;
  CreateRelationship?: Maybe<Relationship>;
  CreateReminder?: Maybe<Reminder>;
  CreateSalesLead: SalesLead;
  CreateTagConfiguration?: Maybe<TagConfiguration>;
  DeactivateReminder?: Maybe<Reminder>;
  DeclineRelationship?: Maybe<Relationship>;
  DeleteClientInvitation?: Maybe<Scalars['Boolean']['output']>;
  DeleteEventLogEntry?: Maybe<EventLogEntry>;
  DeleteFeedbackConfiguration?: Maybe<Scalars['Boolean']['output']>;
  DeleteGreeting?: Maybe<Scalars['Boolean']['output']>;
  DeleteMarketingLead: Scalars['Boolean']['output'];
  DeleteRelationship?: Maybe<Scalars['Boolean']['output']>;
  DeleteReminder?: Maybe<Scalars['Boolean']['output']>;
  DeleteTagConfiguration?: Maybe<Scalars['Boolean']['output']>;
  DeleteWeightGoal?: Maybe<Scalars['Boolean']['output']>;
  DisableReminders?: Maybe<Client>;
  EmblaShareAddRedemptionAdmin?: Maybe<Scalars['Boolean']['output']>;
  EmblaShareApplyCode?: Maybe<Scalars['Boolean']['output']>;
  EmblaShareRemoveRedemptionAdmin?: Maybe<Scalars['Boolean']['output']>;
  EmployeeActivate?: Maybe<Employee>;
  EmployeeDeactivate?: Maybe<Employee>;
  EmployeeDescriptionDelete?: Maybe<Scalars['Boolean']['output']>;
  EmployeeDescriptionUpsert?: Maybe<Array<Maybe<EmployeeDescription>>>;
  EnableReminders?: Maybe<Client>;
  JoinCommunity?: Maybe<Scalars['Boolean']['output']>;
  LearningFeedItemFavorit?: Maybe<Scalars['Boolean']['output']>;
  LearningFeedItemLike?: Maybe<Scalars['Boolean']['output']>;
  LearningFeedItemMarkAsRead?: Maybe<Scalars['Boolean']['output']>;
  LeaveCommunity?: Maybe<Scalars['Boolean']['output']>;
  LogFeedItemEvent?: Maybe<Scalars['Boolean']['output']>;
  MeasurementImage?: Maybe<MeasurementImage>;
  MeasurementImageDelete?: Maybe<Scalars['Boolean']['output']>;
  MeasurementLiquidImage?: Maybe<MeasurementLiquidSuggestion>;
  MessageAddReaction?: Maybe<Message>;
  MessageRemoveReaction?: Maybe<Message>;
  RecipeFavorit?: Maybe<Scalars['Boolean']['output']>;
  RefundInvoice?: Maybe<Scalars['Boolean']['output']>;
  RegisterAbsence?: Maybe<Absence>;
  SaveWeightGoal?: Maybe<WeightGoal>;
  SignupFunnelFinalizeV3?: Maybe<Scalars['Boolean']['output']>;
  SignupFunnelMarkAsLead?: Maybe<Scalars['Boolean']['output']>;
  SyncConfiguration?: Maybe<Scalars['Boolean']['output']>;
  SyncExternalMeasurements?: Maybe<Scalars['Boolean']['output']>;
  TaskCreate?: Maybe<Task>;
  TaskCreateThread?: Maybe<Task>;
  TaskDelete?: Maybe<Scalars['Boolean']['output']>;
  TaskUpdate?: Maybe<Task>;
  ToggleEmailMarketing?: Maybe<Scalars['Boolean']['output']>;
  TrackEvent?: Maybe<Scalars['Boolean']['output']>;
  UnassignLearning?: Maybe<Array<Maybe<LearningAssignment>>>;
  UnassignTag?: Maybe<Scalars['Boolean']['output']>;
  UpdateAbsenceRegistration?: Maybe<Absence>;
  UpdateAbuseReview?: Maybe<AbuseReview>;
  UpdateCalendar?: Maybe<Calendar>;
  UpdateClient: Client;
  UpdateClientInvitation?: Maybe<ClientInvitation>;
  UpdateCommunity?: Maybe<Community>;
  UpdateEventLogEntry?: Maybe<EventLogEntry>;
  UpdateFeedbackConfiguration?: Maybe<Scalars['Boolean']['output']>;
  UpdateGamificationConfiguration?: Maybe<GamificationConfiguration>;
  UpdateMarketingLead: MarketingLead;
  UpdateMessageThreadAuthorDisplayType?: Maybe<Scalars['Boolean']['output']>;
  UpdateMessageThreadCatchAllStatus?: Maybe<Scalars['Boolean']['output']>;
  UpdateReminder?: Maybe<Reminder>;
  UpdateSalesLead: SalesLead;
  UpdateScheduledSubscription?: Maybe<Scalars['Boolean']['output']>;
  UpdateTagConfiguration?: Maybe<TagConfiguration>;
  UpdateTrustPilotReview?: Maybe<TrustPilotReview>;
  UpdateUnitSystem: User;
  UpsertFeedItemLocalization?: Maybe<Array<Maybe<FeedItemLocalization>>>;
  UpsertGreeting?: Maybe<Greeting>;
  UserAchievementRewardClaim?: Maybe<Reward>;
  UserAchievementRewardClaimAll?: Maybe<Array<Maybe<Reward>>>;
  UserAchievementRewardView?: Maybe<Reward>;
  UserActivateCareTeamMember?: Maybe<Scalars['Boolean']['output']>;
  UserAddClinicalScreening?: Maybe<ClinicalScreening>;
  UserAssessment?: Maybe<Scalars['Boolean']['output']>;
  UserAssignCareTeamMember?: Maybe<Scalars['ID']['output']>;
  UserAssignCareTeamMemberBatch?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  UserAvatarConfiguration?: Maybe<Scalars['JSON']['output']>;
  UserChangeCountry?: Maybe<Scalars['Boolean']['output']>;
  UserCompleteIdConfirmation?: Maybe<UserIdConfirmationResult>;
  UserConnectPaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  UserConsentCommunityTerms?: Maybe<User>;
  UserConsentCurrentLogbookAndTerms?: Maybe<User>;
  UserCreate?: Maybe<Token>;
  UserCreateOrUpdateDeviceTokens?: Maybe<Scalars['Boolean']['output']>;
  UserCreatePaymentIntent?: Maybe<StripePaymentIntent>;
  UserDeactivateCareTeamMember?: Maybe<Scalars['Boolean']['output']>;
  UserDelete?: Maybe<UserDeleteResponse>;
  UserDeleteAll?: Maybe<UserDeleteResponse>;
  UserDeleteDeviceTokens?: Maybe<Scalars['Boolean']['output']>;
  UserGetSignInFlow?: Maybe<UserSignInFlowDecisionData>;
  UserGoFreemium?: Maybe<Scalars['Boolean']['output']>;
  UserImageRemoveV2?: Maybe<Scalars['Boolean']['output']>;
  UserImageUploadV2?: Maybe<UserImage>;
  UserLoginAuth0?: Maybe<Token>;
  UserLoginAuth0V2?: Maybe<Token>;
  UserMeasurement?: Maybe<Measurement>;
  UserMemberFocusItem?: Maybe<MemberFocus>;
  UserMemberFocusItemDelete?: Maybe<Scalars['Boolean']['output']>;
  UserMessageCreate?: Maybe<Message>;
  UserMessageDelete?: Maybe<Scalars['Boolean']['output']>;
  UserMessageEdit?: Maybe<Message>;
  UserMessageGetRobertReplySuggestion?: Maybe<Scalars['String']['output']>;
  UserMessageMultipleImagesCreate?: Maybe<Array<Maybe<Message>>>;
  UserMessageRegret?: Maybe<Message>;
  UserMessageThreadLeave?: Maybe<Scalars['Boolean']['output']>;
  UserMessageThreadNotificationsMute?: Maybe<Scalars['Boolean']['output']>;
  UserMessageThreadRead?: Maybe<MessageThread>;
  UserMessageThreadUnread?: Maybe<MessageThread>;
  UserMessagesCreate?: Maybe<Array<Maybe<Message>>>;
  UserNoteCreate?: Maybe<UserNote>;
  UserNoteDelete?: Maybe<Scalars['Boolean']['output']>;
  UserPayInvitationFee?: Maybe<StripePaymentIntent>;
  UserRemoveGoFreemium?: Maybe<Scalars['Boolean']['output']>;
  UserRemoveMeasurement?: Maybe<Scalars['Boolean']['output']>;
  UserRequestData?: Maybe<Scalars['Boolean']['output']>;
  UserResendVerificationEmail?: Maybe<Scalars['Boolean']['output']>;
  UserResetPassword?: Maybe<Scalars['Boolean']['output']>;
  UserResetPasswordInput?: Maybe<Scalars['Boolean']['output']>;
  UserResetToPotentialCustomer?: Maybe<Scalars['Boolean']['output']>;
  UserScreeningForm?: Maybe<SelfScreeningResult>;
  UserScreeningFormV2?: Maybe<SelfScreeningResult>;
  UserSetDefaultPaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  UserSignupFunnelLogin?: Maybe<SignupFunnelLoginResult>;
  UserSignupSetWeight?: Maybe<Measurement>;
  UserStartIdConfirmation?: Maybe<Scalars['Boolean']['output']>;
  UserSubmitFeedback?: Maybe<Scalars['Boolean']['output']>;
  UserSubscriptionChange?: Maybe<Scalars['Boolean']['output']>;
  UserSubscriptionPause?: Maybe<Scalars['Boolean']['output']>;
  UserSubscriptionPriceChange?: Maybe<Scalars['Boolean']['output']>;
  UserSubscriptionScheduleBillingCycle?: Maybe<Scalars['Boolean']['output']>;
  UserSubscriptionSetup: UserSetupSubscriptionResponse;
  UserSubscriptionStartV2?: Maybe<UserStartSubscriptionResponse>;
  UserSubscriptionStop?: Maybe<Scalars['Boolean']['output']>;
  UserSwitchLocaleISO6391?: Maybe<Token>;
  UserUnassignCareTeamMember?: Maybe<Scalars['Boolean']['output']>;
  UserUpdate?: Maybe<User>;
  UserUpdateDeviceToken?: Maybe<Scalars['Boolean']['output']>;
  UserUpdateMetaData?: Maybe<Scalars['Boolean']['output']>;
  UserUpdateV2?: Maybe<User>;
  WithdrawAbsenceRegistration?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated No longer supported */
  _Calendar?: Maybe<Calendar>;
  _CalendarAvailabilityRemove?: Maybe<Scalars['Boolean']['output']>;
  _CalendarAvailabilityV2?: Maybe<CalendarAvailability>;
  _CalendarException?: Maybe<CalendarException>;
  _CalendarExceptionRemove?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use new community model */
  _CommunityCreateOrUpdate?: Maybe<MessageThread>;
  _CommunityDisable?: Maybe<Scalars['Boolean']['output']>;
  _PrescriptionRemove?: Maybe<Scalars['Boolean']['output']>;
  _UserClientAssign?: Maybe<User>;
  _UserCreateAssignedService?: Maybe<UserAssignedService>;
  _UserEmployeeSignup?: Maybe<Token>;
  _UserGenerateToken?: Maybe<Token>;
  _UserInvalidateTokens?: Maybe<UserTokensInvalidated>;
  _UserRoleChange?: Maybe<User>;
  _UserSwitchCountryISO3166?: Maybe<Token>;
  _UserTag?: Maybe<User>;
  _UserUpdateAssignedService?: Maybe<UserAssignedService>;
  _UserUpdateOrCreatePrescription?: Maybe<Prescription>;
};

export type MutationAcceptRelationshipArgs = {
  targetUserId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationActivateOrDeactivateCommunityArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationActivateReminderArgs = {
  reminderId: Scalars['ID']['input'];
};

export type MutationAddMeasurementsArgs = {
  data: Array<CreateMeasurementEventInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAddTagsToMarketingLeadArgs = {
  id: Scalars['ID']['input'];
  tags: Array<MarketingLeadTag>;
};

export type MutationAddUserVerificationArgs = {
  dateOfBirth: Scalars['Date']['input'];
  fullName: Scalars['String']['input'];
  memberId: Scalars['ID']['input'];
  screenshot: UserImageInput;
};

export type MutationAddressCreateOrUpdateArgs = {
  address?: InputMaybe<AddressInput>;
};

export type MutationAppointmentCancelArgs = {
  id: Scalars['ID']['input'];
};

export type MutationAppointmentCompleteArgs = {
  id: Scalars['ID']['input'];
  value?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationAppointmentCreateArgs = {
  appointmentDuration?: InputMaybe<CoachingAppointmentDuration>;
  appointmentReason?: InputMaybe<AppointmentReason>;
  calendarId?: InputMaybe<Scalars['ID']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  force?: InputMaybe<Scalars['Boolean']['input']>;
  invitationId?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationAppointmentMoveArgs = {
  appointmentDuration?: InputMaybe<CoachingAppointmentDuration>;
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  appointmentReason?: InputMaybe<AppointmentReason>;
  calendarId?: InputMaybe<Scalars['ID']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  invitationId?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationAppointmentNoShowArgs = {
  id: Scalars['ID']['input'];
};

export type MutationAssignLearningArgs = {
  clientId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export type MutationAssignTagArgs = {
  tag?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type MutationBookmarkLearningArgs = {
  id: Scalars['ID']['input'];
  type: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCancelScheduledSubscriptionArgs = {
  subscriptionId: Scalars['String']['input'];
};

export type MutationChristmasCalendarParticipateArgs = {
  dayId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationClientMuteFeedItemTypeArgs = {
  clientId: Scalars['ID']['input'];
  feedItemType: FeedItemType;
};

export type MutationClientReactionToFeedItemArgs = {
  measurementId: Scalars['ID']['input'];
  reaction?: InputMaybe<Scalars['String']['input']>;
};

export type MutationClientSetMeasurementNudgeSettingArgs = {
  clientId: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
  measurementType: MeasurementType;
};

export type MutationClientUnmuteFeedItemTypeArgs = {
  clientId: Scalars['ID']['input'];
  feedItemType: FeedItemType;
};

export type MutationCompleteFeedItemArgs = {
  data?: InputMaybe<FeedItemCompleteInput>;
};

export type MutationCompleteLearningArgs = {
  id: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export type MutationCreateAbuseReportArgs = {
  data?: InputMaybe<AbuseReportInput>;
};

export type MutationCreateCalendarArgs = {
  data: CalendarInput;
  userId: Scalars['ID']['input'];
};

export type MutationCreateClientInvitationArgs = {
  data?: InputMaybe<ClientInvitationCreate>;
};

export type MutationCreateCommunityArgs = {
  community?: InputMaybe<CommunityInput>;
};

export type MutationCreateEmblaShareCouponArgs = {
  ownerId: Scalars['ID']['input'];
};

export type MutationCreateEventLogEntryArgs = {
  entry?: InputMaybe<EventLogEntryInput>;
  trackEvent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateFeedbackConfigurationArgs = {
  configuration?: InputMaybe<FeedbackConfigurationInput>;
  localizations?: InputMaybe<Array<InputMaybe<FeedbackConfigurationLocalizationInput>>>;
};

export type MutationCreateLeadScoringArgs = {
  confirmedLeadScoringQuestions: Array<InputMaybe<LeadScoringQuestion>>;
  leadScoringVersion: LeadScoringVersion;
  salesLeadId: Scalars['ID']['input'];
};

export type MutationCreateMarketingLeadArgs = {
  clientId: Scalars['ID']['input'];
  tags: Array<MarketingLeadTag>;
};

export type MutationCreateRelationshipArgs = {
  targetUserId: Scalars['ID']['input'];
  type: RelationshipType;
};

export type MutationCreateReminderArgs = {
  reminder: CreateReminderPayload;
};

export type MutationCreateSalesLeadArgs = {
  input: CreateSalesLeadInput;
};

export type MutationCreateTagConfigurationArgs = {
  configuration?: InputMaybe<TagConfigurationInput>;
  localizations?: InputMaybe<Array<InputMaybe<TagConfigurationLocalizationInput>>>;
};

export type MutationDeactivateReminderArgs = {
  reminderId: Scalars['ID']['input'];
};

export type MutationDeclineRelationshipArgs = {
  targetUserId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationDeleteClientInvitationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteEventLogEntryArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFeedbackConfigurationArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeleteGreetingArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteMarketingLeadArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteRelationshipArgs = {
  targetUserId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationDeleteReminderArgs = {
  reminderId: Scalars['ID']['input'];
};

export type MutationDeleteTagConfigurationArgs = {
  tag: Scalars['String']['input'];
};

export type MutationDeleteWeightGoalArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationEmblaShareAddRedemptionAdminArgs = {
  applyBuddyDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  couponId: Scalars['ID']['input'];
  redeemedById?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationEmblaShareApplyCodeArgs = {
  code: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationEmblaShareRemoveRedemptionAdminArgs = {
  couponId: Scalars['ID']['input'];
  redeemedById: Scalars['ID']['input'];
};

export type MutationEmployeeActivateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEmployeeDeactivateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEmployeeDescriptionDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEmployeeDescriptionUpsertArgs = {
  descriptions: Array<InputMaybe<EmployeeDescriptionInput>>;
};

export type MutationJoinCommunityArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationLearningFeedItemFavoritArgs = {
  id: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
};

export type MutationLearningFeedItemLikeArgs = {
  id: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
};

export type MutationLearningFeedItemMarkAsReadArgs = {
  id: Scalars['ID']['input'];
};

export type MutationLeaveCommunityArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationLogFeedItemEventArgs = {
  data?: InputMaybe<FeedItemEventInput>;
};

export type MutationMeasurementImageArgs = {
  base64?: InputMaybe<Scalars['String']['input']>;
  measurementId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MutationMeasurementImageDeleteArgs = {
  measurementId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MutationMeasurementLiquidImageArgs = {
  base64?: InputMaybe<Scalars['String']['input']>;
};

export type MutationMessageAddReactionArgs = {
  emoji: Scalars['String']['input'];
  messageId: Scalars['ID']['input'];
};

export type MutationMessageRemoveReactionArgs = {
  emoji: Scalars['String']['input'];
  messageId: Scalars['ID']['input'];
};

export type MutationRecipeFavoritArgs = {
  id: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
};

export type MutationRefundInvoiceArgs = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  invoiceId: Scalars['ID']['input'];
};

export type MutationRegisterAbsenceArgs = {
  payload: RegisterAbsencePayload;
};

export type MutationSaveWeightGoalArgs = {
  weightGoal?: InputMaybe<WeightGoalInput>;
};

export type MutationSignupFunnelFinalizeV3Args = {
  data?: InputMaybe<SignupFunnelFinalizeV3Input>;
  terminal?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationSyncConfigurationArgs = {
  type?: InputMaybe<Array<InputMaybe<ConfigurationType>>>;
};

export type MutationSyncExternalMeasurementsArgs = {
  data: Array<SyncMeasurementEventInput>;
  dateRange: DateRange;
};

export type MutationTaskCreateArgs = {
  assigneeGroup?: InputMaybe<UserGroups>;
  assigneeUserId?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  subject: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type MutationTaskCreateThreadArgs = {
  id: Scalars['ID']['input'];
};

export type MutationTaskDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationTaskUpdateArgs = {
  id: Scalars['ID']['input'];
  task?: InputMaybe<TaskInput>;
};

export type MutationToggleEmailMarketingArgs = {
  clientId: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
};

export type MutationTrackEventArgs = {
  data?: InputMaybe<Scalars['String']['input']>;
  eventName: Scalars['String']['input'];
};

export type MutationUnassignLearningArgs = {
  clientId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export type MutationUnassignTagArgs = {
  tag?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type MutationUpdateAbsenceRegistrationArgs = {
  payload: UpdateAbsencePayload;
};

export type MutationUpdateAbuseReviewArgs = {
  abuseReviewId: Scalars['ID']['input'];
  data?: InputMaybe<AbuseReviewInput>;
};

export type MutationUpdateCalendarArgs = {
  calendarId: Scalars['ID']['input'];
  data: CalendarInput;
};

export type MutationUpdateClientArgs = {
  clientInput?: InputMaybe<ClientInput>;
  id: Scalars['ID']['input'];
  motivations?: InputMaybe<Array<InputMaybe<ClientMotivation>>>;
};

export type MutationUpdateClientInvitationArgs = {
  data?: InputMaybe<ClientInvitationUpdate>;
  id: Scalars['ID']['input'];
};

export type MutationUpdateCommunityArgs = {
  community?: InputMaybe<CommunityInput>;
  id: Scalars['ID']['input'];
};

export type MutationUpdateEventLogEntryArgs = {
  entry?: InputMaybe<EventLogEntryInput>;
  id: Scalars['ID']['input'];
};

export type MutationUpdateFeedbackConfigurationArgs = {
  configuration?: InputMaybe<FeedbackConfigurationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  localizations?: InputMaybe<Array<InputMaybe<FeedbackConfigurationLocalizationInput>>>;
};

export type MutationUpdateGamificationConfigurationArgs = {
  clientId: Scalars['ID']['input'];
  newConfig: GamificationConfigurationInput;
};

export type MutationUpdateMarketingLeadArgs = {
  id: Scalars['ID']['input'];
  status?: InputMaybe<MarketingLeadStatus>;
  tags?: InputMaybe<Array<InputMaybe<MarketingLeadTag>>>;
};

export type MutationUpdateMessageThreadAuthorDisplayTypeArgs = {
  displayType: MessageAuthorDisplayType;
  threadId: Scalars['ID']['input'];
};

export type MutationUpdateMessageThreadCatchAllStatusArgs = {
  catchAllStatus?: InputMaybe<CatchAllStatus>;
  threadId: Scalars['ID']['input'];
};

export type MutationUpdateReminderArgs = {
  reminder: UpdateReminderPayload;
};

export type MutationUpdateSalesLeadArgs = {
  followup?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  lostLeadReason?: InputMaybe<SalesLeadLostReason>;
  referrerSource?: InputMaybe<SalesLeadReferrerSource>;
  responsibleEmployeeId?: InputMaybe<Scalars['ID']['input']>;
  status: SalesLeadStatus;
  tags?: InputMaybe<Array<InputMaybe<SalesLeadTag>>>;
};

export type MutationUpdateScheduledSubscriptionArgs = {
  productKey: Product;
  startDate: Scalars['Date']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type MutationUpdateTagConfigurationArgs = {
  configuration?: InputMaybe<TagConfigurationInput>;
  localizations?: InputMaybe<Array<InputMaybe<TagConfigurationLocalizationInput>>>;
  tag: Scalars['String']['input'];
};

export type MutationUpdateTrustPilotReviewArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  employeeId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUnitSystemArgs = {
  unitSystem: UnitSystem;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpsertFeedItemLocalizationArgs = {
  localization?: InputMaybe<Array<InputMaybe<FeedItemLocalizationInput>>>;
};

export type MutationUpsertGreetingArgs = {
  greeting?: InputMaybe<GreetingInput>;
};

export type MutationUserAchievementRewardClaimArgs = {
  rewardId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUserAchievementRewardClaimAllArgs = {
  locale: LocaleIso6391;
};

export type MutationUserAchievementRewardViewArgs = {
  rewardId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUserActivateCareTeamMemberArgs = {
  careTeamMembershipId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUserAddClinicalScreeningArgs = {
  approvedForProducts: Array<InputMaybe<Product>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  confirmedLeadScoringQuestions?: InputMaybe<Array<InputMaybe<LeadScoringQuestion>>>;
  leadScoringVersion?: InputMaybe<LeadScoringVersion>;
  productDiscountCoupon?: InputMaybe<Scalars['String']['input']>;
  referrerSource?: InputMaybe<Scalars['String']['input']>;
  result: ClinicalScreeningResult;
  userFeedback?: InputMaybe<ClinicalScreeningUserFeedback>;
  userId: Scalars['ID']['input'];
};

export type MutationUserAssessmentArgs = {
  anonymousId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  recaptchaToken: Scalars['String']['input'];
};

export type MutationUserAssignCareTeamMemberArgs = {
  careTeamMemberId: Scalars['ID']['input'];
  clientUserId: Scalars['ID']['input'];
  periodEnd?: InputMaybe<Scalars['Date']['input']>;
  periodStart?: InputMaybe<Scalars['Date']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  responsibility: CareTeamResponsibility;
};

export type MutationUserAssignCareTeamMemberBatchArgs = {
  careTeamMemberId: Scalars['ID']['input'];
  clientUserIds: Array<InputMaybe<Scalars['ID']['input']>>;
  periodEnd?: InputMaybe<Scalars['Date']['input']>;
  periodStart?: InputMaybe<Scalars['Date']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  responsibility?: InputMaybe<CareTeamResponsibility>;
};

export type MutationUserAvatarConfigurationArgs = {
  config?: InputMaybe<Scalars['JSON']['input']>;
};

export type MutationUserChangeCountryArgs = {
  countryISO3166: CountryIso3166;
  userId: Scalars['ID']['input'];
};

export type MutationUserCompleteIdConfirmationArgs = {
  code: Scalars['String']['input'];
  receiver?: InputMaybe<Scalars['String']['input']>;
  type: UserIdConfirmationType;
};

export type MutationUserConnectPaymentMethodArgs = {
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUserCreateArgs = {
  countryISO3166: CountryIso3166;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  localeISO6391?: InputMaybe<LocaleIso6391>;
  metaData?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  recaptchaToken?: InputMaybe<Scalars['String']['input']>;
  temporaryUserId?: InputMaybe<Scalars['ID']['input']>;
  utmHeaders?: InputMaybe<UtmHeaders>;
};

export type MutationUserCreateOrUpdateDeviceTokensArgs = {
  deviceToken?: InputMaybe<Scalars['String']['input']>;
  expoToken: Scalars['String']['input'];
  platform?: InputMaybe<PlatformType>;
  voipDeviceToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUserCreatePaymentIntentArgs = {
  autoCollect?: InputMaybe<Scalars['Boolean']['input']>;
  priceId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUserDeactivateCareTeamMemberArgs = {
  careTeamMembershipId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUserDeleteArgs = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationUserDeleteAllArgs = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationUserDeleteDeviceTokensArgs = {
  expoToken: Scalars['String']['input'];
};

export type MutationUserGetSignInFlowArgs = {
  overrideData?: InputMaybe<SignInFlowOverrideData>;
  receiver: Scalars['String']['input'];
  type: UserIdConfirmationType;
};

export type MutationUserImageRemoveV2Args = {
  userImageRemoveInput?: InputMaybe<UserImageRemoveInput>;
};

export type MutationUserImageUploadV2Args = {
  userImageInput?: InputMaybe<UserImageInput>;
};

export type MutationUserLoginAuth0Args = {
  auth0Token: Scalars['String']['input'];
};

export type MutationUserLoginAuth0V2Args = {
  auth0Token: Scalars['String']['input'];
  localeISO6391: LocaleIso6391;
};

export type MutationUserMeasurementArgs = {
  data?: InputMaybe<MeasurementInput>;
};

export type MutationUserMemberFocusItemArgs = {
  description: Scalars['String']['input'];
  headline: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  userAuthorId?: InputMaybe<Scalars['ID']['input']>;
  userMemberId?: InputMaybe<Scalars['ID']['input']>;
  visible: Scalars['Boolean']['input'];
};

export type MutationUserMemberFocusItemDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUserMessageCreateArgs = {
  message: Scalars['String']['input'];
  threadId: Scalars['ID']['input'];
  userImageId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUserMessageDeleteArgs = {
  messageId: Scalars['ID']['input'];
  threadId: Scalars['ID']['input'];
};

export type MutationUserMessageEditArgs = {
  message: Scalars['String']['input'];
  messageId: Scalars['ID']['input'];
  threadId: Scalars['ID']['input'];
};

export type MutationUserMessageGetRobertReplySuggestionArgs = {
  messageId: Scalars['ID']['input'];
};

export type MutationUserMessageMultipleImagesCreateArgs = {
  message?: InputMaybe<Scalars['String']['input']>;
  threadId: Scalars['ID']['input'];
  userImageIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  userImageInputs?: InputMaybe<Array<InputMaybe<UserImageInput>>>;
};

export type MutationUserMessageRegretArgs = {
  messageId: Scalars['ID']['input'];
  threadId: Scalars['ID']['input'];
};

export type MutationUserMessageThreadLeaveArgs = {
  threadId: Scalars['ID']['input'];
  userId: Scalars['String']['input'];
};

export type MutationUserMessageThreadNotificationsMuteArgs = {
  mute?: InputMaybe<Scalars['Boolean']['input']>;
  threadId: Scalars['ID']['input'];
};

export type MutationUserMessageThreadReadArgs = {
  threadId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUserMessageThreadUnreadArgs = {
  threadId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUserMessagesCreateArgs = {
  message: Scalars['String']['input'];
  userIds: Array<InputMaybe<Scalars['ID']['input']>>;
  userImageId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUserNoteCreateArgs = {
  note: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  userImageId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUserNoteDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUserPayInvitationFeeArgs = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  invitationId: Scalars['String']['input'];
};

export type MutationUserRemoveMeasurementArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUserResendVerificationEmailArgs = {
  userId: Scalars['String']['input'];
};

export type MutationUserResetPasswordArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUserResetPasswordInputArgs = {
  newPassword?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUserResetToPotentialCustomerArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationUserScreeningFormArgs = {
  CPRNumber?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  emblaShareCode?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  heightCm: Scalars['Int']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  results: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  weight: Scalars['Float']['input'];
};

export type MutationUserScreeningFormV2Args = {
  CPRNumber?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  emblaShareCode?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  heightCm: Scalars['Int']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  results: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  weight: Scalars['Float']['input'];
};

export type MutationUserSetDefaultPaymentMethodArgs = {
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUserSignupFunnelLoginArgs = {
  email: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type MutationUserSignupSetWeightArgs = {
  weight?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUserStartIdConfirmationArgs = {
  receiver?: InputMaybe<Scalars['String']['input']>;
  type: UserIdConfirmationType;
};

export type MutationUserSubmitFeedbackArgs = {
  data: SubmitFeedbackData;
  key: Scalars['String']['input'];
};

export type MutationUserSubscriptionChangeArgs = {
  subscriptionChangeType?: InputMaybe<SubscriptionChangeType>;
  subscriptionId: Scalars['String']['input'];
  type?: InputMaybe<Product>;
  userId: Scalars['ID']['input'];
};

export type MutationUserSubscriptionPauseArgs = {
  pauseUntil: Scalars['Date']['input'];
  stopType: SubscriptionStopType;
  stripeSubscriptionId: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationUserSubscriptionPriceChangeArgs = {
  priceType?: InputMaybe<PriceType>;
  subscriptionChangeType?: InputMaybe<SubscriptionChangeType>;
  subscriptionId: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationUserSubscriptionScheduleBillingCycleArgs = {
  date: Scalars['Date']['input'];
  resetNow?: InputMaybe<Scalars['Boolean']['input']>;
  subscriptionId: Scalars['String']['input'];
};

export type MutationUserSubscriptionSetupArgs = {
  priceId: Scalars['ID']['input'];
};

export type MutationUserSubscriptionStartV2Args = {
  couponId?: InputMaybe<Scalars['ID']['input']>;
  emblaOrStripeCouponCode?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  priceId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUserSubscriptionStopArgs = {
  subscriptionId: Scalars['String']['input'];
  subscriptionStopType?: InputMaybe<SubscriptionStopType>;
  userId: Scalars['ID']['input'];
};

export type MutationUserSwitchLocaleIso6391Args = {
  localeISO6391: LocaleIso6391;
};

export type MutationUserUnassignCareTeamMemberArgs = {
  careTeamMembershipId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUserUpdateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  user?: InputMaybe<UserInput>;
};

export type MutationUserUpdateDeviceTokenArgs = {
  deviceToken?: InputMaybe<Scalars['String']['input']>;
  isAdd?: InputMaybe<Scalars['Boolean']['input']>;
  platform?: InputMaybe<PlatformType>;
  token: Scalars['String']['input'];
  voipDeviceToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUserUpdateMetaDataArgs = {
  jsonData: Scalars['String']['input'];
};

export type MutationUserUpdateV2Args = {
  id?: InputMaybe<Scalars['ID']['input']>;
  user?: InputMaybe<UserInputV2>;
};

export type MutationWithdrawAbsenceRegistrationArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_CalendarArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  calendarId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation_CalendarAvailabilityRemoveArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation_CalendarAvailabilityV2Args = {
  calendarId?: InputMaybe<Scalars['ID']['input']>;
  data?: InputMaybe<CalendarAvailabilityInputV2>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation_CalendarExceptionArgs = {
  calendarId?: InputMaybe<Scalars['ID']['input']>;
  data?: InputMaybe<CalendarExceptionInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation_CalendarExceptionRemoveArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation_CommunityCreateOrUpdateArgs = {
  name: Scalars['String']['input'];
  participantIds: Array<InputMaybe<Scalars['ID']['input']>>;
  threadId?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation_CommunityDisableArgs = {
  threadId?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation_PrescriptionRemoveArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation_UserClientAssignArgs = {
  clientUserId?: InputMaybe<Scalars['ID']['input']>;
  coachUserId?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation_UserCreateAssignedServiceArgs = {
  assignedService: UserAssignedServiceInput;
};

export type Mutation_UserEmployeeSignupArgs = {
  countryISO3166: CountryIso3166;
  role: Role;
};

export type Mutation_UserGenerateTokenArgs = {
  userId: Scalars['ID']['input'];
};

export type Mutation_UserInvalidateTokensArgs = {
  userId: Scalars['ID']['input'];
};

export type Mutation_UserRoleChangeArgs = {
  role?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation_UserSwitchCountryIso3166Args = {
  countryISO3166: CountryIso3166;
};

export type Mutation_UserTagArgs = {
  toggleTag: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type Mutation_UserUpdateAssignedServiceArgs = {
  assignedService: UserAssignedServiceInput;
  id: Scalars['ID']['input'];
};

export type Mutation_UserUpdateOrCreatePrescriptionArgs = {
  prescription?: InputMaybe<PrescriptionInput>;
};

export type MyCareTeamFeatures = {
  __typename?: 'MyCareTeamFeatures';
  disableNurse?: Maybe<Scalars['Boolean']['output']>;
};

export enum NonEligibleReason {
  AlcoholDrugs = 'AlcoholDrugs',
  EatingDisorder = 'EatingDisorder',
}

export type NumberRange = {
  max?: InputMaybe<Scalars['Int']['input']>;
  min?: InputMaybe<Scalars['Int']['input']>;
};

export type OgTagsInput = {
  ogDescription?: InputMaybe<Scalars['String']['input']>;
  ogImage?: InputMaybe<Scalars['String']['input']>;
  ogTitle: Scalars['String']['input'];
  ogUrl?: InputMaybe<Scalars['String']['input']>;
};

export enum OrderBy {
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  LastWeekMeasurementCount = 'lastWeekMeasurementCount',
}

export type PaginatedClientSearchResult = {
  __typename?: 'PaginatedClientSearchResult';
  results: Array<Client>;
  total: Scalars['Int']['output'];
};

export type PaginatedCommunitySearchResult = {
  __typename?: 'PaginatedCommunitySearchResult';
  results: Array<Community>;
  total: Scalars['Int']['output'];
};

export type PaginatedEmployeeSearchResult = {
  __typename?: 'PaginatedEmployeeSearchResult';
  results: Array<Employee>;
  total: Scalars['Int']['output'];
};

export type PaginatedMarketingLeadSearchResult = {
  __typename?: 'PaginatedMarketingLeadSearchResult';
  results: Array<MarketingLead>;
  total: Scalars['Int']['output'];
};

export type PaginatedMessageThreadSearchResult = {
  __typename?: 'PaginatedMessageThreadSearchResult';
  results: Array<MessageThread>;
  total: Scalars['Int']['output'];
};

export type PaginatedSalesLeadSearchResult = {
  __typename?: 'PaginatedSalesLeadSearchResult';
  results: Array<SalesLead>;
  total: Scalars['Int']['output'];
};

export type PaginatedTaskSearchResult = {
  __typename?: 'PaginatedTaskSearchResult';
  results: Array<Task>;
  total: Scalars['Int']['output'];
};

export type PaginatedUserSearchResult = {
  __typename?: 'PaginatedUserSearchResult';
  results: Array<User>;
  total: Scalars['Int']['output'];
};

export enum PaymentMethod {
  ApplePay = 'APPLE_PAY',
  Card = 'CARD',
  GooglePay = 'GOOGLE_PAY',
}

export enum PaymentSourceType {
  Card = 'card',
  Link = 'link',
}

export type PerformanceReport = {
  __typename?: 'PerformanceReport';
  appointmentsBookedAll?: Maybe<Scalars['Int']['output']>;
  appointmentsBookedCurrentWeek?: Maybe<Scalars['Int']['output']>;
  appointmentsBookedNextWeek?: Maybe<Scalars['Int']['output']>;
  appointmentsCurrentWeek?: Maybe<Scalars['Int']['output']>;
  appointmentsMinus1Week?: Maybe<Scalars['Int']['output']>;
  appointmentsMinus2Weeks?: Maybe<Scalars['Int']['output']>;
  appointmentsTotalLast30Days?: Maybe<Scalars['Int']['output']>;
  appointmentsTotalPrevious30Days?: Maybe<Scalars['Int']['output']>;
  chatCurrentWeek?: Maybe<Scalars['Int']['output']>;
  chatMinus1Week?: Maybe<Scalars['Int']['output']>;
  chatMinus2Weeks?: Maybe<Scalars['Int']['output']>;
  chatTotalLast30Days?: Maybe<Scalars['Int']['output']>;
  chatTotalPrevious30Days?: Maybe<Scalars['Int']['output']>;
  employeeId?: Maybe<Scalars['ID']['output']>;
  membersNewCurrentWeek?: Maybe<Scalars['Int']['output']>;
  membersNewLast30Days?: Maybe<Scalars['Int']['output']>;
  membersNewMinus1Week?: Maybe<Scalars['Int']['output']>;
  membersNewMinus2Weeks?: Maybe<Scalars['Int']['output']>;
  membersTotal?: Maybe<Scalars['Int']['output']>;
  tasksSolvedCurrentWeek?: Maybe<Scalars['Int']['output']>;
  tasksSolvedLast30Days?: Maybe<Scalars['Int']['output']>;
  tasksSolvedMinus1Week?: Maybe<Scalars['Int']['output']>;
  tasksSolvedMinus2Weeks?: Maybe<Scalars['Int']['output']>;
};

export enum PlatformType {
  Android = 'ANDROID',
  Ios = 'IOS',
}

export type Pong = {
  __typename?: 'Pong';
  time?: Maybe<Scalars['String']['output']>;
};

export type Prescription = {
  __typename?: 'Prescription';
  article?: Maybe<MedicationArticle>;
  comment?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  expeditionFrom?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type PrescriptionInput = {
  article: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  expeditionFrom?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['String']['input'];
};

export enum PriceType {
  Legacy = 'LEGACY',
  LowerDose = 'LOWER_DOSE',
  Normal = 'NORMAL',
}

export type PrimaryCareTeamMember = {
  __typename?: 'PrimaryCareTeamMember';
  about?: Maybe<Scalars['String']['output']>;
  absentEmoji?: Maybe<Scalars['String']['output']>;
  absentEnd?: Maybe<Scalars['Date']['output']>;
  employeeUserId?: Maybe<Scalars['ID']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isAbsent?: Maybe<Scalars['Boolean']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profilePictureId?: Maybe<Scalars['String']['output']>;
  responsibility?: Maybe<CareTeamResponsibility>;
};

export enum Product {
  Coaching = 'COACHING',
  CoachingAndMedication = 'COACHING_AND_MEDICATION',
  CoachingAndMedicationPrep = 'COACHING_AND_MEDICATION_PREP',
  EmblaGo = 'EMBLA_GO',
  EmblaGoMedication = 'EMBLA_GO_MEDICATION',
  EmblaLight = 'EMBLA_LIGHT',
  PsychologySession = 'PSYCHOLOGY_SESSION',
}

export type ProductRequirements = {
  __typename?: 'ProductRequirements';
  clinicalScreening?: Maybe<Scalars['Boolean']['output']>;
};

export type PromoCode = {
  __typename?: 'PromoCode';
  amount?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  durationInMonths?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  percentage?: Maybe<Scalars['Float']['output']>;
  promoCodeId: Scalars['ID']['output'];
};

export type PublicBookableScreeningAppointment = {
  __typename?: 'PublicBookableScreeningAppointment';
  calendarId: Scalars['ID']['output'];
  endTime: Scalars['Date']['output'];
  name: Scalars['String']['output'];
  profilePictureImageId?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['Date']['output'];
  userId: Scalars['ID']['output'];
  userRole: Role;
};

export type Query = {
  __typename?: 'Query';
  AbsenceGetAllFutureRegistrations?: Maybe<Array<Maybe<Absence>>>;
  AbsenceGetRegistrationPreview?: Maybe<AbsenceRegistrationPreview>;
  AbuseReviewByTaskId?: Maybe<AbuseReview>;
  Appointment?: Maybe<Appointment>;
  BookableScreeningAppointments?: Maybe<Array<Maybe<PublicBookableScreeningAppointment>>>;
  ChristmasCalendar?: Maybe<ChristmasCalendar>;
  ChurnReport?: Maybe<Report>;
  Client?: Maybe<Client>;
  ClientCommunities?: Maybe<ClientCommunities>;
  ClientGameFriendFeed?: Maybe<Array<Maybe<ClientGameFriendFeedItem>>>;
  ClientGameLeadershipBoard?: Maybe<LeadershipBoard>;
  ClientGameOverview?: Maybe<ClientGameOverview>;
  ClientRandomAlias?: Maybe<Scalars['String']['output']>;
  ClientSearch?: Maybe<PaginatedClientSearchResult>;
  ClientWeeklyReport?: Maybe<ClientWeeklyReport>;
  Community?: Maybe<Community>;
  CommunitySearch?: Maybe<PaginatedCommunitySearchResult>;
  ConfigurationObjects?: Maybe<ConfigurationObjects>;
  ConfigurationSync?: Maybe<ConfigurationSyncData>;
  ConfigurationSyncLog?: Maybe<Array<Maybe<ConfigurationSyncLogEntry>>>;
  Country?: Maybe<Country>;
  CustomerPortalSessionUrl?: Maybe<CustomerPortalSession>;
  Dashboard?: Maybe<DashboardInfo>;
  DiscountCode?: Maybe<DiscountCode>;
  DropOffReport?: Maybe<Report>;
  EmblaShare?: Maybe<Coupon>;
  EmblaShareAdmin?: Maybe<Coupon>;
  EmblaShareDetails?: Maybe<EmblaShareDetails>;
  Employee?: Maybe<Employee>;
  EmployeeDescriptions?: Maybe<Array<Maybe<EmployeeDescription>>>;
  EmployeeInfo?: Maybe<EmployeeInfo>;
  EmployeePerformanceReport?: Maybe<Array<Maybe<PerformanceReport>>>;
  EmployeePerformanceReportFilters?: Maybe<PaginatedEmployeeSearchResult>;
  EmployeeSearch?: Maybe<PaginatedEmployeeSearchResult>;
  EventLogEntries?: Maybe<Array<Maybe<EventLogEntry>>>;
  FeedItemLocalization?: Maybe<Array<Maybe<FeedItemLocalization>>>;
  FeedbackConfigurations?: Maybe<Array<Maybe<FeedbackConfiguration>>>;
  FinancialGetStripeSetupIntent?: Maybe<StripeSetupIntent>;
  FinancialPrices?: Maybe<Array<Maybe<FinancialPrice>>>;
  FinancialPricesOneTime?: Maybe<Array<Maybe<FinancialPrice>>>;
  FinancialProducts?: Maybe<Array<Maybe<FinancialProduct>>>;
  FinancialPublishableKey?: Maybe<Scalars['String']['output']>;
  FinancialPublishableKeyV2?: Maybe<Scalars['String']['output']>;
  FinancialSyncStripeToUser?: Maybe<Scalars['Boolean']['output']>;
  FinancialUserInvoices?: Maybe<Array<Maybe<UserFinancialInvoice>>>;
  FinancialUserSources?: Maybe<Array<Maybe<UserFinancialPaymentSource>>>;
  FinancialUserSubscriptions?: Maybe<Array<Maybe<FinancialSubscriptions>>>;
  Greetings?: Maybe<Array<Maybe<Greeting>>>;
  LearningFeed?: Maybe<Array<Maybe<LearningArticle>>>;
  LearningFeedItem?: Maybe<LearningArticle>;
  Link?: Maybe<Scalars['String']['output']>;
  MarketingLead?: Maybe<MarketingLead>;
  MarketingLeadByClientId?: Maybe<MarketingLead>;
  MarketingLeadSearch?: Maybe<PaginatedMarketingLeadSearchResult>;
  MemberFlowStatus?: Maybe<Array<Maybe<UserFlowStatus>>>;
  MessageReactionByEmoji?: Maybe<Array<Maybe<MessageReactionByEmoji>>>;
  MessageThread?: Maybe<MessageThread>;
  MessageThreadMessages?: Maybe<Array<Maybe<Message>>>;
  MessageThreadSearch?: Maybe<PaginatedMessageThreadSearchResult>;
  MessageThreads?: Maybe<Array<Maybe<MessageThread>>>;
  MonthlyOverview?: Maybe<MonthlyOverviewData>;
  Ping?: Maybe<Pong>;
  Prescription?: Maybe<Prescription>;
  Recipes?: Maybe<Array<Maybe<RecipeItem>>>;
  RewardLeagueConfig?: Maybe<Array<Maybe<RewardLeagueLvlConfig>>>;
  RoleAppointmentStatisticsReport?: Maybe<Report>;
  SalesLead?: Maybe<SalesLead>;
  SalesLeadSearch?: Maybe<PaginatedSalesLeadSearchResult>;
  ScreeningCoupons?: Maybe<Array<Maybe<CouponDiscount>>>;
  SearchBookableAppointments?: Maybe<Array<Maybe<BookableAppointment>>>;
  SearchBookableAppointmentsV2?: Maybe<Array<Maybe<BookableAppointmentV2>>>;
  ShortLink?: Maybe<Scalars['String']['output']>;
  TagConfigurations?: Maybe<Array<Maybe<TagConfiguration>>>;
  TaskSearch?: Maybe<Array<Maybe<Task>>>;
  TaskSearchV2?: Maybe<PaginatedTaskSearchResult>;
  TrustPilotReviewSearch?: Maybe<TrustPilotReviewSearchResult>;
  UnreadMessageThreads: Array<MessageThread>;
  UnreadTasks?: Maybe<PaginatedTaskSearchResult>;
  User?: Maybe<User>;
  UserAchievements?: Maybe<Array<Maybe<Reward>>>;
  UserAchievementsStatus?: Maybe<AchievementsStatus>;
  UserAppointments?: Maybe<Array<Maybe<Appointment>>>;
  UserEmblaShareSeniority?: Maybe<EmblaShareUserSeniority>;
  UserFinancial?: Maybe<UserFinancial>;
  UserGetRefreshTokenByAccessToken?: Maybe<TokenSet>;
  UserGraphs?: Maybe<Array<Maybe<Graph>>>;
  UserGraphsV2?: Maybe<Array<Maybe<Graph>>>;
  UserImages?: Maybe<Array<Maybe<UserImage>>>;
  UserInfo?: Maybe<UserInfo>;
  UserMeasurements?: Maybe<Array<Maybe<Measurement>>>;
  UserMemberFocus?: Maybe<Array<Maybe<MemberFocus>>>;
  UserMyMessageThreads?: Maybe<Array<Maybe<MessageThread>>>;
  UserPotentialCalendars?: Maybe<Array<Maybe<Calendar>>>;
  UserPrescriptions?: Maybe<Array<Maybe<Prescription>>>;
  UserPrivacyCode: Scalars['String']['output'];
  UserProfileImage?: Maybe<UserImage>;
  UserRefreshAccessToken?: Maybe<TokenSet>;
  UserSearch?: Maybe<PaginatedUserSearchResult>;
  UserSearchV2?: Maybe<PaginatedUserSearchResult>;
  UserUTMReport?: Maybe<Report>;
  Users?: Maybe<Array<Maybe<User>>>;
  VideoAppointmentToken?: Maybe<Scalars['String']['output']>;
  WeightGoals?: Maybe<Array<Maybe<WeightGoal>>>;
  _Appointments?: Maybe<Array<Maybe<Appointment>>>;
  _AppointmentsV2?: Maybe<Array<Maybe<Appointment>>>;
  _Calendar?: Maybe<Calendar>;
  _CalendarAvailability?: Maybe<CalendarAvailability>;
  _Calendars?: Maybe<Array<Maybe<Calendar>>>;
  _ClientsMissingAppointment?: Maybe<Array<Maybe<ClientWithLatestAppointment>>>;
};

export type QueryAbsenceGetRegistrationPreviewArgs = {
  absenceId?: InputMaybe<Scalars['ID']['input']>;
  employeeId: Scalars['ID']['input'];
  enableSubstitution?: InputMaybe<Scalars['Boolean']['input']>;
  end: Scalars['Date']['input'];
  overrideSubstitutions?: InputMaybe<Scalars['Boolean']['input']>;
  start: Scalars['Date']['input'];
  substitutions?: InputMaybe<Array<InputMaybe<AbsenceRegistrationSubstitution>>>;
};

export type QueryAbuseReviewByTaskIdArgs = {
  taskId: Scalars['ID']['input'];
};

export type QueryAppointmentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryChurnReportArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type QueryClientArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryClientGameLeadershipBoardArgs = {
  date?: InputMaybe<Scalars['Date']['input']>;
  type?: InputMaybe<LeadershipboardType>;
};

export type QueryClientGameOverviewArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryClientSearchArgs = {
  pagination?: InputMaybe<UserPaginationArgs>;
  queryArgs?: InputMaybe<ClientSearchQueryArgs>;
};

export type QueryClientWeeklyReportArgs = {
  clientId: Scalars['ID']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryCommunityArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryCommunitySearchArgs = {
  pagination?: InputMaybe<CommunityPaginationArgs>;
  query?: InputMaybe<CommunitySearchQueryArgs>;
};

export type QueryDiscountCodeArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  priceId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryDropOffReportArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type QueryEmblaShareArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  priceId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEmblaShareAdminArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryEmployeeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEmployeeDescriptionsArgs = {
  employeeId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryEmployeeInfoArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEmployeePerformanceReportArgs = {
  employeeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryEmployeeSearchArgs = {
  deactivated?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderBy>;
  roles?: InputMaybe<Array<InputMaybe<Role>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type QueryEventLogEntriesArgs = {
  types?: InputMaybe<Array<InputMaybe<EventLogType>>>;
  userId: Scalars['ID']['input'];
};

export type QueryFeedbackConfigurationsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  targetType?: InputMaybe<FeedbackTarget>;
};

export type QueryFinancialPricesArgs = {
  checkEligibility?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleIso6391>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFinancialPricesOneTimeArgs = {
  checkEligibility?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<LocaleIso6391>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFinancialUserInvoicesArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryFinancialUserSourcesArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryFinancialUserSubscriptionsArgs = {
  includeScheduled?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGreetingsArgs = {
  category?: InputMaybe<GreetingCategory>;
};

export type QueryLearningFeedArgs = {
  language?: InputMaybe<SupportedLanguage>;
};

export type QueryLearningFeedItemArgs = {
  id: Scalars['ID']['input'];
  language?: InputMaybe<SupportedLanguage>;
};

export type QueryLinkArgs = {
  expireAt?: InputMaybe<Scalars['Date']['input']>;
  link: Scalars['String']['input'];
  ogTagsInput: OgTagsInput;
  targetId?: InputMaybe<Scalars['String']['input']>;
  targetType?: InputMaybe<Scalars['String']['input']>;
  utmHeaders?: InputMaybe<UtmHeaders>;
};

export type QueryMarketingLeadArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMarketingLeadByClientIdArgs = {
  clientId: Scalars['ID']['input'];
};

export type QueryMarketingLeadSearchArgs = {
  pagination?: InputMaybe<MarketingLeadPaginationArgs>;
  query?: InputMaybe<MarketingLeadQueryArgs>;
};

export type QueryMemberFlowStatusArgs = {
  coachId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryMessageReactionByEmojiArgs = {
  messageId: Scalars['ID']['input'];
};

export type QueryMessageThreadArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMessageThreadMessagesArgs = {
  beforeDate?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  threadId: Scalars['ID']['input'];
};

export type QueryMessageThreadSearchArgs = {
  careTeam?: InputMaybe<CareTeamInput>;
  catchAllStatus?: InputMaybe<Array<CatchAllStatus>>;
  coachId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  participantId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<MessageThreadType>;
};

export type QueryMessageThreadsArgs = {
  catchAllStatus?: InputMaybe<Array<CatchAllStatus>>;
  coachId?: InputMaybe<Scalars['ID']['input']>;
  participantId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryMonthlyOverviewArgs = {
  month?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryPrescriptionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRecipesArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<SupportedLanguage>;
  search?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryRewardLeagueConfigArgs = {
  localeISO6391?: InputMaybe<LocaleIso6391>;
};

export type QueryRoleAppointmentStatisticsReportArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type QuerySalesLeadArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySalesLeadSearchArgs = {
  followUpEndDate?: InputMaybe<Scalars['Date']['input']>;
  followUpStartDate?: InputMaybe<Scalars['Date']['input']>;
  lastScreeningEndDate?: InputMaybe<Scalars['Date']['input']>;
  lastScreeningStartDate?: InputMaybe<Scalars['Date']['input']>;
  lastUserResponseEndDate?: InputMaybe<Scalars['Date']['input']>;
  lastUserResponseStartDate?: InputMaybe<Scalars['Date']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SalesLeadOrderBy>;
  responsibleEmployeeId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
  status?: InputMaybe<Array<InputMaybe<SalesLeadStatus>>>;
  tags?: InputMaybe<Array<InputMaybe<SalesLeadTag>>>;
};

export type QueryScreeningCouponsArgs = {
  products: Array<InputMaybe<Product>>;
};

export type QuerySearchBookableAppointmentsArgs = {
  calendarId?: InputMaybe<Scalars['ID']['input']>;
  fromDate?: InputMaybe<Scalars['String']['input']>;
  showForAllUsers?: InputMaybe<Scalars['Boolean']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySearchBookableAppointmentsV2Args = {
  appointmentDuration?: InputMaybe<CoachingAppointmentDuration>;
  invitationId?: InputMaybe<Scalars['String']['input']>;
  movingAppointmentId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryShortLinkArgs = {
  expireAt?: InputMaybe<Scalars['Date']['input']>;
  link: Scalars['String']['input'];
};

export type QueryTagConfigurationsArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTaskSearchArgs = {
  assigneeUserId?: InputMaybe<Scalars['ID']['input']>;
  category?: InputMaybe<TaskCategory>;
  id?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<TaskStatus>;
  userGroup?: InputMaybe<UserGroups>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryTaskSearchV2Args = {
  assigneeUserId?: InputMaybe<Scalars['ID']['input']>;
  category?: InputMaybe<TaskCategory>;
  createdById?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TaskOrderBy>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
  status?: InputMaybe<TaskStatus>;
  userGroup?: InputMaybe<UserGroups>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryTrustPilotReviewSearchArgs = {
  pagination?: InputMaybe<TrustPilotReviewPaginationArgs>;
  query?: InputMaybe<TrustPilotReviewQueryArgs>;
};

export type QueryUnreadMessageThreadsArgs = {
  selectedThreadTypes?: InputMaybe<Array<InputMaybe<MessageThreadType>>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUnreadTasksArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TaskOrderBy>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserAchievementsArgs = {
  localeISO6391: LocaleIso6391;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserAchievementsStatusArgs = {
  localeISO6391?: InputMaybe<LocaleIso6391>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserAppointmentsArgs = {
  status?: InputMaybe<Array<InputMaybe<AppointmentStatus>>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserFinancialArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserGetRefreshTokenByAccessTokenArgs = {
  accessToken: Scalars['String']['input'];
  accessTokenKey: Scalars['String']['input'];
  timestampIsoString: Scalars['String']['input'];
};

export type QueryUserGraphsArgs = {
  graphType?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserGraphsV2Args = {
  graphType?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserImagesArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserInfoArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserMeasurementsArgs = {
  date?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  subType?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserMemberFocusArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserPotentialCalendarsArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserPrescriptionsArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryUserProfileImageArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserRefreshAccessTokenArgs = {
  refreshToken: Scalars['String']['input'];
};

export type QueryUserSearchArgs = {
  careTeam?: InputMaybe<CareTeamInput>;
  coachId?: InputMaybe<Scalars['ID']['input']>;
  isEmbla?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderBy>;
  pagination?: InputMaybe<UserPaginationArgs>;
  query?: InputMaybe<UserSearchQueryArgs>;
  role?: InputMaybe<Role>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
  status?: InputMaybe<UserStatus>;
  tag?: InputMaybe<UserTags>;
};

export type QueryUserSearchV2Args = {
  careTeam?: InputMaybe<CareTeamInput>;
  coachId?: InputMaybe<Scalars['ID']['input']>;
  isEmbla?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderBy>;
  pagination?: InputMaybe<UserPaginationArgs>;
  query?: InputMaybe<UserSearchQueryArgs>;
  role?: InputMaybe<Role>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
  status?: InputMaybe<UserStatus>;
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserUtmReportArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type QueryUsersArgs = {
  role?: InputMaybe<Scalars['String']['input']>;
};

export type QueryVideoAppointmentTokenArgs = {
  appointmentId: Scalars['ID']['input'];
  notifyOtherParticipant?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryWeightGoalsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
};

export type Query_AppointmentsArgs = {
  calendarId?: InputMaybe<Scalars['ID']['input']>;
  fromDate?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
  usersId?: InputMaybe<Scalars['ID']['input']>;
};

export type Query_AppointmentsV2Args = {
  employeeId?: InputMaybe<Scalars['ID']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};

export type Query_CalendarArgs = {
  calendarId?: InputMaybe<Scalars['ID']['input']>;
  dateFrom?: InputMaybe<Scalars['Date']['input']>;
  dateTo?: InputMaybe<Scalars['Date']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type Query_CalendarAvailabilityArgs = {
  id: Scalars['ID']['input'];
};

export type Query_CalendarsArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type Reaction = {
  __typename?: 'Reaction';
  alias?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type RecipeIngredient = {
  __typename?: 'RecipeIngredient';
  amount?: Maybe<Scalars['Float']['output']>;
  amountUnit?: Maybe<Scalars['String']['output']>;
  ingredient?: Maybe<Scalars['String']['output']>;
};

export type RecipeItem = {
  __typename?: 'RecipeItem';
  category?: Maybe<Scalars['String']['output']>;
  courseOfAction?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  ingredients?: Maybe<Array<Maybe<RecipeIngredient>>>;
  name?: Maybe<Scalars['String']['output']>;
  pictures?: Maybe<Array<Maybe<RecipePicture>>>;
  publishedAt?: Maybe<Scalars['String']['output']>;
  recipeVolume?: Maybe<Scalars['Int']['output']>;
  recipeVolumeUnit?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  totalDuration_minutes?: Maybe<Scalars['Int']['output']>;
  userHasFavorit?: Maybe<Scalars['Boolean']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
  workTime_minutes?: Maybe<Scalars['Int']['output']>;
};

export type RecipePicture = {
  __typename?: 'RecipePicture';
  id?: Maybe<Scalars['ID']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum RefundStatus {
  Canceled = 'canceled',
  Failed = 'failed',
  Succeeded = 'succeeded',
}

export type RegisterAbsencePayload = {
  description?: InputMaybe<Scalars['String']['input']>;
  enableSubstitution?: InputMaybe<Scalars['Boolean']['input']>;
  end: Scalars['Date']['input'];
  excludedSubstituteServices?: InputMaybe<Array<InputMaybe<CareTeamService>>>;
  icon?: InputMaybe<Scalars['String']['input']>;
  muteNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  start: Scalars['Date']['input'];
  substitutions?: InputMaybe<Array<InputMaybe<AbsenceRegistrationSubstitution>>>;
  userId: Scalars['ID']['input'];
};

export type Relationship = {
  __typename?: 'Relationship';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<RelationshipStatus>;
  targetUser?: Maybe<User>;
  targetUserId?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<RelationshipType>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export enum RelationshipStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Deleted = 'DELETED',
  Pending = 'PENDING',
}

export enum RelationshipType {
  Friend = 'FRIEND',
}

export type Reminder = {
  __typename?: 'Reminder';
  active: Scalars['Boolean']['output'];
  client?: Maybe<Client>;
  clientId: Scalars['String']['output'];
  hour: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isoDays: Array<Maybe<Scalars['Int']['output']>>;
  minute: Scalars['Int']['output'];
};

export type Report = {
  __typename?: 'Report';
  columns: Array<Scalars['String']['output']>;
  reportType: ReportType;
  rows: Array<Array<Scalars['String']['output']>>;
};

export enum ReportType {
  AppointmentStatistics = 'APPOINTMENT_STATISTICS',
  Churn = 'CHURN',
  DropOff = 'DROP_OFF',
  UtmReport = 'UTM_REPORT',
}

export type Reward = {
  __typename?: 'Reward';
  claimText?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  illustrationURL?: Maybe<Scalars['String']['output']>;
  needSubscription?: Maybe<Scalars['Boolean']['output']>;
  onMedication?: Maybe<Scalars['Boolean']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
  requirements?: Maybe<Array<Maybe<RewardRequirement>>>;
  rewardGroupID?: Maybe<Scalars['ID']['output']>;
  rewardGroupLvl?: Maybe<Scalars['Int']['output']>;
  rewardGroupTitle?: Maybe<Scalars['String']['output']>;
  rewardLvl?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  unlockedByTime?: Maybe<Scalars['Boolean']['output']>;
  userProgress?: Maybe<RewardUserProgress>;
};

export type RewardLeagueLvlConfig = {
  __typename?: 'RewardLeagueLvlConfig';
  lvl: Scalars['Int']['output'];
  lvlGreeting: Scalars['String']['output'];
  lvlTitle: Scalars['String']['output'];
  minScore: Scalars['Int']['output'];
};

export type RewardRequirement = {
  __typename?: 'RewardRequirement';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  targetDateRange?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  targetValue?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<RewardRequirementType>;
};

export enum RewardRequirementType {
  CountAllMeasurements = 'COUNT_ALL_MEASUREMENTS',
  CountAppointmentsCompleted = 'COUNT_APPOINTMENTS_COMPLETED',
  CountCommunityHearts = 'COUNT_COMMUNITY_HEARTS',
  CountCommunityLikes = 'COUNT_COMMUNITY_LIKES',
  CountFoodMeasurements = 'COUNT_FOOD_MEASUREMENTS',
  CountReadingLearningItems = 'COUNT_READING_LEARNING_ITEMS',
  CountStatusMeasurements = 'COUNT_STATUS_MEASUREMENTS',
  PctWeightLoss = 'PCT_WEIGHT_LOSS',
  RegisterMedication = 'REGISTER_MEDICATION',
  RegisterWeight = 'REGISTER_WEIGHT',
  StreakDailyChecklist = 'STREAK_DAILY_CHECKLIST',
  TotalKmBiking = 'TOTAL_KM_BIKING',
  TotalKmRunning = 'TOTAL_KM_RUNNING',
  TotalKmWalking = 'TOTAL_KM_WALKING',
  TotalMembershipDays = 'TOTAL_MEMBERSHIP_DAYS',
  TotalMinAllActivity = 'TOTAL_MIN_ALL_ACTIVITY',
  TotalMinBiking = 'TOTAL_MIN_BIKING',
  TotalMinRunning = 'TOTAL_MIN_RUNNING',
  TotalMinWalking = 'TOTAL_MIN_WALKING',
}

export type RewardUserProgress = {
  __typename?: 'RewardUserProgress';
  canClaim?: Maybe<Scalars['Boolean']['output']>;
  hasClaimed?: Maybe<Scalars['Boolean']['output']>;
  hasViewed?: Maybe<Scalars['Boolean']['output']>;
  progressPct?: Maybe<Scalars['Int']['output']>;
  requirements?: Maybe<Array<Maybe<RewardUserProgressRequirement>>>;
  unlocked?: Maybe<Scalars['Boolean']['output']>;
  unlockedAt?: Maybe<Scalars['Date']['output']>;
};

export type RewardUserProgressRequirement = {
  __typename?: 'RewardUserProgressRequirement';
  completed?: Maybe<Scalars['Boolean']['output']>;
  progress?: Maybe<Scalars['Int']['output']>;
  progressPct?: Maybe<Scalars['Int']['output']>;
  requirementID?: Maybe<Scalars['ID']['output']>;
  targetDateRange?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  targetValue?: Maybe<Scalars['Int']['output']>;
};

export enum Role {
  Administrator = 'ADMINISTRATOR',
  Client = 'CLIENT',
  Coach = 'COACH',
  Doctor = 'DOCTOR',
  Employee = 'EMPLOYEE',
  Nurse = 'NURSE',
  Psychologist = 'PSYCHOLOGIST',
  Robot = 'ROBOT',
  Temporary = 'TEMPORARY',
  Unknown = 'UNKNOWN',
}

export type SalesLead = {
  __typename?: 'SalesLead';
  client: Client;
  createdDate: Scalars['Date']['output'];
  followup?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  lastScreeningDate?: Maybe<Scalars['Date']['output']>;
  lastUserResponseDate?: Maybe<Scalars['Date']['output']>;
  leadScoring?: Maybe<LeadScoring>;
  lostLeadReason?: Maybe<SalesLeadLostReason>;
  name: Scalars['String']['output'];
  referrerSource?: Maybe<SalesLeadReferrerSource>;
  responsibleEmployee: Employee;
  status: SalesLeadStatus;
  tags: Array<SalesLeadTag>;
};

export enum SalesLeadLostReason {
  BmiBelow_30NotInterestedInCoaching = 'BMI_BELOW_30_NOT_INTERESTED_IN_COACHING',
  ChoseCompetitor = 'CHOSE_COMPETITOR',
  Other = 'OTHER',
  TooExpensive = 'TOO_EXPENSIVE',
}

export enum SalesLeadOrderBy {
  Followup = 'followup',
  LastClinicalScreeningDate = 'lastClinicalScreeningDate',
  LastUserResponseDate = 'lastUserResponseDate',
  Status = 'status',
}

export enum SalesLeadReferrerSource {
  EmailCampaign = 'EMAIL_CAMPAIGN',
  FacebookAds = 'FACEBOOK_ADS',
  FacebookGroups = 'FACEBOOK_GROUPS',
  FriendFamily = 'FRIEND_FAMILY',
  GoogleAds = 'GOOGLE_ADS',
  HealthcareProfessional = 'HEALTHCARE_PROFESSIONAL',
  Instagram = 'INSTAGRAM',
  NewspaperMagazine = 'NEWSPAPER_MAGAZINE',
  Other = 'OTHER',
  PartnerExseed = 'PARTNER_EXSEED',
  PartnerGp = 'PARTNER_GP',
  RadioPodcast = 'RADIO_PODCAST',
  SearchEngines = 'SEARCH_ENGINES',
  SocialMediaOther = 'SOCIAL_MEDIA_OTHER',
  WordOfMouth = 'WORD_OF_MOUTH',
}

export enum SalesLeadStatus {
  ClinicalScreeningApproved = 'CLINICAL_SCREENING_APPROVED',
  ClinicalScreeningDeclined = 'CLINICAL_SCREENING_DECLINED',
  ClinicalScreeningPending = 'CLINICAL_SCREENING_PENDING',
  HasBookedScreening = 'HAS_BOOKED_SCREENING',
  LostLead = 'LOST_LEAD',
  NewLead = 'NEW_LEAD',
  NewLeadBookedScreening = 'NEW_LEAD_BOOKED_SCREENING',
  PayingCustomer = 'PAYING_CUSTOMER',
  UserHasSetupSubscription = 'USER_HAS_SETUP_SUBSCRIPTION',
  UserNeedsScreening = 'USER_NEEDS_SCREENING',
}

export enum SalesLeadTag {
  AwaitingApprovalFromDoctor = 'AWAITING_APPROVAL_FROM_DOCTOR',
  AwaitingBloodTest = 'AWAITING_BLOOD_TEST',
  Cancelled = 'CANCELLED',
  HadMeetingButNoScreening = 'HAD_MEETING_BUT_NO_SCREENING',
  Interested = 'INTERESTED',
  NeedsTimeToThink = 'NEEDS_TIME_TO_THINK',
  NoShow = 'NO_SHOW',
  SubscribedViaEcom = 'SUBSCRIBED_VIA_ECOM',
}

export type SelfScreeningResult = {
  __typename?: 'SelfScreeningResult';
  bmi?: Maybe<Scalars['String']['output']>;
  bmiResult?: Maybe<BmiResult>;
  eligibilityResult: EligibilityResult;
  eligibleFor: Array<Maybe<FinancialPrice>>;
  reasons?: Maybe<Array<Maybe<NonEligibleReason>>>;
};

export enum ServiceType {
  Coaching = 'COACHING',
  Nursing = 'NURSING',
  Psychology = 'PSYCHOLOGY',
}

export enum SexAssignedAtBirth {
  Female = 'FEMALE',
  Male = 'MALE',
  PreferNotToSay = 'PREFER_NOT_TO_SAY',
}

export type SignInFlowOverrideData = {
  countryISO3166: CountryIso3166;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  localeISO6391?: InputMaybe<LocaleIso6391>;
  metaData?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
};

export type SignupFunnelFinalizeV3Input = {
  address?: InputMaybe<AddressInput>;
  comorbidity?: InputMaybe<ComorbidityAnswersInput>;
  eligibility?: InputMaybe<EligibilityAnswersInput>;
  emailMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  emblaShareCode?: InputMaybe<Scalars['String']['input']>;
  feelingAboutWeightLoss?: InputMaybe<FeelingAboutWeightLoss>;
  glp1?: InputMaybe<Scalars['Boolean']['input']>;
  heardAboutEmbla?: InputMaybe<HeardAboutEmbla>;
  height_weight?: InputMaybe<HeightWeightInput>;
  motivations?: InputMaybe<MotivationAnswersInput>;
  notEligibleReason?: InputMaybe<Scalars['String']['input']>;
  pastWeightLossSolutions?: InputMaybe<WeightLossSolutionsInput>;
  sexAssignedAtBirth?: InputMaybe<SexAssignedAtBirth>;
  signupCurrentMedication?: InputMaybe<CurrentMedicationAnswersInput>;
  signupMedicationWanted?: InputMaybe<MedicationWantedAnswers>;
  signupPregnantOrBreastfeeding?: InputMaybe<Scalars['Boolean']['input']>;
  smsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  subscriptionStarted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SignupFunnelLoginResult = {
  __typename?: 'SignupFunnelLoginResult';
  firstName?: Maybe<Scalars['String']['output']>;
  step?: Maybe<SignupFunnelStep>;
  token: Scalars['ID']['output'];
};

export enum SignupFunnelStep {
  Booking = 'booking',
  Eli = 'eli',
}

export enum SignupStatus {
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  InProgress = 'IN_PROGRESS',
  NotEligible = 'NOT_ELIGIBLE',
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  clientSecret: Scalars['String']['output'];
  requiresAction: Scalars['Boolean']['output'];
};

export type StripeSetupIntent = {
  __typename?: 'StripeSetupIntent';
  clientSecret: Scalars['String']['output'];
};

export type SubmitFeedbackData = {
  answer?: InputMaybe<Scalars['Boolean']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  contactable?: InputMaybe<Scalars['Boolean']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  selection?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  targetId?: InputMaybe<Scalars['ID']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  UserEmblaBot?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionUserEmblaBotArgs = {
  txt?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EmblaBotType>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export enum SubscriptionChangeType {
  EndOfBillingPeriod = 'END_OF_BILLING_PERIOD',
  ImmediatelyWithoutProrate = 'IMMEDIATELY_WITHOUT_PRORATE',
  ImmediatelyWithProrate = 'IMMEDIATELY_WITH_PRORATE',
}

export enum SubscriptionStartStatus {
  ActionRequired = 'ActionRequired',
  InvalidStripeId = 'InvalidStripeId',
  Ok = 'OK',
  PaymentMethodRequired = 'PaymentMethodRequired',
}

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Paused = 'paused',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
}

export enum SubscriptionStopType {
  EndOfBillingPeriod = 'END_OF_BILLING_PERIOD',
  Immediately = 'IMMEDIATELY',
  ImmediatelyWithRefund = 'IMMEDIATELY_WITH_REFUND',
}

export enum SupportedLanguage {
  Da = 'da',
  En = 'en',
}

export type SyncMeasurementEventInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  durationMins?: InputMaybe<Scalars['Int']['input']>;
  externalId: Scalars['String']['input'];
  foodEvent?: InputMaybe<FoodEventInput>;
  sourceId?: InputMaybe<Scalars['String']['input']>;
  subType?: InputMaybe<Scalars['String']['input']>;
  timestamp: Scalars['Date']['input'];
  type: MeasurementType;
  value: Scalars['String']['input'];
};

export enum SystemUserTag {
  BannedFromCommunities = 'BANNED_FROM_COMMUNITIES',
  BlockedFromBooking = 'BLOCKED_FROM_BOOKING',
  HasScheduledSubscriptionStart = 'HAS_SCHEDULED_SUBSCRIPTION_START',
  MedicationPrescriptionMissing = 'MEDICATION_PRESCRIPTION_MISSING',
  MedicationPrescriptionStockLow = 'MEDICATION_PRESCRIPTION_STOCK_LOW',
  MissingCoach = 'MISSING_COACH',
  PastDueInvoice = 'PAST_DUE_INVOICE',
  PreviousCustomer = 'PREVIOUS_CUSTOMER',
  ProfessionalUserPremiumRejected = 'PROFESSIONAL_USER_PREMIUM_REJECTED',
  PublicUserFreemiumOnly = 'PUBLIC_USER_FREEMIUM_ONLY',
  SubscriptionIncompleteExpired = 'SUBSCRIPTION_INCOMPLETE_EXPIRED',
  SubscriptionWillCancel = 'SUBSCRIPTION_WILL_CANCEL',
  SubscriptionWillPause = 'SUBSCRIPTION_WILL_PAUSE',
  UnpaidInvoice = 'UNPAID_INVOICE',
  WorkNewMember = 'WORK_NEW_MEMBER',
}

export enum TagColor {
  Variant_01 = 'VARIANT_01',
  Variant_02 = 'VARIANT_02',
  Variant_03 = 'VARIANT_03',
  Variant_04 = 'VARIANT_04',
  Variant_05 = 'VARIANT_05',
}

export type TagConfiguration = {
  __typename?: 'TagConfiguration';
  color?: Maybe<TagColor>;
  localizations: Array<Maybe<TagConfigurationLocalization>>;
  manuallyAssigned?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  system?: Maybe<Scalars['Boolean']['output']>;
  tag: Scalars['String']['output'];
};

export type TagConfigurationInput = {
  color?: InputMaybe<TagColor>;
  manuallyAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  system?: InputMaybe<Scalars['Boolean']['input']>;
  tag: Scalars['String']['input'];
};

export type TagConfigurationLocalization = {
  __typename?: 'TagConfigurationLocalization';
  description: Scalars['String']['output'];
  locale: LocaleIso6391;
  name: Scalars['String']['output'];
};

export type TagConfigurationLocalizationInput = {
  description: Scalars['String']['input'];
  locale: LocaleIso6391;
  name: Scalars['String']['input'];
};

export type Task = {
  __typename?: 'Task';
  assigneeGroup?: Maybe<Scalars['String']['output']>;
  assigneeUser?: Maybe<User>;
  category?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isRead?: Maybe<Scalars['Boolean']['output']>;
  isViewed?: Maybe<Scalars['Boolean']['output']>;
  messageThread?: Maybe<MessageThread>;
  status?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export enum TaskCategory {
  AbuseReview = 'ABUSE_REVIEW',
  BloodTest = 'BLOOD_TEST',
  CustomerSuccess = 'CUSTOMER_SUCCESS',
  Financial = 'FINANCIAL',
  FollowUp = 'FOLLOW_UP',
  MedicationAdjustment = 'MEDICATION_ADJUSTMENT',
  Prescription = 'PRESCRIPTION',
  SideEffects = 'SIDE_EFFECTS',
  TechSupport = 'TECH_SUPPORT',
}

export type TaskInput = {
  assigneeGroup?: InputMaybe<UserGroups>;
  assigneeUserId?: InputMaybe<Scalars['ID']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  isViewed?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export enum TaskOrderBy {
  CreatedAt = 'createdAt',
  DueDate = 'dueDate',
  Subject = 'subject',
}

export enum TaskStatus {
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Open = 'OPEN',
  Resolved = 'RESOLVED',
}

export type TodaysSchedule = {
  __typename?: 'TodaysSchedule';
  calendar?: Maybe<Calendar>;
  calendarId?: Maybe<Scalars['String']['output']>;
  nAppointments?: Maybe<Scalars['Int']['output']>;
  nAvbSlots?: Maybe<Scalars['Int']['output']>;
};

export type Token = {
  __typename?: 'Token';
  auth0Sub?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailNotVerified?: Maybe<Scalars['Boolean']['output']>;
  refreshToken: Scalars['String']['output'];
  role?: Maybe<Scalars['String']['output']>;
  token: Scalars['ID']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type TokenSet = {
  __typename?: 'TokenSet';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export enum TrackingEventName {
  AppAuthenticated = 'app_authenticated',
  AppLoginScreenOpened = 'app_login_screen_opened',
  AppOpened = 'app_opened',
  AppointmentAttended = 'appointment_attended',
  AppointmentDurationChanged = 'appointment_duration_changed',
  ArticleRead = 'article_read',
  ExperimentStarted = 'experiment_started',
  FeedbackSkipped = 'feedback_skipped',
  FeedbackViewed = 'feedback_viewed',
  HealthDataSyncInitiatedByUser = 'health_data_sync_initiated_by_user',
  HealthIntegrationActivated = 'health_integration_activated',
  HealthIntegrationDeactivated = 'health_integration_deactivated',
  HealthPermissionsDialogDismissed = 'health_permissions_dialog_dismissed',
  HealthPermissionsDialogShown = 'health_permissions_dialog_shown',
  HealthPermissionsInitialPromptBySystem = 'health_permissions_initial_prompt_by_system',
  HealthPermissionsPromptedFromSettings = 'health_permissions_prompted_from_settings',
  InvitationClicked = 'invitation_clicked',
  InvitationDisplayed = 'invitation_displayed',
  JourneyItemActionClicked = 'journey_item_action_clicked',
  JourneyItemDescriptionViewed = 'journey_item_description_viewed',
  LearningShareCompleted = 'learning_share_completed',
  LearningShareFailed = 'learning_share_failed',
  LearningShareStarted = 'learning_share_started',
  MeasurementFoodImageAdded = 'measurement_food_image_added',
  MeasurementFoodImageRemoved = 'measurement_food_image_removed',
  MeasurementLiquidImageAdded = 'measurement_liquid_image_added',
  MeasurementLiquidImageRemoved = 'measurement_liquid_image_removed',
  MemberFocusRead = 'member_focus_read',
  NotificationOpened = 'notification_opened',
  NotificationPermissionPrompted = 'notification_permission_prompted',
  PaymentMethodSelected = 'payment_method_selected',
  ProfileImageAvatarSaved = 'profile_image_avatar_saved',
  ProfileImageSelectAvatarClicked = 'profile_image_select_avatar_clicked',
  RecipeRead = 'recipe_read',
  ReferralCopyButtonClicked = 'referral_copy_button_clicked',
  ReferralShareButtonClicked = 'referral_share_button_clicked',
  RewardAchievementShareCompleted = 'reward_achievement_share_completed',
  RewardAchievementShareFailed = 'reward_achievement_share_failed',
  RewardAchievementShareStarted = 'reward_achievement_share_started',
  RewardAchievementShared = 'reward_achievement_shared',
  RewardRankLevelledUp = 'reward_rank_levelled_up',
  RewardViewedClaimed = 'reward_viewed_claimed',
  SignupGlp1Answered = 'signup_GLP1_answered',
  SignupAddToCalendarClicked = 'signup_add_to_calendar_clicked',
  SignupBookingcodeAsked = 'signup_bookingcode_asked',
  SignupCheckoutCompleted = 'signup_checkout_completed',
  SignupCheckoutStarted = 'signup_checkout_started',
  SignupComorbidityQuestionsAnswered = 'signup_comorbidity_questions_answered',
  SignupConsultationConfirmed = 'signup_consultation_confirmed',
  SignupDownloadAppClicked = 'signup_download_app_clicked',
  SignupEligibilityQuestionsAnswered = 'signup_eligibility_questions_answered',
  SignupHeardAboutAnswered = 'signup_heard_about_answered',
  SignupHeightWeightEntered = 'signup_height_weight_entered',
  SignupMedicationWantedAnswered = 'signup_medication_wanted_answered',
  SignupMedicationsAnswered = 'signup_medications_answered',
  SignupMotivationsEntered = 'signup_motivations_entered',
  SignupOfferExplained = 'signup_offer_explained',
  SignupOfferSelected = 'signup_offer_selected',
  SignupPageEnter = 'signup_page_enter',
  SignupPageExit = 'signup_page_exit',
  SignupPastSolutionsEntered = 'signup_past_solutions_entered',
  SignupPregnancyAnswered = 'signup_pregnancy_answered',
  SignupProgramBehaviourPresented = 'signup_program_behaviour_presented',
  SignupProgramMotivationPresented = 'signup_program_motivation_presented',
  SignupProgramNodietPresented = 'signup_program_nodiet_presented',
  SignupProgramRootcausePresented = 'signup_program_rootcause_presented',
  SignupProgramWeightlossPresented = 'signup_program_weightloss_presented',
  SignupPurchaseConfirmed = 'signup_purchase_confirmed',
  SignupRegistered = 'signup_registered',
  SignupScreeningCompleted = 'signup_screening_completed',
  SignupSexAssignedAnswered = 'signup_sex_assigned_answered',
  SignupStarted = 'signup_started',
  SignupTimeslotPicked = 'signup_timeslot_picked',
  SignupTimeslotPresented = 'signup_timeslot_presented',
  SignupUnitSystemPicked = 'signup_unit_system_picked',
  SignupWatchlistConfirmed = 'signup_watchlist_confirmed',
  SignupWeightlossFeelingsEntered = 'signup_weightloss_feelings_entered',
  SignupWeightlossPredicted = 'signup_weightloss_predicted',
  TrustpilotReviewlinkCopied = 'trustpilot_reviewlink_copied',
  UserConsentDeclined = 'user_consent_declined',
  UserConsentPrompted = 'user_consent_prompted',
  VideoPortalLobbyEntered = 'video_portal_lobby_entered',
  VideoPortalRoomEntered = 'video_portal_room_entered',
  VideoPortalThankyouEntered = 'video_portal_thankyou_entered',
  VideocallAutoJoined = 'videocall_auto_joined',
  WrapUpCompleted = 'wrap_up_completed',
  WrapUpLinkOpened = 'wrap_up_link_opened',
  WrapUpShared = 'wrap_up_shared',
  WrapUpShowed = 'wrap_up_showed',
}

export type TrustPilotReview = {
  __typename?: 'TrustPilotReview';
  client?: Maybe<Client>;
  createdAt: Scalars['Date']['output'];
  deleted?: Maybe<Scalars['Boolean']['output']>;
  displayLocation?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  employee?: Maybe<Employee>;
  id: Scalars['ID']['output'];
  reviewId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export enum TrustPilotReviewOrderBy {
  ClientName = 'clientName',
  CreatedAt = 'createdAt',
  DisplayName = 'displayName',
  EmployeeName = 'employeeName',
  Trigger = 'trigger',
  UpdatedAt = 'updatedAt',
}

export type TrustPilotReviewPaginationArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TrustPilotReviewOrderBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type TrustPilotReviewQueryArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  employeeId?: InputMaybe<Scalars['ID']['input']>;
};

export type TrustPilotReviewSearchResult = {
  __typename?: 'TrustPilotReviewSearchResult';
  results: Array<TrustPilotReview>;
  total: Scalars['Int']['output'];
};

export enum UnitSystem {
  Imperial = 'imperial',
  Metric = 'metric',
}

export type UpdateAbsencePayload = {
  description?: InputMaybe<Scalars['String']['input']>;
  enableSubstitution?: InputMaybe<Scalars['Boolean']['input']>;
  end?: InputMaybe<Scalars['Date']['input']>;
  excludedSubstituteServices?: InputMaybe<Array<InputMaybe<CareTeamService>>>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  muteNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  overrideSubstitutions?: InputMaybe<Scalars['Boolean']['input']>;
  start?: InputMaybe<Scalars['Date']['input']>;
  substitutions?: InputMaybe<Array<InputMaybe<AbsenceRegistrationSubstitution>>>;
};

export type UpdateReminderPayload = {
  hour: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
  isoDays: Array<InputMaybe<Scalars['Int']['input']>>;
  minute: Scalars['Int']['input'];
};

export type User = {
  __typename?: 'User';
  CPRNumber?: Maybe<Scalars['String']['output']>;
  absence?: Maybe<Array<Maybe<Absence>>>;
  activeScreening?: Maybe<Appointment>;
  address?: Maybe<Address>;
  alias?: Maybe<Scalars['String']['output']>;
  availableLoginTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  bio?: Maybe<Scalars['String']['output']>;
  bmi?: Maybe<Scalars['String']['output']>;
  calendarId?: Maybe<Scalars['String']['output']>;
  calendarToken?: Maybe<Scalars['String']['output']>;
  careTeamMembers?: Maybe<Array<Maybe<CareTeamMember>>>;
  careTeamMemberships?: Maybe<Array<Maybe<CareTeamMember>>>;
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['ID']['output']>;
  clinicalScreeningLatest?: Maybe<ClinicalScreening>;
  coach?: Maybe<User>;
  coachCalendarId?: Maybe<Scalars['String']['output']>;
  coachId?: Maybe<Scalars['String']['output']>;
  coachTeamMessageThreadId?: Maybe<Scalars['String']['output']>;
  consentedCommunityTermsVersion?: Maybe<Scalars['Int']['output']>;
  consentedLogbookVersion?: Maybe<Scalars['Int']['output']>;
  consentedPrivacyVersion?: Maybe<Scalars['Int']['output']>;
  consentedTermsVersion?: Maybe<Scalars['Int']['output']>;
  countryISO3166?: Maybe<CountryIso3166>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  emblaShareCode?: Maybe<Scalars['String']['output']>;
  feedbackConfigurations?: Maybe<Array<Maybe<UserFeedbackConfiguration>>>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasLoggedIn?: Maybe<Scalars['Boolean']['output']>;
  heightCm?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  incomingRelationships?: Maybe<Array<Maybe<Relationship>>>;
  kyc?: Maybe<UserVerification>;
  lastLogin?: Maybe<Scalars['Date']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastWeekMeasurementCount?: Maybe<Scalars['Int']['output']>;
  /** @deprecated No longer supported */
  learningCompletions?: Maybe<Array<Maybe<LearningCompletion>>>;
  locale?: Maybe<LocaleIso6391>;
  loginType?: Maybe<Scalars['String']['output']>;
  measurements?: Maybe<Array<Measurement>>;
  measurementsV2?: Maybe<Array<Measurement>>;
  metaData?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<UserNote>>;
  personalCode?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneVerified?: Maybe<Scalars['Boolean']['output']>;
  primaryCareTeam?: Maybe<Array<Maybe<PrimaryCareTeamMember>>>;
  profileAvatarConfiguration?: Maybe<Scalars['JSON']['output']>;
  profilePictureImageId?: Maybe<Scalars['String']['output']>;
  profilePictureImageUrl?: Maybe<Scalars['String']['output']>;
  relationships?: Maybe<Array<Maybe<Relationship>>>;
  role?: Maybe<Scalars['String']['output']>;
  screeningForm?: Maybe<Scalars['Boolean']['output']>;
  screeningResults?: Maybe<Scalars['String']['output']>;
  selfScreeningResult?: Maybe<SelfScreeningResult>;
  sexAssignedAtBirth?: Maybe<SexAssignedAtBirth>;
  status?: Maybe<Scalars['String']['output']>;
  statusActionsAndFeatures?: Maybe<UserStatusActionsAndFeatures>;
  uiSettings?: Maybe<Scalars['String']['output']>;
  unitSystem?: Maybe<UnitSystem>;
  unreadCommunityMessageThreadsCount?: Maybe<Scalars['Int']['output']>;
  unreadMessageThreadsCount?: Maybe<Scalars['Int']['output']>;
  unviewedTaskCount?: Maybe<Scalars['Int']['output']>;
  videoConsultationUrl?: Maybe<Scalars['String']['output']>;
  weightKg?: Maybe<Scalars['String']['output']>;
};

export type UserMeasurementsArgs = {
  after?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['Date']['input']>;
};

export type UserMeasurementsV2Args = {
  after?: InputMaybe<Scalars['Date']['input']>;
  before?: InputMaybe<Scalars['Date']['input']>;
  includeAllWeightAndBodyMeasurements?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserNotesArgs = {
  before?: InputMaybe<Scalars['Date']['input']>;
  max?: InputMaybe<Scalars['Int']['input']>;
};

export type UserAssignedService = {
  __typename?: 'UserAssignedService';
  employee?: Maybe<User>;
  employeeUserId?: Maybe<Scalars['ID']['output']>;
  endAt: Scalars['Date']['output'];
  maxBookings?: Maybe<Scalars['Int']['output']>;
  serviceType: ServiceType;
  startAt: Scalars['Date']['output'];
  user?: Maybe<User>;
  userId: Scalars['ID']['output'];
};

export type UserAssignedServiceInput = {
  employeeUserId?: InputMaybe<Scalars['ID']['input']>;
  endAt: Scalars['Date']['input'];
  maxBookings?: InputMaybe<Scalars['Int']['input']>;
  serviceType: ServiceType;
  startAt?: InputMaybe<Scalars['Date']['input']>;
  userId: Scalars['ID']['input'];
};

export type UserDeleteResponse = {
  __typename?: 'UserDeleteResponse';
  deleted?: Maybe<Scalars['Boolean']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export enum UserFeature {
  AppointmentBook = 'APPOINTMENT_BOOK',
  AppointmentView = 'APPOINTMENT_VIEW',
  ArticlesList = 'ARTICLES_LIST',
  ArticlesReadOpen = 'ARTICLES_READ_OPEN',
  ArticlesReadPaid = 'ARTICLES_READ_PAID',
  ClinicalScreeningResultAdd = 'CLINICAL_SCREENING_RESULT_ADD',
  CoachAdd = 'COACH_ADD',
  Eligible = 'ELIGIBLE',
  EmblaShare = 'EMBLA_SHARE',
  EmblaShareRestricted = 'EMBLA_SHARE_RESTRICTED',
  HasLoggedInOnApp = 'HAS_LOGGED_IN_ON_APP',
  MessagethreadView = 'MESSAGETHREAD_VIEW',
  NewlyEarlyIncompleteSignupFunnel = 'NEWLY_EARLY_INCOMPLETE_SIGNUP_FUNNEL',
  NewlyIncompleteSignupFunnel = 'NEWLY_INCOMPLETE_SIGNUP_FUNNEL',
  ProfilePaymentAndBillingView = 'PROFILE_PAYMENT_AND_BILLING_VIEW',
  Reminders = 'REMINDERS',
  SubscriptionCancel = 'SUBSCRIPTION_CANCEL',
  SubscriptionUpdate = 'SUBSCRIPTION_UPDATE',
  UsersList = 'USERS_LIST',
  UserDelete = 'USER_DELETE',
}

export type UserFeedbackConfiguration = {
  __typename?: 'UserFeedbackConfiguration';
  anonymous: Scalars['Boolean']['output'];
  choices?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  commentPrompt?: Maybe<Scalars['String']['output']>;
  existingFeedbackTargetIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  falseAnswer?: Maybe<Scalars['String']['output']>;
  followUpAnswer?: Maybe<Scalars['Boolean']['output']>;
  followUpRatingMax?: Maybe<Scalars['Int']['output']>;
  followUpSelection?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  followUpWithComment: Scalars['Boolean']['output'];
  followUpWithContactable: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  multipleChoice?: Maybe<Scalars['Boolean']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  ratingMax?: Maybe<Scalars['Int']['output']>;
  ratingMin?: Maybe<Scalars['Int']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  targetType?: Maybe<FeedbackTarget>;
  title?: Maybe<Scalars['String']['output']>;
  trueAnswer?: Maybe<Scalars['String']['output']>;
  type?: Maybe<FeedbackType>;
};

export type UserFinancial = {
  __typename?: 'UserFinancial';
  paymentSources?: Maybe<Array<Maybe<UserFinancialPaymentSource>>>;
};

export type UserFinancialInvoice = {
  __typename?: 'UserFinancialInvoice';
  amountPaid?: Maybe<Scalars['Int']['output']>;
  amountRemaining?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['Boolean']['output']>;
  periodeStartAt?: Maybe<Scalars['String']['output']>;
  refunds?: Maybe<Array<Maybe<UserFinancialRefund>>>;
  total?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type UserFinancialPaymentSource = {
  __typename?: 'UserFinancialPaymentSource';
  brand?: Maybe<Scalars['String']['output']>;
  expMonth?: Maybe<Scalars['String']['output']>;
  expYear?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
  paymentSourceType?: Maybe<PaymentSourceType>;
};

export type UserFinancialRefund = {
  __typename?: 'UserFinancialRefund';
  amount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<RefundStatus>;
};

export type UserFlowStatus = {
  __typename?: 'UserFlowStatus';
  CPRNumber?: Maybe<Scalars['String']['output']>;
  coach?: Maybe<User>;
  firstName?: Maybe<Scalars['String']['output']>;
  flows?: Maybe<Array<Maybe<UserFlows>>>;
  lastName?: Maybe<Scalars['String']['output']>;
  overallValue?: Maybe<UserFlowStatusValues>;
  profilePictureImageId?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  subscriptionCreatedAt?: Maybe<Scalars['String']['output']>;
  userCreatedAt?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export enum UserFlowStatusValues {
  Green = 'GREEN',
  Red = 'RED',
  Yellow = 'YELLOW',
}

export type UserFlows = {
  __typename?: 'UserFlows';
  fid?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<UserFlowStatusValues>;
};

export enum UserGroups {
  Administrator = 'ADMINISTRATOR',
  Coach = 'COACH',
  Doctor = 'DOCTOR',
  Nurse = 'NURSE',
}

export type UserIdConfirmationResult = {
  __typename?: 'UserIdConfirmationResult';
  auth0Error?: Maybe<Auth0Error>;
  auth0Token?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export enum UserIdConfirmationType {
  Email = 'EMAIL',
  Sms = 'SMS',
}

export enum UserIdentificationType {
  Cpr = 'CPR',
  NationalInsuranceNumber = 'NATIONAL_INSURANCE_NUMBER',
  None = 'NONE',
}

export type UserImage = {
  __typename?: 'UserImage';
  createdAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  s3Path?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  userImageUrl?: Maybe<Scalars['String']['output']>;
};

export type UserImageInput = {
  base64: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  target?: InputMaybe<UserImageTarget>;
};

export type UserImageRemoveInput = {
  id: Scalars['ID']['input'];
};

export type UserImageTarget = {
  profilePicture?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profileAvatarConfiguration?: Maybe<Scalars['JSON']['output']>;
  profilePictureImageId?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
};

export type UserInput = {
  CPRNumber?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<AddressInput>;
  alias?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  heightCm?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<LocaleIso6391>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  profilePictureImageId?: InputMaybe<Scalars['String']['input']>;
  screeningForm?: InputMaybe<Scalars['Boolean']['input']>;
  uiSettings?: InputMaybe<Scalars['String']['input']>;
};

export type UserInputV2 = {
  CPRNumber?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<AddressInput>;
  alias?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  heightCm?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<LocaleIso6391>;
  profilePictureImageId?: InputMaybe<Scalars['String']['input']>;
  screeningForm?: InputMaybe<Scalars['Boolean']['input']>;
  sexAssignedAtBirth?: InputMaybe<SexAssignedAtBirth>;
  uiSettings?: InputMaybe<Scalars['String']['input']>;
  unitSystem?: InputMaybe<UnitSystem>;
};

export type UserNote = {
  __typename?: 'UserNote';
  createdAt: Scalars['Date']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  note: Scalars['String']['output'];
  userImageId?: Maybe<Scalars['String']['output']>;
};

export type UserPaginationArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderBy>;
  sortOrder?: InputMaybe<SortOrder>;
};

export enum UserRequiredAction {
  AddressMissing = 'ADDRESS_MISSING',
  AppointmentNewCoachBook = 'APPOINTMENT_NEW_COACH_BOOK',
  AppointmentScreeningBook = 'APPOINTMENT_SCREENING_BOOK',
  MeasurementEventsMissing = 'MEASUREMENT_EVENTS_MISSING',
  PaymentCreditCardMissing = 'PAYMENT_CREDIT_CARD_MISSING',
  ReminderBookCoach = 'REMINDER_BOOK_COACH',
  ReminderFoodMeassurement = 'REMINDER_FOOD_MEASSUREMENT',
  ReminderStatusMeassurement = 'REMINDER_STATUS_MEASSUREMENT',
  ReminderWeightMeassurement = 'REMINDER_WEIGHT_MEASSUREMENT',
  SubscriptionComplete = 'SUBSCRIPTION_COMPLETE',
  SubscriptionFailed = 'SUBSCRIPTION_FAILED',
  SubscriptionMissing = 'SUBSCRIPTION_MISSING',
  UnpaidInvoice = 'UNPAID_INVOICE',
}

export type UserSearch = {
  __typename?: 'UserSearch';
  results?: Maybe<Array<Maybe<User>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type UserSearchQueryArgs = {
  careTeam?: InputMaybe<CareTeamInput>;
  coachId?: InputMaybe<Scalars['ID']['input']>;
  excludedTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isEmbla?: InputMaybe<Scalars['Boolean']['input']>;
  lastWeekMeasurementCount?: InputMaybe<NumberRange>;
  role?: InputMaybe<Role>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
  stripeProductIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UserSetupSubscriptionResponse = {
  __typename?: 'UserSetupSubscriptionResponse';
  clientSecret: Scalars['String']['output'];
  publicKey: Scalars['String']['output'];
};

export enum UserSignInFlowDecision {
  Apple = 'APPLE',
  Continue = 'CONTINUE',
  Email = 'EMAIL',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  Other = 'OTHER',
  Signup = 'SIGNUP',
  Sms = 'SMS',
  TestLogin = 'TEST_LOGIN',
}

export type UserSignInFlowDecisionData = {
  __typename?: 'UserSignInFlowDecisionData';
  decision: UserSignInFlowDecision;
  id?: Maybe<Scalars['ID']['output']>;
  signUpToken?: Maybe<Scalars['String']['output']>;
};

export type UserStartSubscriptionResponse = {
  __typename?: 'UserStartSubscriptionResponse';
  clientSecret?: Maybe<Scalars['String']['output']>;
  paymentIntentId?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  status: SubscriptionStartStatus;
};

export enum UserStatus {
  Consumer = 'CONSUMER',
  CustomerCoachingActive = 'CUSTOMER_COACHING_ACTIVE',
  CustomerCoachingPaused = 'CUSTOMER_COACHING_PAUSED',
  CustomerMedicationAndCoachingActive = 'CUSTOMER_MEDICATION_AND_COACHING_ACTIVE',
  CustomerMedicationAndCoachingPaused = 'CUSTOMER_MEDICATION_AND_COACHING_PAUSED',
  PotentialCustomer = 'POTENTIAL_CUSTOMER',
  PotentialCustomerAlumni = 'POTENTIAL_CUSTOMER_ALUMNI',
  PotentialCustomerAwaitingPrescription = 'POTENTIAL_CUSTOMER_AWAITING_PRESCRIPTION',
  PotentialCustomerAwaitingSubscription = 'POTENTIAL_CUSTOMER_AWAITING_SUBSCRIPTION',
  PotentialCustomerDeclined = 'POTENTIAL_CUSTOMER_DECLINED',
  PotentialCustomerHasBookedScreening = 'POTENTIAL_CUSTOMER_HAS_BOOKED_SCREENING',
  PotentialCustomerIncompleteSubscription = 'POTENTIAL_CUSTOMER_INCOMPLETE_SUBSCRIPTION',
  PotentialCustomerScreeningResultPending = 'POTENTIAL_CUSTOMER_SCREENING_RESULT_PENDING',
}

export type UserStatusActionsAndFeatures = {
  __typename?: 'UserStatusActionsAndFeatures';
  actions?: Maybe<Array<Maybe<UserRequiredAction>>>;
  errors?: Maybe<Array<Maybe<ErrorMessage>>>;
  features?: Maybe<Array<Maybe<UserFeature>>>;
  id?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<UserStatus>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum UserTags {
  BlockedFromBooking = 'BLOCKED_FROM_BOOKING',
  HasScheduledSubscriptionStart = 'HAS_SCHEDULED_SUBSCRIPTION_START',
  MedicationPrescriptionMissing = 'MEDICATION_PRESCRIPTION_MISSING',
  MedicationPrescriptionStockLow = 'MEDICATION_PRESCRIPTION_STOCK_LOW',
  MissingCoach = 'MISSING_COACH',
  PastDueInvoice = 'PAST_DUE_INVOICE',
  PreviousCustomer = 'PREVIOUS_CUSTOMER',
  ProfessionalCoachConsult = 'PROFESSIONAL_COACH_CONSULT',
  ProfessionalDoctorConsult = 'PROFESSIONAL_DOCTOR_CONSULT',
  ProfessionalNurseConsult = 'PROFESSIONAL_NURSE_CONSULT',
  ProfessionalUserPremiumRejected = 'PROFESSIONAL_USER_PREMIUM_REJECTED',
  PublicUserFreemiumOnly = 'PUBLIC_USER_FREEMIUM_ONLY',
  ReminderBookCoach = 'REMINDER_BOOK_COACH',
  ReminderFoodMeassurement = 'REMINDER_FOOD_MEASSUREMENT',
  ReminderStatusMeassurement = 'REMINDER_STATUS_MEASSUREMENT',
  ReminderWeightMeassurement = 'REMINDER_WEIGHT_MEASSUREMENT',
  SubscriptionIncompleteExpired = 'SUBSCRIPTION_INCOMPLETE_EXPIRED',
  SubscriptionWillCancel = 'SUBSCRIPTION_WILL_CANCEL',
  SubscriptionWillPause = 'SUBSCRIPTION_WILL_PAUSE',
  UnpaidInvoice = 'UNPAID_INVOICE',
  WorkNewMember = 'WORK_NEW_MEMBER',
  WorkPeptalk = 'WORK_PEPTALK',
  WorkPeriodCheckUp = 'WORK_PERIOD_CHECK_UP',
  WorkTodo = 'WORK_TODO',
}

export type UserTokensInvalidated = {
  __typename?: 'UserTokensInvalidated';
  minimumRequiredTokenCreationDate: Scalars['Date']['output'];
  userId: Scalars['ID']['output'];
};

export type UserVerification = {
  __typename?: 'UserVerification';
  createdAt?: Maybe<Scalars['Date']['output']>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  screenshotId?: Maybe<Scalars['ID']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  verifiedById?: Maybe<Scalars['ID']['output']>;
  verifiedByName?: Maybe<Scalars['String']['output']>;
};

export type UtmHeaders = {
  fbclid?: InputMaybe<Scalars['String']['input']>;
  gclid?: InputMaybe<Scalars['String']['input']>;
  utm_adgroupid?: InputMaybe<Scalars['String']['input']>;
  utm_campaign?: InputMaybe<Scalars['String']['input']>;
  utm_content?: InputMaybe<Scalars['String']['input']>;
  utm_keyword?: InputMaybe<Scalars['String']['input']>;
  utm_medium?: InputMaybe<Scalars['String']['input']>;
  utm_source?: InputMaybe<Scalars['String']['input']>;
  utm_term?: InputMaybe<Scalars['String']['input']>;
};

export type WeightGoal = {
  __typename?: 'WeightGoal';
  color?: Maybe<WeightGoalColor>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  goal?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export enum WeightGoalColor {
  Variant_01 = 'VARIANT_01',
  Variant_02 = 'VARIANT_02',
  Variant_03 = 'VARIANT_03',
  Variant_04 = 'VARIANT_04',
  Variant_05 = 'VARIANT_05',
  Variant_06 = 'VARIANT_06',
  Variant_07 = 'VARIANT_07',
  Variant_08 = 'VARIANT_08',
  Variant_09 = 'VARIANT_09',
  Variant_10 = 'VARIANT_10',
}

export type WeightGoalInput = {
  color: WeightGoalColor;
  goal: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export enum WeightLossSolution {
  Coaching = 'COACHING',
  Dieting = 'DIETING',
  Medication = 'MEDICATION',
  MobileApps = 'MOBILE_APPS',
  None = 'NONE',
  OnlinePrograms = 'ONLINE_PROGRAMS',
  PhysicalTraining = 'PHYSICAL_TRAINING',
}

export type WeightLossSolutionsInput = {
  COACHING?: InputMaybe<Scalars['Boolean']['input']>;
  DIETING?: InputMaybe<Scalars['Boolean']['input']>;
  MEDICATION?: InputMaybe<Scalars['Boolean']['input']>;
  MOBILE_APPS?: InputMaybe<Scalars['Boolean']['input']>;
  NONE?: InputMaybe<Scalars['Boolean']['input']>;
  ONLINE_PROGRAMS?: InputMaybe<Scalars['Boolean']['input']>;
  PHYSICAL_TRAINING?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BookableScreeningAppointmentsQueryVariables = Exact<{ [key: string]: never }>;

export type BookableScreeningAppointmentsQuery = {
  __typename?: 'Query';
  BookableScreeningAppointments?: Array<{
    __typename?: 'PublicBookableScreeningAppointment';
    calendarId: string;
    startTime: any;
    endTime: any;
    profilePictureImageId?: string | null;
    name: string;
    userRole: Role;
    userId: string;
  } | null> | null;
};

export type UserStartIdConfirmationSmsMutationVariables = Exact<{
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
}>;

export type UserStartIdConfirmationSmsMutation = { __typename?: 'Mutation'; UserStartIdConfirmation?: boolean | null };

export type UserCompleteIdConfirmationSmsMutationVariables = Exact<{
  code: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
}>;

export type UserCompleteIdConfirmationSmsMutation = {
  __typename?: 'Mutation';
  UserCompleteIdConfirmation?: {
    __typename?: 'UserIdConfirmationResult';
    success: boolean;
    auth0Token?: string | null;
    auth0Error?: { __typename?: 'Auth0Error'; name: string; description: string } | null;
  } | null;
};

export type UserGetSignInFlowMutationVariables = Exact<{
  phoneNumber: Scalars['String']['input'];
}>;

export type UserGetSignInFlowMutation = {
  __typename?: 'Mutation';
  UserGetSignInFlow?: { __typename?: 'UserSignInFlowDecisionData'; decision: UserSignInFlowDecision } | null;
};

export type UserGetIncompleteSignUpTokenMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type UserGetIncompleteSignUpTokenMutation = {
  __typename?: 'Mutation';
  UserGetSignInFlow?: {
    __typename?: 'UserSignInFlowDecisionData';
    id?: string | null;
    signUpToken?: string | null;
  } | null;
};

export type UserLoginAuth0V2MutationVariables = Exact<{
  auth0Token: Scalars['String']['input'];
  locale: LocaleIso6391;
}>;

export type UserLoginAuth0V2Mutation = {
  __typename?: 'Mutation';
  UserLoginAuth0V2?: { __typename?: 'Token'; token: string; userId?: string | null } | null;
};

export type UserCreateMutationVariables = Exact<{
  temporaryUserId?: InputMaybe<Scalars['ID']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  countryISO3166: CountryIso3166;
  localeISO6391: LocaleIso6391;
  recaptchaToken?: InputMaybe<Scalars['String']['input']>;
  utmHeaders?: InputMaybe<UtmHeaders>;
  metaData?: InputMaybe<Scalars['String']['input']>;
}>;

export type UserCreateMutation = {
  __typename?: 'Mutation';
  UserCreate?: {
    __typename?: 'Token';
    token: string;
    role?: string | null;
    userId?: string | null;
    emailNotVerified?: boolean | null;
    auth0Sub?: string | null;
  } | null;
};

export type AppointmentCreateMutationVariables = Exact<{
  calendarId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
}>;

export type AppointmentCreateMutation = {
  __typename?: 'Mutation';
  AppointmentCreate?: {
    __typename?: 'Appointment';
    id?: string | null;
    appointmentDate?: any | null;
    completedAt?: any | null;
    status?: string | null;
    type?: string | null;
    comment?: string | null;
  } | null;
};

export type TrackEventMutationVariables = Exact<{
  eventName: Scalars['String']['input'];
  data?: InputMaybe<Scalars['String']['input']>;
}>;

export type TrackEventMutation = { __typename?: 'Mutation'; TrackEvent?: boolean | null };

export type SignupFunnelFinalizeV3MutationVariables = Exact<{
  data?: InputMaybe<SignupFunnelFinalizeV3Input>;
  terminal?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SignupFunnelFinalizeV3Mutation = { __typename?: 'Mutation'; SignupFunnelFinalizeV3?: boolean | null };

export type MarketingLeadByClientIdQueryVariables = Exact<{
  clientId: Scalars['ID']['input'];
}>;

export type MarketingLeadByClientIdQuery = {
  __typename?: 'Query';
  MarketingLeadByClientId?: { __typename?: 'MarketingLead'; id: string } | null;
};

export type SignupFunnelMarkAsLeadMutationVariables = Exact<{ [key: string]: never }>;

export type SignupFunnelMarkAsLeadMutation = { __typename?: 'Mutation'; SignupFunnelMarkAsLead?: boolean | null };

export type AddTagsToMarketingLeadMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  tags: Array<MarketingLeadTag> | MarketingLeadTag;
}>;

export type AddTagsToMarketingLeadMutation = {
  __typename?: 'Mutation';
  AddTagsToMarketingLead: { __typename?: 'MarketingLead'; id: string; tags: Array<MarketingLeadTag> };
};

export type UserGetQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type UserGetQuery = {
  __typename?: 'Query';
  User?: {
    __typename?: 'User';
    id: string;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    phoneNumber?: string | null;
    heightCm?: number | null;
    weightKg?: string | null;
    phoneVerified?: boolean | null;
    countryISO3166?: CountryIso3166 | null;
    metaData?: string | null;
    unitSystem?: UnitSystem | null;
    activeScreening?: {
      __typename?: 'Appointment';
      id?: string | null;
      appointmentDate?: any | null;
      completedAt?: any | null;
      status?: string | null;
      type?: string | null;
      comment?: string | null;
    } | null;
    client?: {
      __typename?: 'Client';
      type?: ClientType | null;
      sexAssignedAtBirth?: SexAssignedAtBirth | null;
      feelingAboutWeightLoss?: FeelingAboutWeightLoss | null;
      pastWeightLossSolutions?: Array<WeightLossSolution | null> | null;
      signedupWhileOnMedication?: boolean | null;
      motivations: Array<ClientMotivation | null>;
      heightCm?: number | null;
      screeningResults?: string | null;
      signupPregnantOrBreastfeeding?: boolean | null;
      signupCurrentMedication?: Array<CurrentMedication | null> | null;
      signupMedicationWanted?: MedicationWantedAnswers | null;
      emailMarketing?: boolean | null;
      signupStatus?: SignupStatus | null;
      heardAboutEmbla?: HeardAboutEmbla | null;
    } | null;
  } | null;
};

export type FinancialPricesQueryVariables = Exact<{
  locale: LocaleIso6391;
  promoCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type FinancialPricesQuery = {
  __typename?: 'Query';
  FinancialPrices?: Array<{
    __typename?: 'FinancialPrice';
    id?: string | null;
    name?: string | null;
    paymentIntervalCode?: string | null;
    paymentIntervalCount?: string | null;
    amount?: number | null;
    currency?: string | null;
    bmi?: string | null;
    icon?: string | null;
    description?: string | null;
    terms?: string | null;
    campaignText?: string | null;
    campaignAmount?: number | null;
    campaignDiscountText?: string | null;
    product?: {
      __typename?: 'FinancialProduct';
      name?: string | null;
      eligibilityType?: EligibilityType | null;
    } | null;
    campaignDiscount?: {
      __typename?: 'CouponDiscount';
      name?: string | null;
      amount?: number | null;
      percentage?: number | null;
      currency?: string | null;
      duration?: string | null;
      durationInMonths?: number | null;
    } | null;
  } | null> | null;
};

export type SubscriptionSetupMutationVariables = Exact<{
  priceId: Scalars['ID']['input'];
}>;

export type SubscriptionSetupMutation = {
  __typename?: 'Mutation';
  UserSubscriptionSetup: { __typename?: 'UserSetupSubscriptionResponse'; clientSecret: string; publicKey: string };
};

export const namedOperations = {
  Query: {
    BookableScreeningAppointments: 'BookableScreeningAppointments',
    MarketingLeadByClientId: 'MarketingLeadByClientId',
    UserGet: 'UserGet',
    FinancialPrices: 'FinancialPrices',
  },
  Mutation: {
    UserStartIdConfirmationSMS: 'UserStartIdConfirmationSMS',
    UserCompleteIdConfirmationSMS: 'UserCompleteIdConfirmationSMS',
    UserGetSignInFlow: 'UserGetSignInFlow',
    UserGetIncompleteSignUpToken: 'UserGetIncompleteSignUpToken',
    UserLoginAuth0V2: 'UserLoginAuth0V2',
    UserCreate: 'UserCreate',
    AppointmentCreate: 'AppointmentCreate',
    TrackEvent: 'TrackEvent',
    SignupFunnelFinalizeV3: 'SignupFunnelFinalizeV3',
    SignupFunnelMarkAsLead: 'SignupFunnelMarkAsLead',
    AddTagsToMarketingLead: 'AddTagsToMarketingLead',
    SubscriptionSetup: 'SubscriptionSetup',
  },
};

export const BookableScreeningAppointmentsDocument = gql`
  query BookableScreeningAppointments {
    BookableScreeningAppointments {
      calendarId
      startTime
      endTime
      profilePictureImageId
      name
      userRole
      userId
    }
  }
`;
export const UserStartIdConfirmationSmsDocument = gql`
  mutation UserStartIdConfirmationSMS($phoneNumber: String) {
    UserStartIdConfirmation(type: SMS, receiver: $phoneNumber)
  }
`;
export const UserCompleteIdConfirmationSmsDocument = gql`
  mutation UserCompleteIdConfirmationSMS($code: String!, $phoneNumber: String) {
    UserCompleteIdConfirmation(type: SMS, code: $code, receiver: $phoneNumber) {
      success
      auth0Token
      auth0Error {
        name
        description
      }
    }
  }
`;
export const UserGetSignInFlowDocument = gql`
  mutation UserGetSignInFlow($phoneNumber: String!) {
    UserGetSignInFlow(receiver: $phoneNumber, type: SMS) {
      decision
    }
  }
`;
export const UserGetIncompleteSignUpTokenDocument = gql`
  mutation UserGetIncompleteSignUpToken($email: String!) {
    UserGetSignInFlow(receiver: $email, type: EMAIL) {
      id
      signUpToken
    }
  }
`;
export const UserLoginAuth0V2Document = gql`
  mutation UserLoginAuth0V2($auth0Token: String!, $locale: LocaleISO6391!) {
    UserLoginAuth0V2(auth0Token: $auth0Token, localeISO6391: $locale) {
      token
      userId
    }
  }
`;
export const UserCreateDocument = gql`
  mutation UserCreate(
    $temporaryUserId: ID
    $email: String!
    $firstName: String!
    $lastName: String
    $phoneNumber: String!
    $countryISO3166: CountryISO3166!
    $localeISO6391: LocaleISO6391!
    $recaptchaToken: String
    $utmHeaders: UtmHeaders
    $metaData: String
  ) {
    UserCreate(
      temporaryUserId: $temporaryUserId
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      countryISO3166: $countryISO3166
      localeISO6391: $localeISO6391
      recaptchaToken: $recaptchaToken
      utmHeaders: $utmHeaders
      metaData: $metaData
    ) {
      token
      role
      userId
      emailNotVerified
      auth0Sub
    }
  }
`;
export const AppointmentCreateDocument = gql`
  mutation AppointmentCreate(
    $calendarId: ID
    $userId: ID
    $startTime: String
    $endTime: String
    $comment: String
    $reason: String
  ) {
    AppointmentCreate(
      calendarId: $calendarId
      userId: $userId
      startTime: $startTime
      endTime: $endTime
      comment: $comment
      reason: $reason
    ) {
      id
      appointmentDate
      completedAt
      status
      type
      comment
    }
  }
`;
export const TrackEventDocument = gql`
  mutation TrackEvent($eventName: String!, $data: String) {
    TrackEvent(eventName: $eventName, data: $data)
  }
`;
export const SignupFunnelFinalizeV3Document = gql`
  mutation SignupFunnelFinalizeV3($data: SignupFunnelFinalizeV3Input, $terminal: Boolean) {
    SignupFunnelFinalizeV3(data: $data, terminal: $terminal)
  }
`;
export const MarketingLeadByClientIdDocument = gql`
  query MarketingLeadByClientId($clientId: ID!) {
    MarketingLeadByClientId(clientId: $clientId) {
      id
    }
  }
`;
export const SignupFunnelMarkAsLeadDocument = gql`
  mutation SignupFunnelMarkAsLead {
    SignupFunnelMarkAsLead
  }
`;
export const AddTagsToMarketingLeadDocument = gql`
  mutation AddTagsToMarketingLead($id: ID!, $tags: [MarketingLeadTag!]!) {
    AddTagsToMarketingLead(id: $id, tags: $tags) {
      id
      tags
    }
  }
`;
export const UserGetDocument = gql`
  query UserGet($id: ID) {
    User(id: $id) {
      id
      email
      firstName
      lastName
      name
      phoneNumber
      heightCm
      weightKg
      phoneVerified
      countryISO3166
      metaData
      unitSystem
      activeScreening {
        id
        appointmentDate
        completedAt
        status
        type
        comment
      }
      client {
        type
        sexAssignedAtBirth
        feelingAboutWeightLoss
        pastWeightLossSolutions
        signedupWhileOnMedication
        motivations
        heightCm
        screeningResults
        signupPregnantOrBreastfeeding
        signupCurrentMedication
        signupMedicationWanted
        emailMarketing
        signupStatus
        heardAboutEmbla
      }
    }
  }
`;
export const FinancialPricesDocument = gql`
  query FinancialPrices($locale: LocaleISO6391!, $promoCode: String) {
    FinancialPrices(locale: $locale, promoCode: $promoCode, checkEligibility: false) {
      id
      name
      product {
        name
        eligibilityType
      }
      paymentIntervalCode
      paymentIntervalCount
      amount
      currency
      bmi
      icon
      description
      terms
      campaignDiscount {
        name
        amount
        percentage
        currency
        duration
        durationInMonths
      }
      campaignText
      campaignAmount
      campaignDiscountText
    }
  }
`;
export const SubscriptionSetupDocument = gql`
  mutation SubscriptionSetup($priceId: ID!) {
    UserSubscriptionSetup(priceId: $priceId) {
      clientSecret
      publicKey
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    BookableScreeningAppointments(
      variables?: BookableScreeningAppointmentsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<BookableScreeningAppointmentsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<BookableScreeningAppointmentsQuery>(BookableScreeningAppointmentsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'BookableScreeningAppointments',
        'query',
      );
    },
    UserStartIdConfirmationSMS(
      variables?: UserStartIdConfirmationSmsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserStartIdConfirmationSmsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserStartIdConfirmationSmsMutation>(UserStartIdConfirmationSmsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserStartIdConfirmationSMS',
        'mutation',
      );
    },
    UserCompleteIdConfirmationSMS(
      variables: UserCompleteIdConfirmationSmsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserCompleteIdConfirmationSmsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserCompleteIdConfirmationSmsMutation>(UserCompleteIdConfirmationSmsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserCompleteIdConfirmationSMS',
        'mutation',
      );
    },
    UserGetSignInFlow(
      variables: UserGetSignInFlowMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserGetSignInFlowMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserGetSignInFlowMutation>(UserGetSignInFlowDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserGetSignInFlow',
        'mutation',
      );
    },
    UserGetIncompleteSignUpToken(
      variables: UserGetIncompleteSignUpTokenMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserGetIncompleteSignUpTokenMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserGetIncompleteSignUpTokenMutation>(UserGetIncompleteSignUpTokenDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserGetIncompleteSignUpToken',
        'mutation',
      );
    },
    UserLoginAuth0V2(
      variables: UserLoginAuth0V2MutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserLoginAuth0V2Mutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserLoginAuth0V2Mutation>(UserLoginAuth0V2Document, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserLoginAuth0V2',
        'mutation',
      );
    },
    UserCreate(
      variables: UserCreateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserCreateMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserCreateMutation>(UserCreateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UserCreate',
        'mutation',
      );
    },
    AppointmentCreate(
      variables?: AppointmentCreateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AppointmentCreateMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AppointmentCreateMutation>(AppointmentCreateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'AppointmentCreate',
        'mutation',
      );
    },
    TrackEvent(
      variables: TrackEventMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<TrackEventMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TrackEventMutation>(TrackEventDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'TrackEvent',
        'mutation',
      );
    },
    SignupFunnelFinalizeV3(
      variables?: SignupFunnelFinalizeV3MutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SignupFunnelFinalizeV3Mutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SignupFunnelFinalizeV3Mutation>(SignupFunnelFinalizeV3Document, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'SignupFunnelFinalizeV3',
        'mutation',
      );
    },
    MarketingLeadByClientId(
      variables: MarketingLeadByClientIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<MarketingLeadByClientIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MarketingLeadByClientIdQuery>(MarketingLeadByClientIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'MarketingLeadByClientId',
        'query',
      );
    },
    SignupFunnelMarkAsLead(
      variables?: SignupFunnelMarkAsLeadMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SignupFunnelMarkAsLeadMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SignupFunnelMarkAsLeadMutation>(SignupFunnelMarkAsLeadDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'SignupFunnelMarkAsLead',
        'mutation',
      );
    },
    AddTagsToMarketingLead(
      variables: AddTagsToMarketingLeadMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddTagsToMarketingLeadMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddTagsToMarketingLeadMutation>(AddTagsToMarketingLeadDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'AddTagsToMarketingLead',
        'mutation',
      );
    },
    UserGet(variables?: UserGetQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserGetQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserGetQuery>(UserGetDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'UserGet',
        'query',
      );
    },
    FinancialPrices(
      variables: FinancialPricesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<FinancialPricesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FinancialPricesQuery>(FinancialPricesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'FinancialPrices',
        'query',
      );
    },
    SubscriptionSetup(
      variables: SubscriptionSetupMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SubscriptionSetupMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SubscriptionSetupMutation>(SubscriptionSetupDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'SubscriptionSetup',
        'mutation',
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
