import { Box, List, MantineSpacing, Text } from "@mantine/core";
import { FunctionComponent, ReactNode } from "react";

import { colors } from "../theme";

interface IProps {
  items: {
    icon: (props: { width?: number; color?: string }) => ReactNode;
    label: string | ReactNode;
  }[];
  color?: string;
  weight?: number;
  spacing?: MantineSpacing;
}
export const IconList: FunctionComponent<IProps> = ({
  items,
  color = colors.embla[3],
  weight = 600,
  spacing,
}) => {
  return (
    <List spacing={8}>
      {items.map((item, idx) => (
        <List.Item
          key={idx + String(item.label)}
          color="embla.3"
          icon={<Box mt={2}>{item.icon?.({ width: 16, color })}</Box>}
        >
          <Text ml={spacing && spacing} fw={weight} c={color}>
            {item.label}
          </Text>
        </List.Item>
      ))}
    </List>
  );
};
