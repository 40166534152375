import { useViewportSize } from "@mantine/hooks";
import { Button, ButtonProps, Group } from "@mantine/core";
import { HTMLAttributeAnchorTarget, MouseEventHandler } from "react";
import classes from "./Button.module.css";

interface IProps extends ButtonProps {
  buttonLabel?: string;
  onClick?: MouseEventHandler;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  type?: HTMLButtonElement["type"];
}

export const GrowingButton: React.FunctionComponent<IProps> = ({
  type,
  buttonLabel,
  onClick,
  href,
  target = "_blank",
  ...buttonProps
}) => {
  const { width } = useViewportSize();
  const classNames = {
    root: buttonProps.variant === "subtle" ? null : classes.primary,
  };
  return (
    <Group grow={width < 500} justify="center">
      {href ? (
        <Button
          classNames={classNames}
          type={type}
          miw={222}
          component="a"
          target={target}
          rel="noopener noreferrer"
          href={href}
          onClick={onClick}
          {...buttonProps}
        >
          {buttonLabel}
        </Button>
      ) : (
        <Button
          classNames={classNames}
          type={type}
          miw={222}
          onClick={onClick}
          {...buttonProps}
        >
          {buttonLabel}
        </Button>
      )}
    </Group>
  );
};
