import { ReactComponent as IconCheck } from "@assets/icons/fi-rr-badge-check.svg";
import { ReactComponent as IconChartArrow } from "@assets/icons/fi-rr-chat-arrow-down.svg";
import { ReactComponent as IconMedicine } from "@assets/icons/fi-rr-medicine.svg";
import { ReactComponent as IconScale } from "@assets/icons/fi-rr-scale.svg";
import { ReactComponent as IconTruck } from "@assets/icons/fi-rr-truck-side.svg";
import medicineSrc from "@assets/images/medicine-header.jpg";
import appetiteSrc from "@assets/science-behind/appetite.svg";
import bloodSugarSrc from "@assets/science-behind/blood-sugar.svg";
import brainSrc from "@assets/science-behind/brain.svg";
import cravingsSrc from "@assets/science-behind/cravings.svg";
import { Stack, Title } from "@mantine/core";
import { IconList } from "@shared/components/IconList";
import { ImageCard } from "@shared/components/ImageCard";
import { ImageList } from "@shared/components/ImageList";
import { CountryIso3166 } from "@shared/gql/sdk";
import { resolveIntl } from "@shared/helpers";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export const GLP1MedicineCard: FunctionComponent = () => {
  const { t } = useTranslation();
  const { country } = resolveIntl();

  const pitchLists = {
    [CountryIso3166.Dk]: [
      {
        icon: IconMedicine,
        label: t("onceAWeekSelfInjectablePen"),
      },
      {
        icon: IconCheck,
        label: t("clinicallyProven"),
      },
      {
        icon: IconScale,
        label: t("averageWeightLoss1Kg"),
      },
      {
        icon: IconChartArrow,
        label: t("aPlanAndSupportTaperOffMedicine"),
      },
    ],
    [CountryIso3166.Gb]: [
      {
        icon: IconTruck,
        label: t("deliveredToYourDoor"),
      },
      {
        icon: IconMedicine,
        label: t("onceAWeekSelfInjectablePen"),
      },
      {
        icon: IconCheck,
        label: t("clinicallyProven"),
      },
      {
        icon: IconScale,
        label: t("averageWeightLoss1Kg"),
      },
    ],
  };

  return (
    <ImageCard
      imageSrc={medicineSrc}
      imageOffset={"-50%"}
      bottomSection={
        <ImageList
          itemWidth={100}
          itemSpacing={16}
          title={t("theScienceBehind")}
          items={[
            {
              imageSrc: brainSrc,
              title: t("regulatedAppetite"),
            },
            {
              imageSrc: appetiteSrc,
              title: t("increaesSatiety"),
            },
            {
              imageSrc: cravingsSrc,
              title: t("reducesCravings"),
            },
            {
              imageSrc: bloodSugarSrc,
              title: t("decreasesBloodSugar"),
            },
          ]}
        />
      }
    >
      <Stack>
        <Title c="embla.4" size={30} fw={600}>
          {t("glp1Medicine")}
        </Title>
        <IconList items={pitchLists[country]} />
      </Stack>
    </ImageCard>
  );
};
