import {
  FunnelEngineConfig,
  FunnelPageActions,
  FunnelPageComponent,
  FunnelPageConfiguration,
  IFunnelEngineHookOptions,
} from "./types";

type FunnelPageArgs<
  TData extends Record<string, unknown>,
  TContext extends object,
  TActions extends FunnelPageActions,
  TErrors extends string
> = [
  component: FunnelPageComponent<TData, TContext, TErrors, TActions>,
  // @ts-ignore - Error about all tuple entries must either have or not have a name - here it resolved to named entries
  ...(TActions extends undefined
    ? [
        config?: Omit<
          FunnelPageConfiguration<TData, TContext, TActions, TErrors>,
          "actions" | "component"
        >
      ]
    : [
        config: Omit<
          FunnelPageConfiguration<TData, TContext, TActions, TErrors>,
          "component"
        >
      ])
];

export const funnelPage = <
  TData extends Record<string, unknown>,
  TContext extends object,
  TActions extends FunnelPageActions,
  TErrors extends string
>(
  ...[component, config]: FunnelPageArgs<TData, TContext, TActions, TErrors>
): FunnelPageConfiguration<TData, TContext, TActions, TErrors> => {
  return {
    component,
    // @ts-ignore - actually not sure about this one, but works
    actions: undefined,
    ...(config || {}),
  };
};

export const funnelConfiguration = <T extends FunnelEngineConfig>(
  config: IFunnelEngineHookOptions<T>
) => config;
