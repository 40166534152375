import { i18n } from "i18next";
import React, { FunctionComponent, PropsWithChildren } from "react";
import { TrackingContextProvider } from "./TrackingContext";
import {
  FeatureToggleContextProvider,
  useFeatureToggleContext,
} from "./FeatureToggleContext";
import { MantineProvider } from "@mantine/core";
import { theme } from "./theme";
import { Notifications } from "@mantine/notifications";
import classes from "./notification.module.css";
import { I18nextProvider } from "react-i18next";

const FeatureLoader: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { featuresLoaded } = useFeatureToggleContext();
  if (!featuresLoaded) return null;

  return children;
};

export const DefaultContexts: FunctionComponent<
  PropsWithChildren<{ i18n?: i18n }>
> = ({ i18n, children }) => {
  return (
    <React.StrictMode>
      <TrackingContextProvider>
        <FeatureToggleContextProvider>
          <MantineProvider theme={theme}>
            <I18nextProvider i18n={i18n}>
              <Notifications
                position="bottom-right"
                classNames={{ notification: classes.notification }}
              />
              <FeatureLoader>{children}</FeatureLoader>
            </I18nextProvider>
          </MantineProvider>
        </FeatureToggleContextProvider>
      </TrackingContextProvider>
    </React.StrictMode>
  );
};
