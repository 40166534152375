import { Card, MantineColor } from "@mantine/core";
import { FunctionComponent, PropsWithChildren, ReactNode } from "react";
import { theme } from "../theme";

interface IImageCardProps {
  imageSrc: string;
  imageOffset?: number | string;
  imageBg?: MantineColor;
  bottomSection?: ReactNode;
  imageStyle?: React.CSSProperties;
}

export const ImageCard: FunctionComponent<
  PropsWithChildren<IImageCardProps>
> = ({
  children,
  imageSrc,
  imageBg,
  imageOffset,
  bottomSection,
  imageStyle,
}) => {
  return (
    <Card
      shadow="md"
      bg="cream.0"
      styles={{
        root: {
          borderColor: theme.colors.embla[1],
          borderWidth: 1,
          borderStyle: "solid",
        },
      }}
    >
      <Card.Section bg={imageBg}>
        <div
          style={{
            maxHeight: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <img
            width="100%"
            src={imageSrc}
            style={{
              marginTop: imageOffset,
              ...(imageStyle || {}),
            }}
          />
        </div>
      </Card.Section>
      <Card.Section p="lg" inheritPadding>
        {children}
      </Card.Section>
      {bottomSection && <Card.Section pb="lg">{bottomSection}</Card.Section>}
    </Card>
  );
};
