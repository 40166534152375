import { CountryIso3166, LocaleIso6391 } from "@shared/gql/sdk";
import dayjs from "dayjs";

import { changeLanguage } from "./i18n";

type Currency = "dkk" | "gbp";
type IntlConfig = {
  locale: LocaleIso6391;
  country: CountryIso3166;
  currency: Currency;
};

const CountryLocaleStoreKeys = {
  [CountryIso3166.Dk]: "locale_dk",
  [CountryIso3166.Gb]: "locale_gb",
};

export const resolveCountry = () => {
  const { href } = window.location;
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const paramCountry = params.get("country") as CountryIso3166;

  if (href.includes("/uk/")) {
    return CountryIso3166.Gb;
  } else if (href.includes("/dk/")) {
    return CountryIso3166.Dk;
  } else return paramCountry || CountryIso3166.Gb;
};

export const resolveLocale = () => {
  const { search } = window.location;
  const params = new URLSearchParams(search);

  const country = resolveCountry();
  const sessionLocale = sessionStorage.getItem(
    CountryLocaleStoreKeys[country],
  ) as LocaleIso6391;
  const paramLocale = params.get("locale") as LocaleIso6391;
  const defaultLocale =
    country === CountryIso3166.Dk ? LocaleIso6391.Da : LocaleIso6391.En;

  return paramLocale || sessionLocale || defaultLocale;
};

export const resolveCurrency = () => {
  const country = resolveCountry();

  switch (country) {
    case CountryIso3166.Dk:
      return "dkk";
    case CountryIso3166.Gb:
      return "gbp";
    default:
      return "gbp";
  }
};

export const changeLocale = (locale: LocaleIso6391) => {
  changeLanguage(locale);
  dayjs.locale(locale === LocaleIso6391.Da ? "da" : "en-gb");

  const country = resolveCountry();
  sessionStorage.setItem(CountryLocaleStoreKeys[country], locale);
};

export const resolveIntl = (): IntlConfig => {
  return {
    locale: resolveLocale(),
    country: resolveCountry(),
    currency: resolveCurrency(),
  };
};
