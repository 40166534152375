import { ReactComponent as IconCarrot } from "@assets/icons/fi-rr-carrot.svg";
import { ReactComponent as IconComment } from "@assets/icons/fi-rr-comment.svg";
import { ReactComponent as IconComments } from "@assets/icons/fi-rr-comments.svg";
import { ReactComponent as IconStats } from "@assets/icons/fi-rr-stats.svg";
import triplePhoneSrc from "@assets/images/triple-phone.png";
import { Stack, Title } from "@mantine/core";
import { IconList } from "@shared/components/IconList";
import { ImageCard } from "@shared/components/ImageCard";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export const VirtualWeightLossUniverseCard: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <ImageCard
      imageBg="cream.2"
      imageSrc={triplePhoneSrc}
      imageStyle={{
        width: "130%",
        marginRight: "5%",
        marginTop: "45%",
        rotate: "5deg",
      }}
    >
      <Stack>
        <Title c="embla.4" size={30} fw={600}>
          {t("virtualWeightLossUniverse")}
        </Title>
        <IconList
          items={[
            {
              icon: IconStats,
              label: t("trackAndFollowYourProgress"),
            },
            {
              icon: IconComments,
              label: t("meetOtherMembers"),
            },
            {
              icon: IconCarrot,
              label: t("recipesAndArticles"),
            },
            {
              icon: IconComment,
              label: t("contactYourTreatmentTeam"),
            },
          ]}
        />
      </Stack>
    </ImageCard>
  );
};
