import { NumberInput, NumberInputProps, Text } from "@mantine/core";
import { FocusEventHandler, FunctionComponent } from "react";

import emblaNumberInputClasses from "./css-modules/EmblaNumberInput.module.css";

interface IProps extends NumberInputProps {
  suffix?: string;
  selectOnFocus?: boolean;
  mode?:
    | "default"
    | "measurement"
    | "measurement-result"
    | "website"
    | "website-result";
}

export const EmblaNumberInput: FunctionComponent<IProps> = (props) => {
  const {
    suffix,
    selectOnFocus = true,
    mode = "default",
    ...inputProps
  } = props;

  const selectAll: FocusEventHandler<HTMLInputElement> = (e) => {
    e.currentTarget?.setSelectionRange(0, e.currentTarget.value?.length || 0);
  };

  return (
    <NumberInput
      {...inputProps}
      w={mode === "measurement" ? inputProps.width : undefined}
      classNames={{
        input: getInputClassNames(mode),
      }}
      min={0}
      hideControls
      onFocus={selectOnFocus ? selectAll : undefined}
      rightSection={
        <Text c={"embla.3"} size={"md"}>
          {suffix}
        </Text>
      }
      rightSectionWidth={50}
    />
  );
};

const getInputClassNames = (mode: IProps["mode"]) => {
  switch (mode) {
    case "measurement":
      return emblaNumberInputClasses.measurementInput;
    case "measurement-result":
      return `${emblaNumberInputClasses.measurementInput} ${emblaNumberInputClasses.measurementResultInput}`;
    case "website":
      return `${emblaNumberInputClasses.measurementInput} ${emblaNumberInputClasses.measurementRadiusInput}`;
    case "website-result":
      return `${emblaNumberInputClasses.measurementInput} ${emblaNumberInputClasses.measurementResultInput} ${emblaNumberInputClasses.measurementRadiusInput}`;
    default:
      return undefined;
  }
};
