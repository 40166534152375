import { Group, Paper, Stack, Text } from "@mantine/core";
import { ReactNode, FunctionComponent } from "react";

type InfoRow = { icon?: ReactNode; label: string; value: string };
interface IProps {
  config: Array<InfoRow>;
  title?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}
export const KeyValueInfoCard: FunctionComponent<IProps> = ({
  config,
  title,
  header,
  footer,
}) => {
  const renderRow = (row: InfoRow) => {
    return (
      <Group key={row.value}>
        <Group
          justify="space-between"
          align="flex-start"
          wrap="nowrap"
          style={{ flex: 1 }}
        >
          <Group gap={"sm"} wrap="nowrap">
            {row.icon}
            <Text c={"embla.3"} size="md">
              {row.label}
            </Text>
          </Group>
          <Text c={"embla.4"} ta="right" fw={600} size="md">
            {row.value}
          </Text>
        </Group>
      </Group>
    );
  };
  return (
    <Paper radius={"lg"} shadow="md" w={"100%"} p={"md"}>
      <Stack>
        {header}
        {title ? (
          <Text size="lg" fw={600} ta="center" c={"embla.4"}>
            {title}
          </Text>
        ) : null}
        {config?.map((c) => {
          return renderRow(c);
        })}
        {footer}
      </Stack>
    </Paper>
  );
};
