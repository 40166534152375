import {
  FunnelEngineConfig,
  FunnelEngineData,
  FunnelRoute,
  FunnelRoutes,
  NavigationDirection,
} from "../types";

export const createEmptyData = <T extends FunnelEngineConfig>(
  config: T
): FunnelEngineData<T> => {
  const obj: Record<string, Record<string, Record<never, never>>> = {};
  Object.keys(config).forEach((sectionName) => {
    obj[sectionName] = {};

    Object.keys(config[sectionName]).forEach((pageName) => {
      obj[sectionName][pageName] = {};
    });
  });

  return obj as FunnelEngineData<T>;
};

export const getSectionAndPageByDirectionRaw = <T extends FunnelEngineConfig>(
  config: T,
  currentSection: keyof T,
  currentPage: keyof T[typeof currentSection],
  direction: NavigationDirection
): FunnelRoute<T> => {
  const currentSectionPages = config[currentSection];
  const currentSectionPageNames = Object.keys(currentSectionPages);
  const currentPageIndex = currentSectionPageNames.indexOf(
    currentPage as string
  );
  const nextPage = currentSectionPageNames[
    currentPageIndex + direction
  ] as keyof T[keyof T];

  if (nextPage) {
    return { section: currentSection, page: nextPage };
  } else {
    const currentSectionNames = Object.keys(config);
    const currentSectionIndex = currentSectionNames.indexOf(
      currentSection as string
    );
    const nextSection = currentSectionNames[currentSectionIndex + direction];
    if (nextSection) {
      const sectionPageNames = Object.keys(config[nextSection]);
      const nextPageIndex =
        direction === NavigationDirection.Back
          ? sectionPageNames.length - 1
          : 0;

      return {
        section: nextSection,
        page: sectionPageNames[nextPageIndex] as keyof T[keyof T],
      };
    } else {
      return null;
    }
  }
};

export const getFunnelSteps = <T extends FunnelEngineConfig>(
  config: T,
  currentSection: keyof T,
  currentPage: keyof T[typeof currentSection]
) => {
  const sectionNames = Object.keys(config);
  return sectionNames.map((section, idx) => {
    let percentage = 0;
    const currentSectionIndex = sectionNames.indexOf(currentSection as string);

    if (idx < currentSectionIndex) {
      percentage = 100;
    } else if (idx === currentSectionIndex) {
      const pageNames = Object.keys(config[section]);
      const currentPageIndex = pageNames.indexOf(currentPage as string) + 1;
      const sectionLength = pageNames.length;

      percentage = (currentPageIndex / sectionLength) * 100;
    } else {
      percentage = 0;
    }

    return { label: section, percentComplete: percentage };
  });
};

export const routeToString = <T extends FunnelEngineConfig>(
  route: FunnelRoute<T>
): FunnelRoutes<T> => {
  return `${String(route.section)}/${String(route.page)}` as FunnelRoutes<T>;
};

export const stringToRoute = <T extends FunnelEngineConfig>(
  route: FunnelRoutes<T>,
  config: T
): FunnelRoute<T> | undefined => {
  const str = String(route);

  if (!str || !str.includes("/")) return;
  const [section, page] = str.split("/") as [keyof T, keyof T[keyof T]];

  if (
    Object.keys(config).includes(String(section)) &&
    Object.keys(config[section]).includes(String(page))
  ) {
    return { section, page };
  }
};
