import { Group, Input, Modal, Select, NumberInputProps } from "@mantine/core";
import { useDisclosure, useViewportSize } from "@mantine/hooks";
import parsePhoneNumber, {
  isPossiblePhoneNumber,
} from "libphonenumber-js/mobile";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { resolveIntl } from "../helpers";
import COUNTRY_PHONE_CODES_JSON from "./countryPhoneCodes.json";
import { EmblaPhoneInputCodePicker } from "./EmblaPhoneInputCodePicker";
import { EmblaNumberInput } from "./EmblaNumberInput";
import { CountryIso3166 } from "@shared/gql/sdk";

interface IProps extends NumberInputProps {
  disabled?: boolean;
  value?: string;
  onPhoneNumberChange?: (phoneNumber: string, valid: boolean) => void;
  classNames?: {
    error: string;
  };
}

let defaultPhoneCode = "+45";
switch (resolveIntl().country) {
  case CountryIso3166.Gb: {
    defaultPhoneCode = "+44";
    break;
  }
  case CountryIso3166.Dk:
  default: {
    defaultPhoneCode = "+45";
    break;
  }
}

const sortedCodes = COUNTRY_PHONE_CODES_JSON.sort((c1, c2) => {
  return Number(c1.dial_code.replace("+", "")) >
    Number(c2.dial_code.replace("+", ""))
    ? 1
    : -1;
});

const allCcOptions = sortedCodes.map((c) => {
  return { label: c.dial_code, value: c.dial_code };
});

export const EmblaPhoneInput: FunctionComponent<IProps> = (props) => {
  const { disabled, value, onPhoneNumberChange, classNames, ...inputProps } =
    props;

  const [isOpen, { open, close }] = useDisclosure(false);
  const { t } = useTranslation();
  const { width } = useViewportSize();
  const smallDevice = width < 500;
  const [code, setCode] = useState<string>(defaultPhoneCode);
  const [number, setNumber] = useState<string>("");

  useEffect(() => {
    const parsed = value ? parsePhoneNumber(value) : null;

    if (parsed) {
      setCode("+" + parsed?.countryCallingCode);
      setNumber(parsed?.nationalNumber);
    }
  }, [value]);

  useEffect(() => {
    if (number) {
      const parsed = parsePhoneNumber(code + number);

      const finalPhoneNumber = parsed?.format("E.164");

      onPhoneNumberChange?.(
        finalPhoneNumber,
        finalPhoneNumber && isPossiblePhoneNumber(finalPhoneNumber),
      );
    } else {
      onPhoneNumberChange?.(code + (number || ""), false);
    }
  }, [code, number]);

  return (
    <>
      <Modal
        size={"xl"}
        opened={isOpen}
        onClose={close}
        title={<b>{t("selectCountryCode")}</b>}
      >
        <EmblaPhoneInputCodePicker
          onSelect={(value) => {
            setCode(value);
          }}
          close={close}
        />
      </Modal>
      <Group gap={"sm"} align="baseline">
        {smallDevice ? (
          <Input
            type="button"
            size="lg"
            w={120}
            py={"xs"}
            styles={{
              input: {
                borderColor: "white",
              },
            }}
            disabled={disabled}
            value={code}
            onClick={open}
          ></Input>
        ) : (
          <Select
            searchable
            disabled={disabled}
            size="lg"
            role="searchbox"
            w={120}
            data={removeDuplicatesByProperty(allCcOptions, "value")}
            value={code}
            onChange={(value) => {
              setCode(value);
            }}
          />
        )}
        <EmblaNumberInput
          {...inputProps}
          classNames={classNames}
          disabled={disabled}
          type="tel"
          value={number}
          onChange={(e) => {
            setNumber(e ? e.toString() : "");
          }}
          placeholder={t("phone")}
          style={{ flex: 1 }}
        />
      </Group>
    </>
  );
};

const removeDuplicatesByProperty = <T extends { [key: string]: any }>(
  arr: T[],
  property: keyof T,
) => {
  const seen = new Set();
  return arr.filter((el) => {
    const duplicate = seen.has(el[property]);
    seen.add(el[property]);
    return !duplicate;
  });
};
