import { showNotification } from "@mantine/notifications";
import { t } from "../src/i18n";

export const showErrorNotification = (
  message: string,
  autoClose: number | boolean = 5000
) => {
  showNotification({
    message,
    title: t("error"),
    datatype: "error",
    autoClose: false,
  });
};
