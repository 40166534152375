import { CountryIso3166, LocaleIso6391 } from "@shared/gql/sdk";
import avatarSteph from "./images/employees/avatar-steph.png";
import avatarAzeem from "./images/employees/avatar-azeem.png";
import avatarNicol from "./images/employees/avatar-nicol.png";
import avatarOli from "./images/employees/avatar-oli.png";
import avatarAlina from "./images/employees/avatar-alina.png";

import avatarRobin from "./images/employees/avatar-robin.png";
import avatarLiv from "./images/employees/avatar-liv.png";
import avatarNanna from "./images/employees/avatar-nanna.png";
import avatarEmma from "./images/employees/avatar-emma.png";
import avatarPernille from "./images/employees/avatar-pernille.png";
import avatarBenjamiin from "./images/employees/avatar-benjamiin.png";

interface IEmployee {
  name: string;
  description: string;
  imageSrc: string;
}

type Country = CountryIso3166;

type EmployeeMap = Record<Country, Partial<Record<LocaleIso6391, IEmployee[]>>>;

const employeeMap: EmployeeMap = {
  DK: {
    da: [
      {
        name: "Emma Brix",
        description:
          "Bachelor i Idræt og Kandidat i Sundhedsfremme og sundhedsstrategier + Psykologi",
        imageSrc: avatarEmma,
      },
      {
        name: "Pernille Severinsen",
        description:
          "Bachelor i Ernæring og Sundhed samt Kandidat i Idræt og Sundhed med speciale i Sportspsykologi",
        imageSrc: avatarPernille,
      },
      {
        name: "Robin Petersen",
        description: "Bachelor i Idræt og Kandidat i Humanfysiologi",
        imageSrc: avatarRobin,
      },
      {
        name: "Liv Andersen",
        description:
          "Bachelor i Idræt og Sundhed samt Kandidat i Humanistisk samfundsvidenskabelig idrætsvidenskab",
        imageSrc: avatarLiv,
      },
      {
        name: "Benjamin Laudrup",
        description: "Bachelor i Idræt og Ernæring samt ICF-certificeret Coach",
        imageSrc: avatarBenjamiin,
      },
      {
        name: "Nanna Sys Frier",
        description: "Bachelor og Kandidat i Folkesundhedsvidenskab",
        imageSrc: avatarNanna,
      },
    ],
    en: [
      {
        name: "Emma Brix",
        description:
          "Bachelor in Nutrition, Exercise and Sports, Master in Health Promotion and Health Strategies + Psychology",
        imageSrc: avatarEmma,
      },
      {
        name: "Pernille Severinsen",
        description:
          "Bachelor in Health and Nutrition, Master in Sports and Health with a specialization in Sports Psychology",
        imageSrc: avatarPernille,
      },
      {
        name: "Robin Petersen",
        description: "Master in Human Physiology",
        imageSrc: avatarRobin,
      },
      {
        name: "Liv Andersen",
        description:
          "Bachelor in Sport Science and Health, Master in Humanities and Social Sports Sciences",
        imageSrc: avatarLiv,
      },
      {
        name: "Benjamin Laudrup",
        description: "Bachelor in Sports and Nutrition, ICF certified Coach",
        imageSrc: avatarBenjamiin,
      },
      {
        name: "Nanna Sys Frier",
        description: "Master in Public Health",
        imageSrc: avatarNanna,
      },
    ],
  },
  GB: {
    en: [
      {
        name: "Steph Gregory",
        description: "BA in Nutrition, Diet, and Lifestyle",
        imageSrc: avatarSteph,
      },
      {
        name: "Azeem Yussouf",
        description: "Medical Degree and BSc in Haematology",
        imageSrc: avatarAzeem,
      },
      {
        name: "Nicol Ingram",
        description: "BA in Nutrition and Public Health",
        imageSrc: avatarNicol,
      },
      {
        name: "Oliver Large",
        description: "UK Medical Director, private GP",
        imageSrc: avatarOli,
      },
      {
        name: "Alina Morozova",
        description: "Registered Nurse, BSc in Nursing, MSC in Nutrition",
        imageSrc: avatarAlina,
      },
    ],
  },
};

export const getEmployees = (country: Country, locale: LocaleIso6391) =>
  employeeMap[country][locale];
export const getPrimaryEmployee = (country: Country, locale: LocaleIso6391) =>
  employeeMap[country][locale][0];
