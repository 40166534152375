import { useIntersection, useViewportSize } from "@mantine/hooks";
import {
  ActionIcon,
  Group,
  ScrollArea,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";

import { ReactComponent as IconArrowLeft } from "@assets/icons/fi-rr-arrow-left.svg";
import { ReactComponent as IconArrowRight } from "@assets/icons/fi-rr-arrow-right.svg";
import { colors } from "../theme";

interface IImageListItemProps {
  imageSrc: string;
  title: string;
  description?: string;
  width: number;
}
const ImageListItem: FunctionComponent<IImageListItemProps> = ({
  imageSrc,
  title,
  description,
  width,
}) => {
  return (
    <Stack w={width} gap="sm" align="center">
      <div
        style={{
          width: 56,
          height: 56,
          borderRadius: 100,
          overflow: "hidden",
        }}
      >
        <img src={imageSrc} width="100%" />
      </div>
      <Title c="menthol.3" ta="center" fw="bold" size={16} miw={100}>
        {title}
      </Title>
      {description && (
        <Text c="embla.4" ta="center">
          {description}
        </Text>
      )}
    </Stack>
  );
};
interface IImageListProps {
  title: string;
  items: Omit<IImageListItemProps, "width">[];
  itemWidth?: number;
  itemSpacing?: number;
}

export const ImageList: FunctionComponent<IImageListProps> = ({
  title,
  items,
  itemWidth = 148,
  itemSpacing = 10,
}) => {
  const { width } = useViewportSize();
  const [cursor, setCursor] = useState(0);
  const listRef = useRef<HTMLDivElement>();
  const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });
  const { ref: firstElementRef, entry: firstElementEntry } = useIntersection({
    root: listRef.current,
    threshold: 1,
  });
  const { ref: lastElementRef, entry: lastElementEntry } = useIntersection({
    root: listRef.current,
    threshold: 1,
  });

  const fullItemWidth = itemWidth + itemSpacing;

  const calculateScrollPosition = (index: number) => {
    return index * fullItemWidth;
  };

  const onNextItem = () => {
    listRef.current.scrollTo({
      left: calculateScrollPosition(cursor + 1),
      behavior: "smooth",
    });
  };
  const onPrevItem = () => {
    listRef.current.scrollTo({
      left: calculateScrollPosition(cursor - 1),
      behavior: "smooth",
    });
  };

  const disableNavigation = useMemo(
    () => firstElementEntry?.isIntersecting && lastElementEntry?.isIntersecting,
    [firstElementEntry, lastElementEntry]
  );

  useEffect(() => {
    setCursor(Math.round(scrollPosition.x / fullItemWidth));
  }, [scrollPosition.x, fullItemWidth]);

  return (
    <Stack>
      <Group
        wrap="nowrap"
        justify="space-between"
        gap={width > 360 ? null : 0}
        mx="lg"
      >
        <Title size={20} fw={600} c="embla.4">
          {title}
        </Title>
        <Group wrap="nowrap" gap={width > 360 ? null : 8}>
          {!disableNavigation && (
            <>
              {!firstElementEntry?.isIntersecting && (
                <ActionIcon
                  variant="subtle"
                  size="lg"
                  color="embla.1"
                  onClick={onPrevItem}
                >
                  <IconArrowLeft color={colors.embla[4]} width={16} />
                </ActionIcon>
              )}
              {!lastElementEntry?.isIntersecting && (
                <ActionIcon
                  variant="subtle"
                  size="lg"
                  color="embla.1"
                  onClick={onNextItem}
                >
                  <IconArrowRight color={colors.embla[4]} width={16} />
                </ActionIcon>
              )}
            </>
          )}
        </Group>
      </Group>
      <ScrollArea
        viewportRef={listRef}
        onScrollPositionChange={onScrollPositionChange}
        scrollbarSize={0}
      >
        <Group align="flex-start" gap={0} wrap="nowrap">
          <div ref={firstElementRef} style={{ width: 24 }} />
          {items.map((item, idx) => (
            <>
              <ImageListItem
                width={itemWidth}
                key={idx + item.title}
                {...item}
              />
              {idx !== items.length - 1 && (
                <div style={{ width: itemSpacing }} />
              )}
            </>
          ))}
          <div ref={lastElementRef} style={{ minWidth: 24 }} />
        </Group>
      </ScrollArea>
    </Stack>
  );
};
